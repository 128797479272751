import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import 'react-toastify/dist/ReactToastify.css';
import "flatpickr/dist/themes/airbnb.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from 'react-query'

import {create} from "zustand";
import { persist, devtools } from "zustand/middleware";

import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './theme-components/chart';
import ScrollToTop from './theme-components/scroll-to-top';



import 'bootstrap/dist/css/bootstrap.css';
import "react-quill/dist/quill.snow.css";

import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

import './assets/sass/admin.scss'
import './assets/sass/utils.scss'
import './assets/sass/style.scss'

const queryClient = new QueryClient(
  {
    defaultOptions: {
      queries: {
        staleTime: 2* 60 * 1000, // 2 minutes
        cacheTime: 2 * 60 * 1000 // 2 minutes
      }
    }
  }
)


export const useStore = create(
  devtools(
    persist(
      (set) => ({
        userStatus: false,
        accessToken: null,
        refreshToken: null,
      }),
      {
        name: "auth-storage-tkml",
        getStorage: () => localStorage,
      }
    )
  )
);

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
          <ScrollToTop />
          <StyledChart />
          <Router />
          <ToastContainer/> 
          </QueryClientProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
