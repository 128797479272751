import React from "react";

import { Formik } from "formik";
import { MdCheck, MdDelete } from "react-icons/md";
import { editQuestionSchema } from "../../validations/validation-schemas";

const EditForm = ({
  quest,
  idx,
  editQuestionnaireMutation,
  addNewQuestionMutation,
  toggleDeleteModal,
  setDeleteItem,
}) => {
  return (
    <div>
      <Formik
        initialValues={{
          statement: quest?.statement ?? "",
          option1: quest?.option1 ?? "",
          option2: quest?.option2 ?? "",
          option3: quest?.option3 ?? "",
          option4: quest?.option4 ?? "",
          correct_option: quest?.correct_option ?? "",
        }}
        validationSchema={editQuestionSchema}
        onSubmit={(values) => {
          if (quest?.id) {
            console.log("id");
            editQuestionnaireMutation.mutate({
              ...values,
              question_id: quest?.id,
            });
          } else {
            console.log("no id");
            addNewQuestionMutation.mutate({
              ...values,
            });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          isSubmitting,
          resetForm,
          dirty,
        }) => {
          return (
            <>
              <div className="question-form col-12 w-100">
                <label htmlFor="statement" className="mt-3">
                  سؤال
                </label>
                <input
                  type="text"
                  id="statement"
                  name="statement"
                  dir="rtl"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.statement}
                  placeholder="أدخل السؤال"
                  className="form-control form-control-icon input-border-primary col-6 mb-3"
                />
                <div className="row flex-row-reverse">
                  <div className="col-3">
                    الخيار 1
                    <input
                      type="text"
                      dir="rtl"
                      id="option1"
                      name="option1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.option1}
                      placeholder="أدخل الخيار"
                      className="form-control form-control-icon input-border-primary"
                    />
                    <div className="d-flex justify-content-end mt-3 mx-3">
                    <label htmlFor="">الخيار الصحيح</label>
                      <input
                        type="checkbox"
                        name="correct_option"
                        value="option1"
                        onChange={() =>
                          setFieldValue("correct_option", "option1")
                        }
                        checked={values.correct_option === "option1"}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    الخيار 2
                    <input
                      type="text"
                      dir="rtl"
                      id="option2"
                      name="option2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.option2}
                      placeholder="أدخل الخيار"
                      className="form-control form-control-icon input-border-primary"
                    />
                    <div className="d-flex justify-content-end mt-3 mx-3">
                    <label htmlFor="">الخيار الصحيح</label>
                      <input
                        type="checkbox"
                        name="correct_option"
                        value="option2"
                        onChange={() =>
                          setFieldValue("correct_option", "option2")
                        }
                        checked={values.correct_option === "option2"}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    الخيار 3
                    <input
                      type="text"
                      dir="rtl"
                      id="option3"
                      name="option3"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.option3}
                      placeholder="أدخل الخيار"
                      className="form-control form-control-icon input-border-primary"
                    />
                    <div className="d-flex justify-content-end mt-3 mx-3">
                      <label htmlFor="">الخيار الصحيح</label>
                      <input
                        type="checkbox"
                        name="correct_option"
                        value="option3"
                        onChange={() =>
                          setFieldValue("correct_option", "option3")
                        }
                        checked={values.correct_option === "option3"}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    الخيار 4
                    <input
                      type="text"
                      dir="rtl"
                      id="option4"
                      name="option4"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.option4}
                      placeholder="أدخل الخيار"
                      className="form-control form-control-icon input-border-primary"
                    />
                    <div className="d-flex justify-content-end mt-3 mx-3">
                    <label htmlFor="">الخيار الصحيح</label>
                      <input
                        type="checkbox"
                        name="correct_option"
                        value="option4"
                        onChange={() =>
                          setFieldValue("correct_option", "option4")
                        }
                        checked={values.correct_option === "option4"}
                      />
                    </div>
                  </div>
                  <div>
                    {(touched.correct_option && errors.correct_option) ||
                    (touched.option4 && errors.option4) ||
                    (touched.option3 && errors.option3) ||
                    (touched.option2 && errors.option2) ||
                    (touched.option1 && errors.option1) ||
                    (touched.statement && errors.statement) ? (
                      <div className="text-danger">
                        يرجى ملء جميع الحقول بشكل صحيح
                      </div>
                    ) : null}
                  </div>
                  <div className="d-flex justify-content-start mt-4 gap-5">
                    <MdDelete
                      className="trash-icon custom-icon cursor-pointer"
                      onClick={() => {
                        toggleDeleteModal();
                        setDeleteItem(quest);
                      }}
                    />
                    <MdCheck
                      className="text-success custom-icon"
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditForm;
