import React, { useState, useEffect, useMemo } from "react";
import MaterialReactTable from "material-react-table";

import pencil from "../../assets/icons/ic_pencil.svg";
import trash from "../../assets/icons/ic_trash.svg";

import { toast } from "react-toastify";
import useAxios from "../../api/useAxios";
import IP from "../../sections/variables";

import { useQuery } from "react-query";
import { useMutation } from "react-query";

import { formatDateTime } from "../../utils/formatDate";

import DeleteConfirmationModal from "../../components/modals/delete-confirmation.modal";


const NotificationListing = () => {
 

  const columns = useMemo(() => [
    {
      header: "عنوان",
      accessorKey: "title",
    },
    {
      header: "المتلقي",
      accessorKey: "receiver"
    },
    { accessorFn: (row) => formatDateTime(row.timestamp),
      header: "تم إنشاؤها على",
      accessorKey: "timestamp"
    },
  ]);

  let api = useAxios();

  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch,
  } = useQuery(`locations`, async () => api.get("/notifications/"), {
    cacheTime: 0,
  });

  const notifications = response?.data ?? [];

  return (
    <div className="wrapper">
      <MaterialReactTable
        state={{ isLoading: isLoading }}
        columns={columns}
        data={notifications}
        renderTopToolbarCustomActions={() => {
          return <h3 className="mx-3 mt-3">إشعارات</h3>;
        }}
        enableRowNumbers
      />
    
    </div>
  );
};

export default NotificationListing;
