import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";

import { formatDate } from "../../utils/formatTime";

import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { CSVLink } from "react-csv";

import {
  formatGender,
  formatNationality,
  formatIDTypes,
  formatQualification,
  formatEmploymentStatus,
  formatCity,
  formatResidence,
  formatAge
} from "../../utils/formatCandidate";

import { toast } from "react-toastify";
import { useMutation } from "react-query";
import useAxios from "../../api/useAxios";
import IP from "../../sections/variables";
import createCertificate from "../../utils/certificate-generator";
import vendorCreateCertificate from "../../utils/vendor-certificate-generator";


const ManageBookingsComponent = ({
  bookings,
  isLoading,
  bookingLoading,
  refetchBooking,
}) => {

  const [selectedRows, setSelectedRows] = useState({});

  let api = useAxios();

  const URL = `${IP}/api`;

  const sendCertificateStatus = (payload) => {

    return api.post(`${URL}/courses/update-certificate/${payload.rowId}`, {
      show_certificate: payload.status,
    });
  };


  const updateCertificateMutation = useMutation(sendCertificateStatus, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
      refetchBooking();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
        ) {
          toast.error(response.response.data.message);
        } else {
          toast.error("هناك خطأ ما");
        }
      },
    });
    
    const toggleCheckbox = (rowId, status) => {
      updateCertificateMutation.mutate({ rowId, 'status': !status });
      setSelectedRows((prevSelectedRows) => ({
        ...prevSelectedRows,
        [rowId]: !prevSelectedRows[rowId],
      }));
    };
    // -================
    
    // download feature
    const [modifiedPDFBytes, setModifiedPDFBytes] = useState(false)


    const downloadCertificate = async (registration) => {
      try {
        var modifiedBytes;
        if (registration.trainer == "ESM" || registration.trainer == "Event Academy" || registration.trainer == "Fhm" || registration.trainer == "Fennec"){
          modifiedBytes = await vendorCreateCertificate(registration);
          }
          else{
          modifiedBytes = await createCertificate(registration);
  
        }

        if (modifiedBytes){
          setModifiedPDFBytes(modifiedBytes)
        }
      
    } catch (error) {
      console.error("Error loading PDF:", error);
    }
  };


  useEffect(() => {
    if (modifiedPDFBytes){
      toast.info('انتظر من فضلك')
      const blob = new Blob([modifiedPDFBytes], { type: "application/pdf" });
      const reader = new FileReader();

    reader.onloadend = () => {
      const url = reader.result;
      const a = document.createElement("a");
      a.href = url;
      a.download = "Certificate.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    reader.readAsDataURL(blob);

    }

  } ,[modifiedPDFBytes])


  // -================


  const courseDays = bookings[0]?.course_days

  const columns = useMemo(() => [
    {
      header: "Course Name",
      accessorKey: "course_name",
    },
    {
      header: "Arabic Name",
      accessorKey: "candidate.ar_name",
    },
    {
      header: "English Name",
      accessorKey: "candidate.en_name",
    },
    {
      header: "Email",
      accessorKey: "candidate.email",
    },
    {
      header: "Phone",
      accessorKey: "candidate.phone",
    },
    {
      accessorFn: (row) => formatGender(row?.candidate?.gender),
      header: "Gender",
    },
    {
      accessorFn: (row) => formatAge(row?.candidate?.age),
      header: "Age",
    },
    {
      accessorFn: (row) => formatQualification(row?.candidate?.qualification),
      header: "Qualification",
    },
    {
      accessorFn: (row) =>
        formatEmploymentStatus(row?.candidate?.employment_status),
      header: "Employment Status",
    },
    {
      header: "Job Title",
      accessorKey: "candidate.job_title",
    },
    {
      header: "Organization",
      accessorKey: "candidate.organization",
    },
    {
      header: "Years of Experience",
      accessorKey: "candidate.years_of_experience",
    },
    {
      accessorFn: (row) => formatIDTypes(row?.candidate?.id_type),
      header: "ID Type",
    },
    {
      header: "ID No",
      accessorKey: 'candidate.id_no'
    },
    {
      accessorFn: (row) => formatNationality(row?.candidate?.nationality),
      header: "Nationality",
    },
    {
      accessorFn: (row) => formatResidence(row?.candidate?.residence),
      header: "Residence",
    },
    {
      accessorFn: (row) => formatCity(row?.candidate?.city),
      header: "City",
    },
    {
      header: "Registration Date",
      accessorKey: "created_on",
      Cell: ({ row }) => formatDate(row?.original?.created_on),
    },    
    {
      header: "Certificate Status",
      Cell: ({ row }) => (
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={`CheckDownloadCertificate-${row?.original?.id}`}
            checked={
              row?.original?.show_certificate
            }
            onChange={() =>
              toggleCheckbox(
                row?.original?.registration_id,
                row?.original?.show_certificate
              )
            }
          />
          <label className="form-check-label" for="flexSwitchCheckDefault">
            نشيط
          </label>
        </div>
      ),
    },
    {
      header: 'Download Certificate',
      Cell: ({ row }) => (
        <Button onClick={() => downloadCertificate(row.original)}>
          download
        </Button>
      )
    },
    {
      header: 'Attendance Percentage',
      accessorKey: 'attendance_percentage'
    }
   
  ]);

  const formattedData = bookings?.map((booking, index) => {

    const attendanceDays = Array.from({ length: courseDays }, (_, dayIndex) => {
      const dayKey = `candidate.attendance.day${dayIndex + 1}`;
      return { [dayKey]: booking?.attendance?.[`day${dayIndex + 1}`] };
    });
  
    return {
      serial_no: index + 1,
      course_name: booking?.course_name,
      "candidate.ar_name": booking?.candidate?.ar_name,
      "candidate.en_name": booking?.candidate?.en_name,
      "candidate.email": booking?.candidate?.email,
      "candidate.phone": booking?.candidate?.phone,
      "candidate.gender": formatGender(booking?.candidate?.gender),
      "candidate.age": formatAge(booking?.candidate?.age),
      "candidate.qualification": formatQualification(booking?.candidate?.qualification),
      "candidate.employment_status": formatEmploymentStatus(booking?.candidate?.employment_status),
      "candidate.job_title": booking?.candidate?.job_title ?? "-",
      "candidate.organization": booking?.candidate?.organization ?? "-",
      "candidate.years_of_experience": booking?.candidate?.years_of_experience ?? "-",
      "candidate.id_type": formatIDTypes(booking?.candidate?.id_type),
      "candidate.id_no": booking?.candidate?.id_no,
      "candidate.nationality": formatNationality(booking?.candidate?.nationality),
      "candidate.residence": formatResidence(booking?.candidate?.residence),
      "candidate.city": formatCity(booking?.candidate?.city),
      "candidate.pre_assessment_percentage": booking?.pre_assessment_percentage ?? "-",
      "candidate.post_assessment_percentage": booking?.post_assessment_percentage ?? "-",
      "candidate.feedback": booking?.feedback?.submitted ? "نعم" : "لا",
      "candidate.show_certificate": booking?.show_certificate ? "نعم" : "لا",
      "candidate.attendance_percentage": booking?.attendance_percentage,
      ...Object.assign({}, ...attendanceDays),
    };
  });

  const pre_assessment_label = "Pre Assessment %";
  const post_assessment_label = "Post Assessment %";

  const headers = [
    { label: "#", key: "serial_no" },
    { label: "اسم الدورة", key: "course_name" },
    { label: "الإسم الكامل باللغة العربية", key: "candidate.ar_name" },
    { label: "الإسم الكامل باللغة الانجليزية", key: "candidate.en_name" },
    { label: "البريد الإلكتروني", key: "candidate.email" },
    { label: "رقم الجوال", key: "candidate.phone" },
    { label: "الجنس", key: "candidate.gender" },
    { label: "العمر", key: "candidate.age" },
    { label: "المؤهل العلمي", key: "candidate.qualification" },
    { label: "الحالة الوظيفية", key: "candidate.employment_status" },
    { label: "مسمى وظيفي", key: "candidate.job_title" },
    { label: "جهة العمل", key: "candidate.organization" },
    { label: "عدد سنوات الخبرة", key: "candidate.years_of_experience" },
    { label: "نوع الوثيقة", key: "candidate.id_type" },
    { label: "رقم الهوية", key: "candidate.id_no" },
    { label: "الجنسية", key: "candidate.nationality" },
    { label: "مكان الإقامة", key: "candidate.residence" },
    { label: "المدينة", key: "candidate.city" },
    { label: pre_assessment_label, key: "candidate.pre_assessment_percentage" },
    { label: post_assessment_label, key: "candidate.post_assessment_percentage" },
    { label: "تم إرسال التعليقات", key: "candidate.feedback" },
    { label: "إظهار الشهادة", key: "candidate.show_certificate" },
    { label: "نسبة الحضور", key: "candidate.attendance_percentage" },
  ];



  for (var i=0; i<courseDays; i++){
    columns.push(
      {
        header: `Attendance day ${i+1}`,
        accessorKey: `attendance.day${i+1}`
      })

      headers.push({
        label: `Attendance day ${i+1}`, key: `candidate.attendance.day${i+1}`
      })
    }

  return (
    <div className="wrapper">
      {bookings?.length > 0 && (
        <span style={{ marginBottom: "10px" }}>
          <CSVLink
            data={formattedData}
            headers={headers}
            filename={`${bookings[0]?.course_name}.csv`}
          >
            <Button
              color="primary"
              style={{ marginBottom: "10px", backgroundColor: "#8E46CD" }}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              تحميل البيانات
            </Button>
          </CSVLink>
        </span>
      )}

      <MaterialReactTable
        state={{ isLoading: isLoading || bookingLoading }}
        columns={columns}
        data={bookings}
        renderTopToolbarCustomActions={({ table }) => (
          <>
            <h3 className="mx-3 mt-3">{bookings[0]?.course_name ?? "-"}</h3>
          </>
        )}
      />
    </div>
  );
};

export default ManageBookingsComponent;
