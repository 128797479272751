import React from 'react';
import { Pie } from 'react-chartjs-2';

import { ArcElement } from "chart.js";
import Chart from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatAge, formatCity, formatEmploymentStatus, formatIDTypes, formatNationality, formatQualification, formatResidence } from '../../utils/formatCandidate';

const DynamicPieChart = ({ values, title, category }) => {
  var labels = Object.keys(values);
  var arabic_labels = labels

  if (category == 'cities'){
    arabic_labels = labels.map((label) => formatCity(label))
  }
  
  else if (category == 'employment-status'){
    arabic_labels = labels.map((label) => formatEmploymentStatus(label))
  }
  
  else if (category == 'qualification'){
    arabic_labels = labels.map((label) => formatQualification(label))
  }
  
  else if (category == 'id_types'){
    arabic_labels = labels.map((label) => formatIDTypes(label))
  }
  
  else if (category == 'residences'){
    arabic_labels = labels.map((label) => formatResidence(label))
  }

  else if (category == 'nationalities'){
    arabic_labels = labels.map((label) => formatNationality(label))
  }

  else if (category == 'age'){
    arabic_labels = labels.map((label) => formatAge(label))
  }

  const ageData = {
    "Less than 20": 0,
    "20-30": 0,
    "31-40": 0,
    "41-50": 0,
    "51-60": 0
  };

  if (category == 'age'){

    Object.keys(values).forEach(age => {
      const ageValue = parseInt(age);
      if (isNaN(ageValue)) {
        // Handle the 'null' case or any other non-numeric value
        ageData["Less than 20"] += values[age];
      } else {
        if (ageValue < 20) {
          ageData["Less than 20"] += values[age];
        } else if (ageValue >= 20 && ageValue <= 30) {
          ageData["20-30"] += values[age];
        } else if (ageValue >= 31 && ageValue <= 40) {
          ageData["31-40"] += values[age];
        } else if (ageValue >= 41 && ageValue <= 50) {
          ageData["41-50"] += values[age];
        } else if (ageValue >= 51 && ageValue <= 60) {
          ageData["51-60"] += values[age];
        }
      }
    });

  }

  const data = {
    labels: category == 'age' ? Object.keys(ageData) : arabic_labels,
    datasets: [
      {
        data: category == 'age' ? Object.keys(ageData).map(key => ageData[key]) : labels.map(key => values[key]),
        backgroundColor: ['#8E46CD', '#04A92B', '#F7C137', '#F7685B', '#5BC0EB'], 
        hoverBackgroundColor: ['#822dcc', '#048823', '#e0ab5a', '#e05a4f', '#5aafe0'],
        borderWidth: 0, 
      },
    ],
  };

  const options = {
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
        },
      datalabels: {
        formatter: (value, context) => {
            const label = context.chart.data.labels[context.dataIndex];
            return `${value}`;
        },
        color: '#fff', 
        font:{
            weight: 'bold',
            size: '16px'
        }
      },
    },
  };

  return (
    <div className='container text-center d-flex flex-column align-items-center' style={{ height: '350px', marginBottom: '100px' }}>
      <h3>{title}</h3>
      <div>
        <Pie data={data} options={options} plugins={[ChartDataLabels]} />
      </div>
    </div>
  );
};

export default DynamicPieChart;
