import MaterialReactTable from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Input, Label } from "reactstrap";
import useAxios from "../../api/useAxios";
import SimpleQuestion from "./simple-question.component";
import MCQQuestion from "./mcq-question.component";
import AddNewQuestion from "./add-new-question.component";
import { toast } from "react-toastify";
import IP from "../../sections/variables";

const EvaluationListing = () => {
  const [schedule, setSchedule] = useState("");
  const [addSection, setAddSection] = useState(false);
  const [active, setActive] = useState(false);
  const api = useAxios();

  const {
    isLoading,
    isError,
    data: res,
    isSuccess,
    refetch,
  } = useQuery(
    `get-feedback-${schedule}`,
    async () => api.get(`/feedback/feedback-form/${schedule}`),
    {
      cacheTime: 0,
      enabled: false,
    }
  );

  const feedbackData = res?.data ?? [];


  console.log(feedbackData)

  const { data: response } = useQuery(
    `get-schedules`,
    async () => api.get("/courses/get-schedules"),
    {
      cacheTime: 0,
    }
  );

  const courses = response?.data ?? [];

  const URL = `${IP}/api`;

  const sendActive = (payload) => {
    return api.patch(`${URL}/feedback/feedback-form/${schedule}`, {
      is_active: payload,
    });
  };

  const changeActiveMutation = useMutation(sendActive, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  useEffect(() => {
    if (schedule) {
      refetch();
    }
  }, [schedule]);

  return (
    <div className="row text-end justify-content-end">
      <div className="col-12 col-md-6">
        <Label className="form-label text-start mt-3 mb-1">دورة*</Label>
        <Input
          value={schedule}
          onChange={(e) => setSchedule(e.target.value)}
          type="select"
          className={
            "form-control form-control-icon input-border-primary col-6"
          }
          id="courseInput"
          name="course"
          dir="rtl"
        >
          <option value="">اختر الدورة</option>

          {courses?.map((course) => (
            <option key={course.id} value={course.id}>
              {course.value}
            </option>
          ))}
        </Input>
      </div>
      <div className="col-12 ">
        <div className="wrapper">
          {isLoading ? (
            <div className="d-flex justify-content-center py-5">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>

          ) : !(feedbackData?.simple_questions ?? null) ? (

            <div className="d-flex align-items-center justify-content-center py-5">
              حدد جدولًا يحتوي على تعليقات
            </div>
          ) : (
            <div>
              <div className="d-flex align-items-center justify-content-end  h-100">
                <div className="form-check form-switch mb-5 mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`flexSwitchCheckDefault`}
                    checked={active}
                    onChange={() => {
                      setActive(!active);
                      changeActiveMutation.mutate(!active);
                    }}
                  />
                  <label
                    className="form-check-label ms-3"
                    for="flexSwitchCheckDefault"
                  >
                    نشيط
                  </label>
                </div>
              </div>
              {feedbackData?.simple_questions?.map((simple, index) => {
                return (
                  <SimpleQuestion
                    key={simple?.id}
                    question={simple}
                    idx={index}
                    refetchFeedback={refetch}
                  />
                );
              })}
              {feedbackData?.mcq_questions?.map((mcq, index) => {
                return (
                  <MCQQuestion
                    key={mcq?.id}
                    question={mcq}
                    idx={index}
                    simpleLength={feedbackData?.simple_questions?.length}
                    refetchFeedback={refetch}
                  />
                );
              })}
              <div>
                <div className="d-flex flex-row-reverse gap-3">
                  <button
                    className="custom-btn"
                    onClick={() => setAddSection("simpleQ")}
                  >
                    إضافة سؤال بسيط جديد
                  </button>
                  <button
                    className="custom-btn"
                    onClick={() => setAddSection("MCQ")}
                  >
                    إضافة سؤال اختيار من متعدد جديد
                  </button>
                </div>
                <AddNewQuestion
                  addSection={addSection}
                  refetchFeedback={refetch}
                  feedbackID={feedbackData?.id}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EvaluationListing;
