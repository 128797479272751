import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
import Page404 from "./pages/Page404";

import LoginPage from "./pages/login";
import DashboardPage from "./pages/dashboard";

import ManageCourses from "./pages/course/manage-courses";
import ManageCourseCategories from "./pages/course-category/manage-course-categories";
import ManageBookings from "./pages/booking/manage-bookings";
import ManageLocations from "./pages/location/manage-locations";
import ManageSchedules from "./pages/schedule/manage-schedule";
import ManageTrainers from "./pages/trainer/manage-trainer";
import ManageQuestionnaires from "./pages/questionnaire/manage-questionnaire";
import ManageUsers from "./pages/user/manage-users";

import { useStore } from "./App";
import ManageAttendance from "./pages/attendance/manage-attendance";
import ManageNotifications from "./pages/notifications/manage-notifications.component";
import ManageEvaluation from "./pages/evaluation/manage-evaluation";

const AdminRoute = ({ children }) => {
  const userStatus = useStore((state) => state.userStatus);

  return userStatus === true ? children : <Navigate to="/" />;
};

const RedirectAdmin = ({ children }) => {
  const userStatus = useStore((state) => state.userStatus);

  return userStatus === true ? <Navigate to="/dashboard" /> : children;
};

export default function Router() {
  const routes = useRoutes([
    {
      element: <DashboardLayout />,
      children: [
        {
          path: "dashboard",
          element: (
            <AdminRoute>
              <DashboardPage />
            </AdminRoute>
          ),
        },
        {
          path: "manage-courses",
          element: (
            <AdminRoute>
              <ManageCourses />
            </AdminRoute>
          ),
        },
        // { path: 'manage-course-categories', element: <AdminRoute><ManageCourseCategories /></AdminRoute> },
        {
          path: "manage-locations",
          element: (
            <AdminRoute>
              <ManageLocations />
            </AdminRoute>
          ),
        },
        {
          path: "manage-schedules",
          element: (
            <AdminRoute>
              <ManageSchedules />
            </AdminRoute>
          ),
        },
        {
          path: "manage-users",
          element: (
            <AdminRoute>
              <ManageUsers />
            </AdminRoute>
          ),
        },
        {
          path: "manage-trainers",
          element: (
            <AdminRoute>
              <ManageTrainers />
            </AdminRoute>
          ),
        },
        {
          path: "manage-questionnaires",
          element: (
            <AdminRoute>
              <ManageQuestionnaires />
            </AdminRoute>
          ),
        },

        {
          path: "manage-bookings",
          element: (
            <AdminRoute>
              <ManageBookings />
            </AdminRoute>
          ),
        },
        {
          path: "manage-attendance",
          element: (
            <AdminRoute>
              <ManageAttendance />
            </AdminRoute>
          ),
        },
        {
          path: "manage-notifications",
          element: (
            <AdminRoute>
              <ManageNotifications />
            </AdminRoute>
          ),
        },
        {
          path: "manage-evaluation",
          element: (
            <AdminRoute>
              <ManageEvaluation />
            </AdminRoute>
          ),
        },
      ],
    },

    {
      element: <SimpleLayout />,
      children: [
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "",
      element: (
        <RedirectAdmin>
          {" "}
          <LoginPage />
        </RedirectAdmin>
      ),
    },
  ]);

  return routes;
}
