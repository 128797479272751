import React, { useState } from "react";

import AddNewSchedule from "../../components/schedule/add-new-schedule.component";
import SchedulesListing from "../../components/schedule/schedule-listing.component";

const ManageSchedules = () => {
  document.title = "Manage Schedules | Happiness Creators";

  const [activeSection, setActiveSection] = useState("add");

  return (
    <>
      <div className="container-fluid mt-4 mb-3">
        <div
          className="bg-primary breadcrumb-item text-white text-end active fs-4"
          aria-current="page"
        >
          إدارة الجداول الزمنية
        </div>

        <div className="text-end">
          <button
            onClick={() => setActiveSection("manage")}
            className={`custom-btn ${
              activeSection == "manage" ? "active-btn" : "inactive-btn"
            } mt-3`}
          >
            إدارة الجداول الزمنية
          </button>

          <button
            onClick={() => setActiveSection("add")}
            className={`custom-btn ${
              activeSection == "add" ? "active-btn" : "inactive-btn"
            } ms-2 mt-3`}
          >
            إضافة جدول جديد
          </button>
        </div>

        {activeSection == "add" ? <AddNewSchedule /> : <SchedulesListing />}
      </div>
    </>
  );
};

export default ManageSchedules;
