import React from 'react';
import { Line } from 'react-chartjs-2';

const LineGraph = ({ data }) => {
  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: 'لا يوجد أشخاص حاضرين',
        data: Object.values(data),
        borderColor: '#04A92B', 
        borderWidth: 3,
        pointRadius: 2, 
        fill: true,
        backgroundColor: '#8e46cd78'
      },
    ],
  };
  const options = {
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.4)',
        },
        ticks: {
          fontSize: 200, // Increase font size for x-axis labels
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.4)',
        },
        ticks: {
          fontSize: 18, // Increase font size for y-axis labels
          precision: 0, // Set precision to 0 to remove decimals
        },
        title: {
          display: true,
          text: 'عدد الأشخاص الحاضرين', // Label for the y-axis
          color: 'rgba(0, 0, 0, 0.8)', // You can customize the color
          fontSize: 18,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels:{
          font:{
            size: 14
          }
        }
      },
      font: {
        size: 16
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    },
  };
  
  
  return (
    <div style={{minHeight: '400px'}}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default LineGraph;
