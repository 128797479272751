import React, { useState } from "react";
import { Input } from "reactstrap";
import { MdCheck, MdDelete } from "react-icons/md";
import useAxios from "../../api/useAxios";
import IP from "../../sections/variables";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

const MCQOption = ({ data, idx, refetchFeedback }) => {
  const [opValue, setOpValue] = useState(data?.option_text);

  const api = useAxios();

  const URL = `${IP}/api`;

  const sendUpdate = (payload) => {
    return api.patch(`${URL}/feedback/choice-option/${data?.id}`, payload);
  };

  const updateOptionMutation = useMutation(sendUpdate, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const handleUpdateOption = () => {
    updateOptionMutation.mutate({
      option_text: opValue,
    });
  };

  const sendDelete = () => {
    return api.delete(`${URL}/feedback/choice-option/${data?.id}`);
  };

  const deleteOptionMutation = useMutation(sendDelete, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
      refetchFeedback();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  return (
    <div key={data?.id} className="d-block col-6 mt-3 ">
      <div className="d-flex align-items-center justify-content-between feedback-option-box px-3 gap-3">
        <div className="d-flex gap-3">
          <MdDelete
            className="trash-icon custom-sm-icon cursor-pointer"
            onClick={() => deleteOptionMutation.mutate()}
          />

          <MdCheck
            className="text-success custom-sm-icon"
            onClick={handleUpdateOption}
          />
        </div>
        <Input
          value={opValue}
          dir="rtl"
          type="text"
          className="feedback-option-input"
          onChange={(e) => setOpValue(e.target.value)}
        />
      </div>
    </div>
  );
};

export default MCQOption;
