export const formatDate = (date) => {
        
    const months = [
        "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
        ];

        const [year, month, day] = date.split("-");
        const monthAbbreviation = months[parseInt(month, 10) - 1];
        const formattedDate = `${day}-${monthAbbreviation}`;

        return formattedDate;
}


export const formatDateTime = (datetime) => {

        // Convert the string to a Date object
        const apiResponseDatetime = new Date(datetime);

        // Manually construct the desired format
        const hours = apiResponseDatetime.getHours();
        const minutes = apiResponseDatetime.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
        const formattedDate = `${apiResponseDatetime.getDate()}/${apiResponseDatetime.getMonth() + 1}/${apiResponseDatetime.getFullYear().toString().slice(-2)}`;

        const formattedDatetime = `${formattedTime} - ${formattedDate}`;

        return formattedDatetime;

}



export const arToEn = (date) => {
        var dateComponents = date.split('-');
    
        var year = dateComponents[0];
        var month = dateComponents[1];
        var day = dateComponents[2];
    
        var newDateString = `${day}-${month}-${year}`;
    
        return newDateString;
    }