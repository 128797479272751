import MaterialReactTable from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Input, Label } from "reactstrap";
import useAxios from "../../api/useAxios";
import ModalViewFeedback from "./modal-view-feedback.component";

const FeedbacksListing = () => {
  const [schedule, setSchedule] = useState("");
  const [detailID, setDetailID] = useState("");
  const [detailModal, setDetailModal] = useState(false);

  const toggleDetailModal = () => {
    if (detailModal) {
      setDetailID("");
    }
    setDetailModal(!detailModal);
  };
  const api = useAxios();

  const columns = useMemo(() => [
    {
      header: "مُرَشَّح",
      accessorKey: "candidate",
    },
    {
      header: "التفاصيل",
      Cell: ({ row }) => (
        <button
          className="active-btn custom-btn"
          onClick={() => {
            setDetailID(row.original.id);
            toggleDetailModal();
          }}
        >
          عرض التفاصيل
        </button>
      ),
    },
  ]);

  const { data: response } = useQuery(
    `get-schedules`,
    async () => api.get("/courses/get-schedules"),
    {
      cacheTime: 0,
    }
  );

  const courses = response?.data ?? [];

  const {
    isLoading,
    isError,
    data: res,
    isSuccess,
    refetch,
  } = useQuery(
    `get-feedback-${schedule}`,
    async () => api.get(`/feedback/feedbacks-list/${schedule}`),
    {
      cacheTime: 0,
      enabled: false,
    }
  );

  const feedbacksList = res?.data?.feedbacks ?? [];

  useEffect(() => {
    if (schedule) {
      refetch();
    }
  }, [schedule]);

  console.log(detailID);

  return (
    <div className="row text-end justify-content-end">
      <div className="col-12 col-md-6">
        <Label className="form-label text-start mt-3 mb-1">دورة*</Label>
        <Input
          value={schedule}
          onChange={(e) => setSchedule(e.target.value)}
          type="select"
          className={
            "form-control form-control-icon input-border-primary col-6"
          }
          id="courseInput"
          name="course"
          dir="rtl"
        >
          <option value="">اختر الدورة</option>

          {courses?.map((course) => (
            <option key={course.id} value={course.id}>
              {course.value}
            </option>
          ))}
        </Input>
      </div>
      <div className="wrapper">
        <MaterialReactTable
          state={{ isLoading: isLoading }}
          columns={columns}
          data={feedbacksList}
          renderTopToolbarCustomActions={() => {
            return <h3 className="mx-3 mt-3">التقيمات</h3>;
          }}
          enableRowNumbers
        />
      </div>
      <ModalViewFeedback
        detailModal={detailModal}
        toggleDetailModal={toggleDetailModal}
        detailID={detailID}
      />
    </div>
  );
};

export default FeedbacksListing;
