export const formatGender = (gender) => {
  switch (gender) {
    case "male":
      return "ذكر";

    case "female":
      return "أنثى";

    default:
      return "N/A"
  }
};

export const formatNationality = (nationality) => {

  switch (nationality) {
    case "saudi":
      return "سعودي";

    case "non-saudi":
      return "غير سعودي";

    default:
      return "N/A";
  }
};

export const formatIDTypes = (idType) => {
  switch (idType) {
    case "national_id":
      return "هوية وطنية";

    case "iqama":
      return "إقامة";

    default:
      return "N/A"
  }
};

export const formatQualification = (qualification) => {
  switch (qualification) {
    case "secondary":
      return "ثانوي";

    case "bachelors":
      return "جامعي";

    case "masters":
      return "ماجستير";

    case "phd":
      return "دكتوراه";

    default:
      return "N/A"
  }
};

export const formatEmploymentStatus = (employmentStatus) => {
  switch (employmentStatus) {
    case "employed":
      return "موظف";

    case "unemployed":
      return "غير موظف";

    case "seasonal-employee":
      return "موظف موسمي";

    case "student":
      return "طالب";

    default:
      return "N/A"
  }
};

export const formatCity = (value) => {
  switch (value) {
    case "riyadh":
      return "الرياض";
    case "jeddah":
      return "جدة";
    case "mecca":
      return "مكة المكرمة";
    case "medina":
      return "المدينة المنورة";
    case "yanbu":
      return "ينبع";
    case "taif":
      return "الطائف";
    case "dammam":
      return "الدمام";
    case "dhahran":
      return "الظهران";
    case "khobar":
      return "الخبر";
    case "ahsa":
      return "الأحساء";
    case "jubail":
      return "الجبيل";
    case "qatif":
      return "القطيف";
    case "hail":
      return "حائل";
    case "tabuk":
      return "تبوك";
    case "abha":
      return "أبها";
    case "baha":
      return "الباحة";
    case "jazan":
      return "جازان";
    case "najran":
      return "نجران";
    case "other":
      return "أخرى";
    default:
      return "N/A";
  }
};

export const formatResidence = (residence) => {
  switch (residence) {
    case "central":
      return "المنطقة الوسطى";
    case "eastern":
      return "المنطقة الشرقية";
    case "western":
      return "المنطقة الغربية";
    case "northern":
      return "المنطقة الشمالية";
    case "southern":
      return "المنطقة الجنوبية";
    default:
      return 'N/A';
  }
};


export const formatAge = (age) => {
  if (age < 20) {
    return "أقل من 20";
  } else if (age >= 20 && age <= 30) {
    return "20-30";
  } else if (age >= 31 && age <= 40) {
    return "31-40";
  } else if (age >= 41 && age <= 50) {
    return "41-50";
  } else if (age >= 51 && age <= 60) {
    return "51-60";
  } else {
    return 'N/A';
  }
}
