import React, { useEffect, useState } from "react";

import { Label, Input, Spinner } from "reactstrap";
import { useFormik } from "formik";
import Select from "react-select";

import { useMutation, useQuery } from "react-query";

import { toast } from "react-toastify";
import useAxios from "../../api/useAxios";
import IP from "../../sections/variables";
import ReactQuill from "react-quill";

import { notificationsSchema } from "../../validations/validation-schemas";

const AddNewNotification = () => {
  const [sendToAll, setSendToAll] = useState(true);
  const [userValue, setUserValue] = useState('')

  const api = useAxios();

  const URL = `${IP}/api`;

  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch,
  } = useQuery(`candidates-notifications`, async () => api.get("/accounts/candidates"), {
    cacheTime: 0,
  });

  const candidates = response?.data?.candidates ?? [];

  const transformedCandidates = candidates?.map((item) => ({
    label: (
      <div>
        <p className="mb-0">{item.ar_name}</p>
        <p className="mb-0" style={{ fontSize: "10px" }}>
          {item.email}
        </p>
      </div>
    ),
    value: item.id, // You can customize the value as needed
  }));


  const sendNote = (payload) => {

    if (sendToAll){
      payload['audience'] = 'all'
      
    }
    else{
      payload['audience'] = 'individual'
    }

    payload['user'] = userValue

    return api.post(`${URL}/notifications/create-notification`, payload);
  };

  const addNotificationsMutation = useMutation(sendNote, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
      resetForm();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const onSubmit = () => {
   
    addNotificationsMutation.mutate(values);
  };

  
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      title: "",
      description: "",
    },

    validationSchema: notificationsSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="wrapper">
      <form onSubmit={handleSubmit}>
        <div className="row text-end justify-content-end my-3">
          <div className="col-12 col-md-6">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked={sendToAll}
                onChange={() => {
                  setSendToAll(!sendToAll);
                }}
              />
              <label class="form-check-label" for="flexSwitchCheckDefault">
                جميع المستخدمين
              </label>
            </div>
          </div>
        </div>
        <div className="row text-end justify-content-end">
          <div className="col-12 col-md-6"></div>
          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">
              مستخدم محدد
            </Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="اختر المستخدم"
              isDisabled={sendToAll}
              isLoading={isLoading}
              isRtl={true}
              isSearchable={true}
              name="users"
              options={transformedCandidates}
              classNames={{
                control: (state) => "input-border-primary",
              }}
              onChange={(selectedOption) => setUserValue(selectedOption?.value)}

            />
          </div>
        </div>
        <div className="row text-end justify-content-end">
          <div className="col-12 col-md-6"></div>
          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">عنوان*</Label>
            <Input
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              className={
                errors.title && touched.title
                  ? "form-control form-control-icon input-border-danger is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
              id="locationInput"
              name="title"
              placeholder="أدخل اسم الموقع"
              dir="rtl"
            />
            {errors.title && touched.title && (
              <div className="invalid-feedback">{errors.title}</div>
            )}
          </div>
          <div className="col-12">
            <Label className="form-label text-start mt-3 mb-1">وصف</Label>
            <Input
              type="textarea"
              name="description"
              className={
                errors.description && touched.description
                  ? "form-control form-control-icon is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
              value={values.description}
              onChange={handleChange}
              dir='rtl'
              style={{ height: '100px' }}
            />
            {errors.description && touched.description && (
              <p className="text-danger text-end">{errors.description}</p>
            )}
          </div>
        </div>
        <div className=" text-end">
          <button
            type="submit"
            className="custom-btn active-btn mt-3"
            disabled={addNotificationsMutation.isLoading}
          >
            {addNotificationsMutation.isLoading ? "يعالج" : " يضيف"}
            {addNotificationsMutation.isLoading && (
              <Spinner size="sm" className="flex-shrink-0 ms-2" />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewNotification;
