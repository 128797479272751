import React, { useState } from "react";

import AddNewQuestionnaire from "../../components/questionnaire/add-new-questionnaire.component";
import QuestionnaireListing from "../../components/questionnaire/questionnaire-listing.component";

const ManageLocations = () => {
  document.title = "Manage Locations | Happiness Creators";

  const [activeSection, setActiveSection] = useState("add");

  return (
    <>
      <div className="container-fluid mt-4 mb-3">
        {/* container fluid */}
        <div
          className="bg-primary breadcrumb-item text-white text-end active fs-4"
          aria-current="page"
        >
إدارة التقييمات

        </div>

        <div className="text-end">
          <button
            onClick={() => setActiveSection("manage")}
            className={`custom-btn ${
              activeSection == "manage" ? "active-btn" : "inactive-btn"
            } mt-3`}
          >
    إدارة التقييمات
          </button>

          <button
            onClick={() => setActiveSection("add")}
            className={`custom-btn ${
              activeSection == "add" ? "active-btn" : "inactive-btn"
            } ms-2 mt-3`}
          >
            إضافة تقييم جديد

          </button>
        </div>

        {activeSection == "add" ? (
          <AddNewQuestionnaire />
        ) : (
          <QuestionnaireListing />
        )}
      </div>
    </>
  );
};

export default ManageLocations;
