import React, { useState } from "react";

import AttendanceListing from "../../components/attendance/attendance-listing.component";
import AddAttendance from "../../components/attendance/add-attendance.component";

const ManageAttendance = () => {
  document.title = "إدارة المواقع | صناع السعادة";

  const [activeSection, setActiveSection] = useState("add");

  return (
    <>
      <div className="container-fluid mt-4 mb-3">
        {/* container fluid */}
        <div
          className="bg-primary breadcrumb-item text-white text-end active fs-4"
          aria-current="page"
        >
          إدارة الحضور
        </div>

      

        {activeSection == "add" ? <AddAttendance /> : <AttendanceListing />}
      </div>
    </>
  );
};

export default ManageAttendance;
