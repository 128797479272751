import React, { useState } from "react";

import AddNewTrainer from "../../components/trainer/add-new-trainer.component";
import TrainerListing from "../../components/trainer/trainer-listing.component";


const ManageCourses = () => {

    document.title = 'إدارة المدربين | صناع السعادة'

    const [activeSection, setActiveSection] = useState('add')

  return (
    <>

      <div className="container-fluid mt-4 mb-3">
        <div className="bg-primary breadcrumb-item text-white text-end active fs-4"
          aria-current="page">
           إدارة المدربين
        </div>

        <div className="text-end">
         
          <button onClick={() => setActiveSection('manage')} className={`custom-btn ${activeSection == 'manage' ? 'active-btn': 'inactive-btn'} mt-3`}> إدارة المدربين</button>
          
          <button onClick={() => setActiveSection('add')} className={`custom-btn ${activeSection == 'add' ? 'active-btn': 'inactive-btn'} ms-2 mt-3`}>إضافة مدربين جدد</button>

        </div>

      {
            activeSection == 'add' ?
            <AddNewTrainer/>
            :
            <TrainerListing/>

      }
      </div>
    </>
  )
}


export default ManageCourses;