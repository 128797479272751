import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import logo1 from '../assets/images/logo/white.png';
import { LoginForm } from '../sections/auth/login';

import { useStore } from '../App'
import { useNavigate } from 'react-router-dom';


const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '80vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(0),
}));

export default function LoginPage() {

  
  document.title = 'Log In | Happiness Creators'

  return (
    <div className='admin-login-wrapper'>

        <Container maxWidth="sm">
          <StyledContent>
            <div>
              <img
                src={logo1}
                className="mx-auto"
                alt="Not Found"
                style={{ width: '300px', marginTop: '50px'}}
              />
            </div>
            
            <div className='login-form'>
            <Typography className='text-secondary text-bold text-center' variant="h4" gutterBottom style={{ marginTop: '35px' }}>
              {/* Sign in to Admin Dashboard */}
              قم بتسجيل الدخول إلى لوحة تحكم المشرف
              
            </Typography>

            <LoginForm />
            </div>
            
          </StyledContent>
        </Container>
   
    </div>
  );
}
