import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, Spinner } from 'reactstrap';

import { useFormik } from 'formik';

import { locationSchema } from '../../validations/validation-schemas';
import { useMutation } from 'react-query';

import useAxios from '../../api/useAxios';

import { toast } from 'react-toastify';
import IP from '../../sections/variables';

const EditLocationModal = ({
    isOpen,
    toggle, 
    data,
    refetch
    }) => {

const api = useAxios()

const URL = `${IP}/api`

const editLocation = (payload) => {

    return api.patch(`${URL}/courses/location/${data?.id}`, payload)
    
}

const editLocationMutation = useMutation(editLocation, {
    onSuccess: () => {

        toast.success('محدث')
        toggle()
        refetch()

        },
        onError: (response) => {
        
        if (response?.response?.status === 400 && !!response?.response?.data?.message){
            toast.error(response.response.data.message)
            }
        
        else{
            toast.error('هناك خطأ ما')
        }

        }
    })

    const onSubmit = () => {
        editLocationMutation.mutate(values)
    }

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
        } = useFormik({
        initialValues: {
            name: data?.name ?? '',
        },

        validationSchema: locationSchema,
        onSubmit,
        enableReinitialize: true,
    });
    

  return (
    <Modal isOpen={isOpen} toggle={toggle} className='modal-md' centered>
      <ModalHeader toggle={toggle}>
        <h4 className='text-primary mb-0'>تحديد الوجهة</h4>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
      <ModalBody>
                <div className="row text-end justify-content-end">
                    <div className="col-12">
                        <Label className="form-label text-start mt-3 mb-1">الموقع*</Label>
                        <Input
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            className={errors.name && touched.name ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon input-border-primary col-6"}
                            id="courseInput"
                            name="name"
                            placeholder="أدخل اسم الموقع"
                            dir="rtl"
                        />
                            {
                                errors.name && touched.name && <div className="invalid-feedback">{errors.name}</div>
                            }

                    </div>
                
                </div>

      </ModalBody>
      <ModalFooter>
        {
            editLocationMutation.isLoading ? 
            <p>يعالج...</p>
            :
            <>
            <button onClick={toggle} type="button" className='custom-btn no-btn px-3'>
            لا
        </button>
        <button 
            type="submit" 
            className='custom-btn active-btn'
            disabled={editLocationMutation.isLoading}>
            {editLocationMutation.isLoading ? "يعالج" : ' تحديث'}
            {editLocationMutation.isLoading &&  <Spinner size="sm" className="flex-shrink-0 ms-2"/>}
        </button>
  </>
        }
      </ModalFooter>
        </form>
    </Modal>
  );
};

export default EditLocationModal;