import React, { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";

import { MdDelete } from "react-icons/md";

const DynamicEvaluationQuestions = ({
  index,
  questionNo,
  handleSubmit,
  onDelete,
  setError,
  // questionType,
  // options,
  // handleChangeQuestion,
  // handleChangeType,
  // handleAddOption,
  // handleRemoveOption,
  // inputRef,
}) => {
  const [option, setOption] = useState("");
  const [statement, setStatement] = useState("");
  const [questionType, setQuestionType] = useState("text");
  const [options, setOptions] = useState([]);
  const [errorQuest, setErrorQuest] = useState(false);

  const handleQuestionSubmit = () => {
    if (questionType == "mcq") {
      handleSubmit({
        questionNo,
        type: questionType,
        question: statement,
        options,
      });
    } else {
      handleSubmit({
        questionNo,
        type: questionType,
        question: statement,
      });
    }
  };

  // console.log("QNO", questionNo);

  useEffect(() => {
    if (questionType == "mcq") {
      if (statement == "" || !(options?.length > 1)) {
        setErrorQuest(true);
      } else {
        setErrorQuest(false);
      }

      setError({ error: errorQuest, id: questionNo });

      if (statement && options?.length > 1 && !errorQuest) {
        handleQuestionSubmit();
      }
    } else {
      if (statement == "") {
        setErrorQuest(true);
      } else {
        setErrorQuest(false);
      }

      setError({ error: errorQuest, id: questionNo });

      if (statement && !errorQuest) {
        handleQuestionSubmit();
      }
    }
  }, [options, statement, questionType, errorQuest]);

  // console.log(questionType == "mcq");
  // console.log(!(options.length > 1));
  return (
    <form
      className="dynamic-component question-form w-100  mx-0  row"
      onSubmit={handleQuestionSubmit}
    >
      <div className="col-12 text-start mb-3">
        <MdDelete
          className="trash-icon cursor-pointer"
          onClick={() => {
            // setError({ error: false, id: questionNo });
            // onDelete(index, questionNo);
            onDelete(questionNo);
            setError({ error: false, id: questionNo });
          }}
        />
      </div>
      <div className="col-12 col-md-6 mt-3 mt-md-0">
        <Label>*نوع السؤال</Label>
        <Input
          type="select"
          className="form-control form-control-icon input-border-primary "
          value={questionType}
          onChange={(e) => setQuestionType(e.target.value)}
          dir="rtl"
        >
          <option value="text">حقل الادخال</option>
          <option value="mcq">خيارات متعددة</option>
        </Input>
      </div>
      <div className="col-12 col-md-6 mt-3 mt-md-0">
        <Label> *السؤال </Label>
        <Input
          type="text"
          dir="rtl"
          className="form-control form-control-icon input-border-primary "
          value={statement}
          onChange={(e) => setStatement(e.target.value)}
          placeholder="أدخل سؤالك"
        />
      </div>
      {questionType === "mcq" && <div className="col-12 col-md-6 mt-3"></div>}
      {questionType === "mcq" && (
        <div className="col-12 col-md-6 mt-3">
          <Label>الخيار</Label>
          <Input
            type="text"
            dir="rtl"
            className="form-control form-control-icon input-border-primary"
            value={option}
            onChange={(e) => setOption(e.target.value)}
            placeholder="أدخل الخيار"
          />
          <div className="text-start">
            <button
              type="button"
              className="custom-btn active-btn mt-2  cursor-pointer"
              disabled={option == ""}
              onClick={() => {
                setOptions((prev) => [...prev, option]);
                setOption("");
              }}
            >
              إضافة خيار
            </button>
          </div>

          {options?.length > 0 && (
            <div className="mt-3">
              <p>
                (الحد الأدنى 2 خيار)<b> :خيارات</b>
              </p>
              {options.map((option, optionIndex) => (
                <div key={optionIndex}>
                  <div className="d-flex flex-row-reverse justify-content-between align-items-center mt-2">
                    <p className="mb-0">{option}</p>
                    <MdDelete
                      className="trash-icon cursor-pointer"
                      onClick={() =>
                        setOptions((prev) =>
                          prev?.filter((_, i) => i !== optionIndex)
                        )
                      }
                    />
                    {/* <button

                  >
                    Remove
                  </button> */}
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          )}

          {/* "يرجى إضافة خيارين على الأقل" */}
        </div>
      )}
      {!statement ? (
        <div className="text-danger">يرجى ملء جميع الحقول بشكل صحيح</div>
      ) : questionType == "mcq" && !(options?.length > 1) ? (
        <div className="text-danger">يرجى إضافة خيارين على الأقل</div>
      ) : null}
    </form>
  );
};

export default DynamicEvaluationQuestions;
