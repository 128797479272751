import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { Label, Input, Spinner } from "reactstrap";
import ReactQuill from "react-quill";
import { useFormik } from "formik";

import { courseSchema } from "../../validations/validation-schemas";

import useAxios from "../../api/useAxios";

import { toast } from "react-toastify";
import IP from "../../sections/variables";

import { useMutation } from "react-query";

const AddNewCourse = () => {
  const api = useAxios();

  const URL = `${IP}/api`;

  // const sendCourse = (payload) => {

  //   console.log(payload)
  //   return api.post(`${URL}/courses/add-course`, payload)

  // }
  const sendCourse = async (payload) => {
    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("description", payload.description);
    formData.append("title_img", payload.title_img);

    try {
      const response = await api.post(`${URL}/courses/add-course`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const addCourseMutation = useMutation(sendCourse, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
      resetForm();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const onSubmit = () => {
    console.log(values);
    addCourseMutation.mutate(values);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      description: "",
      title_img: "",
    },

    validationSchema: courseSchema,
    onSubmit,
    enableReinitialize: true,
  });

  console.log("values", values);
  console.log("errors", errors);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        const maxSize = 5 * 1024 * 1024; // 5 MB
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

        console.log(maxSize);
        console.log(file.size);
        if (file.size > maxSize) {
          toast.error("يتجاوز حجم الملف الحد المسموح به وهو 5 ميغابايت");
        } else if (!allowedTypes.includes(file.type)) {
          toast.error("نوع الملف غير صالح. الرجاء استخدام PNG، JPEG، أو JPG.");
        } else {
          setFieldValue("title_img", file);
        }
      }
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/png, image/jpeg, image/jpg",
    maxSize: 5 * 1024 * 1024, // 5 MB
    multiple: false,
  });

  const handleRemoveImage = () => {
    setFieldValue("title_img", null);
  };

  return (
    <div className="wrapper">
      <form onSubmit={handleSubmit}>
        <div className="row flex-row-reverse text-end justify-content-end">
          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">
              اسم الدورة التدريبية
            </Label>

            <Input
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              className={
                errors.name && touched.name
                  ? "form-control form-control-icon is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
              id="courseInput"
              name="name"
              placeholder="أدخل اسم الدورة"
              dir="rtl"
            />
            {errors.name && touched.name && (
              <div className="invalid-feedback">{errors.name}</div>
            )}
          </div>

          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">
              عنوان الصورة (الحجم الأقصى: 5 ميجابايت)
            </Label>
            <div
              {...getRootProps()}
              className={
                isDragActive || values.title_img
                  ? "dropzone active"
                  : "dropzone"
              }
            >
              <input {...getInputProps()} />
              {values.title_img && (
                <div>
                  <p className="form-control input-border-primary">
                    File: {values.title_img.name}
                  </p>
                  <button
                    type="button"
                    onClick={handleRemoveImage}
                    style={{ borderRadius: "10px" }}
                    className="btn btn-danger btn-sm"
                  >
                    إزالة الصورة
                  </button>
                </div>
              )}
              {!values.title_img && (
                <p
                  className={
                    errors.name && touched.name
                      ? "form-control form-control-icon mb-0 is-invalid col-6 pt-2"
                      : "form-control form-control-icon mb-0 input-border-primary col-6 pt-2"
                  }
                  style={{ color: "rgb(89,92,94)" }}
                >
                  {isDragActive ? "قم بإفلات الملف هنا" : "اسحب وأسقط صورة هنا"}
                </p>
              )}
            </div>
            {errors.title_img && (
              <div className="text-danger mt-0" style={{fontSize: '14px'}}>{errors.title_img}</div>
            )}
          </div>

          <div className="col-12">
            <Label className="form-label text-start mt-3 mb-1">
              وصف الدورة التدريبية
            </Label>
            <ReactQuill
              theme="snow"
              className={
                errors.description && touched.description && "ql-error"
              }
              value={values.description}
              onChange={(e) => setFieldValue("description", e)}
            />
            {errors.description && touched.description && (
              <p className="text-danger text-start">{errors.description}</p>
            )}
          </div>

          <div className="col-12">
            <button
              type="submit"
              className="custom-btn active-btn mt-3"
              disabled={addCourseMutation.isLoading}
            >
              {addCourseMutation.isLoading ? "يعالج" : " يضيف"}
              {addCourseMutation.isLoading && (
                <Spinner size="sm" className="flex-shrink-0 ms-2" />
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewCourse;
