import React, { useEffect, useMemo, useState } from "react";
import { Input, Label } from "reactstrap";

import ManageBookingsComponent from "../../components/bookings/manage-bookings.component";
import { useQuery } from "react-query";
import useAxios from "../../api/useAxios";
import DownloadPreviousComponent from "../../components/bookings/download-previous-courses.component";

import DownloadMultipleCertificates from "../../components/bookings/download-certificates.component";


const ManageBookings = () => {

  document.title = "Manage Bookings | Happiness Creators";

  const [selectedCourse, setSelectedCourse] = useState("");

  console.log(selectedCourse)

  const {
    isLoading: bookingLoading,
    isError: bookingError,
    data: bookingResponse,
    isSuccess: bookingSuccess,
    refetch: refetchBooking,
  } = useQuery(
    `get-bookings`,
    async () => api.get(`/courses/get-registrations/${selectedCourse}`),
    {
      cacheTime: 0,
      enabled: false,
    }
  );

  const bookings = bookingResponse?.data?.registrations ?? [];
  const traineesWithCertificates = bookings.filter(booking => booking.show_certificate === true);
  const courseName = bookings?.[0]?.course_name ?? 'Course'


  const handleCourseChange = async (event) => {
    const selectedValue = event.target.value;
    setSelectedCourse(selectedValue);
  };

  let api = useAxios();

  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch,
  } = useQuery(`get-schedules`, async () => api.get("/courses/get-schedules"), {
    cacheTime: 0,
  });

  const courses = response?.data ?? [];

  useEffect(() => {
    if (!selectedCourse == "") {
      refetchBooking();
    }
  }, [selectedCourse]);

  return (
    <>
      <div className="container-fluid mt-4 mb-3">
        <div
          className="bg-primary breadcrumb-item text-white text-end active fs-4"
          aria-current="page"
        >
          إدارة الحجوزات
        </div>

        <div className="row mt-4 align-items-end">
          <div className="col-12 col-md-6">
            <DownloadPreviousComponent />
          </div>
          <div className="col-12 col-md-6 ">
            <div className="row ">
              <div className="text-end">
                <Label>حدد الدورة</Label>
              </div>
              <Input
                type="select"
                className="input-border-primary"
                dir="rtl"
                onChange={handleCourseChange}
                value={selectedCourse}
              >
                <option value="">حدد الدورة</option>
                {courses?.map((course) => (
                  <option key={course.id} value={course.id}>
                    {course.value}
                  </option>
                ))}
              </Input>
            </div>
          </div>

        </div>

        {
          selectedCourse &&
          <DownloadMultipleCertificates
            registrations={traineesWithCertificates}
            courseName={courseName}
          />
        }

        <ManageBookingsComponent
          bookings={bookings}
          refetchBooking={refetchBooking}
          isLoading={isLoading}
          bookingLoading={bookingLoading}
        />
      </div>
    </>
  );
};

export default ManageBookings;
