import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input } from 'reactstrap';


const DeleteConfirmationModal = ({
    isOpen,
    toggle, 
    data,
    mutation
    }) => {


  return (
    <Modal isOpen={isOpen} toggle={toggle} className='modal-md' centered>
      <ModalHeader toggle={toggle}>
        <h4 className='text-primary mb-0'>تأكيد</h4>
      </ModalHeader>
      <ModalBody>
          <div className='row py-3'>
              <h6 dir='rtl'>هل أنت متأكد أنك تريد حذف  <u>{data?.name ?? ''}</u>؟ </h6>
          </div>  

      </ModalBody>
      <ModalFooter>
        {
          mutation.isLoading ? 
          <p>يعالج...</p>
          :
          <>
            <button onClick={toggle} type="button" className='custom-btn no-btn px-4'>
            لا
        </button>
        <button onClick={() => mutation.mutate()} type="submit" className='custom-btn active-btn px-4'>
        نعم
        </button>
  </>
        }
      </ModalFooter>
    </Modal>
  );
};

export default DeleteConfirmationModal;