import React, { useState } from "react";

import NotificationListing from "./notifications-listing.component";
import AddNewNotification from "../../components/notifications/add-notifications.component";

const ManageNotifications = () => {
  document.title = "إدارة الإخطارات  | صناع السعادة";

  const [activeSection, setActiveSection] = useState("add");

  return (
    <>
      <div className="container-fluid mt-4 mb-3">
        {/* container fluid */}
        <div
          className="bg-primary breadcrumb-item text-white text-end active fs-4"
          aria-current="page"
        >
          إدارة الإخطارات
        </div>

        <div className="text-end">
          <button
            onClick={() => setActiveSection("manage")}
            className={`custom-btn ${
              activeSection == "manage" ? "active-btn" : "inactive-btn"
            } mt-3`}
          >
            {" "}
            إدارة الإخطارات
          </button>

          <button
            onClick={() => setActiveSection("add")}
            className={`custom-btn ${
              activeSection == "add" ? "active-btn" : "inactive-btn"
            } ms-2 mt-3`}
          >
            إرسال إشعار جديد
          </button>
        </div>

        {
          activeSection == "add" ? <AddNewNotification />
           : <NotificationListing />
        }
      </div>
    </>
  );
};

export default ManageNotifications;
