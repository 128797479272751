import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";

import pencil from "../../assets/icons/ic_pencil.svg";
import trash from "../../assets/icons/ic_trash.svg";
import { formatDate } from "../../utils/formatTime";
import { useMutation, useQuery } from "react-query";
import { Label, Input, Spinner } from "reactstrap";
import useAxios from "../../api/useAxios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import AttendanceCard from "./attendance-card.component";
import { attendanceSchema } from "../../validations/validation-schemas";
import IP from "../../sections/variables";

const AddAttendance = ({}) => {
  const [selectedCourse, setSelectedCourse] = useState("");
  const [attendance, setAttendance] = useState([]);
  const [courseDuration, setCourseDuration] = useState(null);
  const [dateDifferenceArray, setDateDifferenceArray] = useState([]);
  const [dayNumber, setDayNumber] = useState("");
  const [usersList, setUsersList] = useState([]);

  const {
    isLoading: bookingLoading,
    isError: bookingError,
    data: bookingResponse,
    isSuccess: bookingSuccess,
    refetch: refetchBooking,
  } = useQuery(
    `get-bookings`,
    async () => api.get(`/courses/get-registrations/${selectedCourse}`),
    {
      cacheTime: 0,
      enabled: false,
    }
  );

  const data = bookingResponse?.data?.registrations ?? [];

  console.log(data)

  const {
    isLoading: attendancesLoading,
    isError: attendancesError,
    data: attendancesResponse,
    isSuccess: attendancesSuccess,
    refetch: refetchAttendances,
  } = useQuery(
    `get-attendances-day-${dayNumber}-course-${selectedCourse}`,
    async () =>
      api.post(`/courses/get-attendances`, {
        schedule: selectedCourse,
        day: dayNumber,
      }),
    {
      cacheTime: 0,
      enabled: false,
    }
  );

  const attendances = attendancesResponse?.data?.attendances ?? [];


  const handleCourseChange = async (event) => {
    const course = courses?.filter((cor) => cor.id == event.target.value);

    setCourseDuration(course[0]?.duration);
    
    if (event.target.value == "") {
      setDayNumber("");
    }

    const selectedValue = event.target.value;
    setSelectedCourse(selectedValue);
    setDateDifferenceArray([]);
    setDayNumber("");
    setUsersList([]);
  };

  let api = useAxios();
  const URL = `${IP}/api`;

  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch,
  } = useQuery(`get-schedules`, async () => api.get("/courses/get-schedules"), {
    cacheTime: 0,
  });

  const courses = response?.data ?? [];

  useEffect(() => {
    if (!dayNumber == "") {
      setUsersList([]);
      setAttendance([]);
      refetchAttendances();
      refetchBooking();
    }
  }, [dayNumber]);

  // console.log(attendance);

  const sendAttendance = (payload) => {
    let completeAttendance = [];

    if (attendances?.length > 0) {
      attendances?.map((book) => {
        completeAttendance.push({
          candidateID: book?.candidate_id,
          isPresent: book?.is_present,
        });
      });
    } else {
      data?.map((book) => {
        completeAttendance.push({ candidateID: book?.id, isPresent: false });
      });
    }

    let newAttendanceData = completeAttendance.filter((item) => {
      return !attendance.some(
        (attItem) => attItem.candidateID === item.candidateID
      );
    });

    // Concatenate attendance and newAttendanceData arrays
    let mergedAttendance = attendance.concat(newAttendanceData);

    console.log("submit data", {
      scheduleID: selectedCourse,
      dayNumber: dayNumber,
      attendanceData: mergedAttendance,
    });

    const finalData = {
      scheduleID: parseInt(selectedCourse),
      dayNumber: parseInt(dayNumber),
      attendanceData: mergedAttendance,
    };

    return api.post(`${URL}/courses/mark-attendance`, finalData);
  };

  const addAttendanceMutation = useMutation(sendAttendance, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
      resetForm();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const onSubmit = (values) => {
    addAttendanceMutation.mutate(values);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    setFieldError,
  } = useFormik({
    initialValues: {
      dayNumber: "",
      course: "",
    },

    // validationSchema: attendanceSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    // Calculate the difference in days and create an array of objects
    if (courseDuration) {
      const newArray = Array.from({ length: courseDuration }, (_, index) => ({
        label: (index + 1).toString(),
        value: index + 1,
      }));

      setDateDifferenceArray(newArray);
    }
  }, [courseDuration]);

  useEffect(() => {
    if (!attendances?.length > 0) {
      setUsersList(data);
    } else {
      // Extract unique ids from array1
      const existingIds = attendances?.map((item) => item.candidate_id);

      // Filter array2 to get items with unique ids
      const newItemsFromArray2 = data?.filter(
        (item) => !existingIds.includes(item.id)
      );

      // Merge arrays
      setUsersList([...attendances, ...newItemsFromArray2]);
    }
  }, [
    attendancesSuccess,
    attendancesResponse,
    bookingSuccess,
    bookingResponse,
  ]);

  return (
    <>
      <div className="wrapper">
        <form onSubmit={handleSubmit}>
          <div className="row text-end justify-content-end flex-row-reverse">
            {/* course */}
            <div className="col-12 col-md-6">
              <Label className="form-label text-start mt-3 mb-1">دورة*</Label>
              <Input
                type="select"
                className={
                  errors.course && touched.course && selectedCourse == ""
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon input-border-primary col-6"
                }
                dir="rtl"
                name="course"
                onChange={handleCourseChange}
                value={selectedCourse}
              >
                <option value="">اختر الدورة</option>
                {courses?.map((course) => (
                  <option key={course.id} value={course.id}>
                    {course.value}
                  </option>
                ))}
              </Input>
              {errors.course && touched.course && (
                <div className="invalid-feedback">{errors.course}</div>
              )}
            </div>
            <div className="col-12 col-md-6">
              <Label className="form-label text-start mt-3 mb-1">
                رقم اليوم*
              </Label>
              <Input
                type="select"
                name="dayNumber"
                className={
                  errors.dayNumber && touched.dayNumber && dayNumber == ""
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon input-border-primary col-6"
                }
                dir="rtl"
                onChange={(e) => setDayNumber(e.target.value)}
                value={dayNumber}
              >
                <option value="">حدد اليوم</option>

                {dateDifferenceArray?.map((day) => (
                  <option key={day.value} value={day.value}>
                    {day.label}
                  </option>
                ))}
              </Input>
              {errors.dayNumber && touched.dayNumber && (
                <div className="invalid-feedback">{errors.dayNumber}</div>
              )}
            </div>
          </div>
          <div className="row my-5 px-0 text-end justify-content-end flex-row-reverse">
            <div className="col-12 col-md-6 ">
              {bookingLoading || isLoading || attendancesLoading ? (
                <div className="d-flex justify-content-center  mx-auto">
                  <div class="spinner-border " role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : usersList?.length > 0 ? (
                usersList?.map((book) => {
                  return (
                    <div key={book?.id}>
                      <AttendanceCard
                        data={book}
                        setAttendance={setAttendance}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="mx-auto text-center">
                  حدد الدورة واليوم لمعرفة الحضور
                </div>
              )}
            </div>
            <div className="col-12 col-md-6"></div>
          </div>
          <div className="text-end">
            <button
              type="submit"
              className="custom-btn active-btn mt-3"
              disabled={
                addAttendanceMutation.isLoading ||
                dayNumber == "" ||
                selectedCourse == ""
              }
            >
              {addAttendanceMutation.isLoading ? "يعالج" : " يضيف"}
              {addAttendanceMutation.isLoading && (
                <Spinner size="sm" className="flex-shrink-0 ms-2" />
              )}
            </button>
          </div>
        </form>
      </div>
      {/* <div className="row mt-3">
        <div className="col-6"></div>
        <div className="col-6">
          <div className="text-end">
            <Label>حدد الدورة</Label>
          </div>
          <Input
            type="select"
            className="input-border-primary"
            dir="rtl"
            onChange={handleCourseChange}
            value={selectedCourse}
          >
            <option value="">حدد الدورة</option>
            {courses?.map((course) => (
              <option key={course.id} value={course.id}>
                {course.value}
              </option>
            ))}
          </Input>
        </div>
      </div> */}
      {/* <div className="wrapper">
        <MaterialReactTable
          state={{ isLoading: isLoading || bookingLoading }}
          columns={columns}
          data={data}
          renderTopToolbarCustomActions={() => {
            return <h3 className="mx-3 mt-3">{data?.course_name}</h3>;
          }}
        />
      </div> */}
    </>
  );
};

export default AddAttendance;
