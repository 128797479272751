import React, { useEffect, useRef } from "react";

import { Label, Input, Spinner } from "reactstrap";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";

import { scheduleSchema } from "../../validations/validation-schemas";
import { useQuery } from "react-query";
import useAxios from "../../api/useAxios";

import IP from "../../sections/variables";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const AddNewSchedule = () => {
  const api = useAxios();

  const URL = `${IP}/api`;

  const sendSchedule = (payload) => {
    return api.post(`${URL}/courses/add-schedule`, payload);
  };

  const addScheduleMutation = useMutation(sendSchedule, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
      resetForm();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const onSubmit = (values) => {
    addScheduleMutation.mutate(values);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    setFieldError,
  } = useFormik({
    initialValues: {
      course: "",
      location: "",
      start_date: "",
      end_date: "",
      start_time: "",
      end_time: "",
      duration: "",
      hours: "",
      language: "",
      trainer: "",
    },

    validationSchema: scheduleSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const {
    isLoading: locationsLoading,
    isError: locationsError,
    data: locationsResponse,
    isSuccess: locationsSuccess,
  } = useQuery(`locations`, async () => api.get("/courses/locations"), {
    cacheTime: 0,
  });

  const locations = locationsResponse?.data?.locations ?? [];

  const {
    isLoading: trainersLoading,
    isError: trainersError,
    data: trainersResponse,
    isSuccess: trainersSuccess,
  } = useQuery(`trainers`, async () => api.get("/courses/trainers"), {
    cacheTime: 0,
  });

  const trainers = trainersResponse?.data?.trainers ?? [];

  const {
    isLoading: coursesLoading,
    isError: coursesError,
    data: coursesResponse,
    isSuccess: coursesSuccess,
    refetch: refetchCourses,
  } = useQuery(`courses`, async () => api.get(`/courses/`), { cacheTime: 0 });

  const courses = coursesResponse?.data?.courses ?? [];

  const today = new Date().toISOString().split("T")[0];

  return (
    <div className="wrapper">
      <form onSubmit={handleSubmit}>
        <div className="row text-end justify-content-end flex-row-reverse">
          {/* course */}
          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">دورة*</Label>
            <Input
              value={values.course}
              onChange={handleChange}
              onBlur={handleBlur}
              type="select"
              className={
                errors.course && touched.course
                  ? "form-control form-control-icon is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
              id="courseInput"
              name="course"
              dir="rtl"
            >
              <option value="">اختر الدورة</option>

              {courses.map((course) => (
                <option key={course.id} value={course.id}>
                  {course.name}
                </option>
              ))}
            </Input>
            {errors.course && touched.course && (
              <div className="invalid-feedback">{errors.course}</div>
            )}
          </div>

          {/* trainer */}
          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">مدرب</Label>
            <Input
              value={values.trainer}
              onChange={handleChange}
              onBlur={handleBlur}
              type="select"
              className={
                errors.trainer && touched.trainer
                  ? "form-control form-control-icon is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
              id="locationInput"
              name="trainer"
              dir="rtl"
            >
              <option value="">حدد المدرب</option>
              {trainers.map((trainer) => (
                <option key={trainer.id} value={trainer.id}>
                  {trainer.name}
                </option>
              ))}
            </Input>
            {errors.trainer && touched.trainer && (
              <div className="invalid-feedback">{errors.trainer}</div>
            )}
          </div>

          {/* location */}
          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">الموقع*</Label>
            <Input
              value={values.location}
              onChange={handleChange}
              onBlur={handleBlur}
              type="select"
              className={
                errors.location && touched.location
                  ? "form-control form-control-icon is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
              id="locationInput"
              name="location"
              placeholder="Enter location name"
              dir="rtl"
            >
              <option value="">اختر الموقع</option>
              {locations.map((location) => (
                <option key={location?.id} value={location.id}>
                  {location.name}
                </option>
              ))}
            </Input>
            {errors.location && touched.location && (
              <div className="invalid-feedback">{errors.location}</div>
            )}
          </div>

          {/* location */}
          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">لغة*</Label>
            <Input
              value={values.language}
              onChange={handleChange}
              onBlur={handleBlur}
              type="select"
              className={
                errors.language && touched.language
                  ? "form-control form-control-icon is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
              id="languageInput"
              name="language"
              placeholder="Enter language name"
              dir="rtl"
            >
              <option value="">اختر الموقع</option>
              <option value="العربية">العربية</option>
              <option value="إنجليزي">إنجليزي</option>
            </Input>
            {errors.language && touched.language && (
              <div className="invalid-feedback">{errors.language}</div>
            )}
          </div>

          {/* start date */}
          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">
              تاريخ البدء*
            </Label>
            <input
              type="date"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.start_date}
              name="start_date"
              min={today}
              className={
                errors.start_date && touched.start_date
                  ? "form-control form-control-icon is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
            />

            {errors.start_date && touched.start_date && (
              <div className="invalid-feedback">{errors.start_date}</div>
            )}
          </div>

          {/* end date */}
          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">
              تاريخ الانتهاء*
            </Label>
            <input
              type="date"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.end_date}
              name="end_date"
              min={today}
              className={
                errors.end_date && touched.end_date
                  ? "form-control form-control-icon is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
            />

            {errors.end_date && touched.end_date && (
              <div className="invalid-feedback">{errors.end_date}</div>
            )}
          </div>

          {/* Start time */}
          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">
              وقت البدء*
            </Label>
            <Input
              value={values.start_time}
              onChange={handleChange}
              onBlur={handleBlur}
              type="time"
              min={1}
              dir="rtl"
              placeholder="Enter Start time"
              className={
                errors.start_time && touched.start_time
                  ? "form-control form-control-icon is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
              name="start_time"
            />
            {errors.start_time && touched.start_time && (
              <div className="invalid-feedback">{errors.start_time}</div>
            )}
          </div>

          {/* End time */}
          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">
              وقت النهاية*
            </Label>
            <Input
              value={values.end_time}
              onChange={handleChange}
              onBlur={handleBlur}
              type="time"
              min={1}
              dir="rtl"
              placeholder="Enter end time"
              className={
                errors.end_time && touched.end_time
                  ? "form-control form-control-icon is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
              name="end_time"
            />
            {errors.end_time && touched.end_time && (
              <div className="invalid-feedback">{errors.end_time}</div>
            )}
          </div>

          {/* duration */}
          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">
              المدة (أيام)*
            </Label>
            <Input
              value={values.duration}
              onChange={handleChange}
              onBlur={handleBlur}
              type="number"
              min={1}
              dir="rtl"
              placeholder="أدخل المدة"
              className={
                errors.duration && touched.duration
                  ? "form-control form-control-icon is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
              name="duration"
            />
            {errors.duration && touched.duration && (
              <div className="invalid-feedback">{errors.duration}</div>
            )}
          </div>
          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">ساعات*</Label>
            <Input
              value={values.hours}
              onChange={handleChange}
              onBlur={handleBlur}
              type="number"
              min={1}
              dir="rtl"
              placeholder="أدخل الساعات"
              className={
                errors.hours && touched.hours
                  ? "form-control form-control-icon is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
              name="hours"
            />
            {errors.hours && touched.hours && (
              <div className="invalid-feedback">{errors.hours}</div>
            )}
          </div>

          <div>
            <button
              type="submit"
              className="custom-btn active-btn mt-3"
              disabled={addScheduleMutation.isLoading}
            >
              {addScheduleMutation.isLoading ? "يعالج" : " يضيف"}
              {addScheduleMutation.isLoading && (
                <Spinner size="sm" className="flex-shrink-0 ms-2" />
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewSchedule;
