import React, { useState, useEffect, useMemo } from "react";
import MaterialReactTable from "material-react-table";

import pencil from "../../assets/icons/ic_pencil.svg";
import trash from "../../assets/icons/ic_trash.svg";

import { toast } from "react-toastify";
import useAxios from "../../api/useAxios";
import IP from "../../sections/variables";

import { useQuery } from "react-query";
import { useMutation } from "react-query";

import DeleteConfirmationModal from "../../components/modals/delete-confirmation.modal";
import EditQuestionnaireModal from "./edit-questionnaire.modal";
import { Input, Label } from "reactstrap";

const QuestionnaireListing = () => {
  const [deleteItem, setDeleteItem] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);

  const [editItem, setEditItem] = useState(false);
  const [openEditModal, setEditModal] = useState(false);

  const [course, setCourse] = useState("");

  const toggleDeleteModal = () => {
    setDeleteModal(!openDeleteModal);
    setEditItem(false);
  };

  useEffect(() => {
    if (deleteItem) {
      setDeleteModal(true);
    }
  }, [deleteItem]);

  useEffect(() => {
    if (!openDeleteModal) {
      setDeleteItem(false);
    }
  }, [openDeleteModal]);

  const toggleEditModal = () => {
    setEditModal(!openEditModal);
  };

  console.log(openEditModal);
  useEffect(() => {
    if (editItem) {
      setEditModal(true);
    }
  }, [editItem]);

  useEffect(() => {
    if (!openEditModal) {
      setEditModal(false);
      setEditItem(false);
    }
  }, [openEditModal]);

  const {
    isLoading: coursesLoading,
    isError: coursesError,
    error,
    data: coursesResponse,
    isSuccess: coursesSuccess,
    refetch: refetchCourses,
  } = useQuery(
    `courses`,
    async () => api.get(`/courses/get-courses-with-ids`),
    {
      cacheTime: 0,
    }
  );

  const courses = coursesResponse?.data ?? [];

  const deleteCategory = () => {
    return api.delete(`/courses/delete-questionnaire/${deleteItem?.id}`);
  };

  const deleteMutation = useMutation(deleteCategory, {
    onSuccess: () => {
      toast.success("تم الحذف");
      setDeleteItem(false);
      setDeleteModal(false);
      refetch();
    },

    onError: (response) => {
      if (
        response?.response?.status == 404 &&
        response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const columns = useMemo(() => [
    {
      header: "فئة",
      Cell: ({ row }) => (
        <div>
          {row.original.category == "pre" ? "التقييم المسبق" : "تقييم ما بعد"}
        </div>
      ),
    },
    {
      header: "يحرر",
      Cell: ({ row }) => (
        <img
          onClick={() => setEditItem(row.original)}
          className="edit-icon"
          src={pencil}
          alt=""
        />
      ),
    },
  ]);

  let api = useAxios();

  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch,
  } = useQuery(
    `get-assessments-${course}`,
    async () => api.get(`/courses/assessments/${course}`),
    // async () => api.get(`/courses/assessments/${parseInt(course)}`),
    {
      cacheTime: 0,
      enabled: false,
    }
  );

  const questionnaires = response?.data?.assessments ?? [];

  useEffect(() => {
    if (!course == "") {
      refetch();
    }
  }, [course]);

  // console.log(questionnaires);
  // console.log(course);

  return (
    <>
      <div className="row text-end justify-content-end">
        <div className="col-12 col-md-6">
          <Label className="form-label text-start mt-3 mb-1">دورة</Label>
          <Input
            value={course}
            handle
            onChange={(e) => {
              setCourse(e.target.value);
            }}
            type="select"
            className="form-control form-control-icon input-border-primary col-6"
            id="courseInput"
            name="course"
            dir="rtl"
          >
            <option value="">حدد الدورة</option>

            {courses?.map((course) => (
              <option key={course.course_id} value={course.course_id}>
                {course.course_name}
              </option>
            ))}
          </Input>
        </div>
      </div>
      <div className="wrapper">
        <MaterialReactTable
          state={{ isLoading: isLoading }}
          columns={columns}
          data={questionnaires}
          renderTopToolbarCustomActions={() => {
            return <h3 className="mx-3 mt-3">الاستبيانات</h3>;
          }}
          enableRowNumbers
        />
        <DeleteConfirmationModal
          isOpen={openDeleteModal}
          toggle={toggleDeleteModal}
          data={deleteItem}
          mutation={deleteMutation}
        />
        <EditQuestionnaireModal
          isOpen={openEditModal}
          toggle={toggleEditModal}
          data={editItem}
          refetch={refetch}
        />
      </div>
    </>
  );
};

export default QuestionnaireListing;
