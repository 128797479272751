import React, { useMemo, useState, useEffect } from "react";
import MaterialReactTable from "material-react-table";

import trash from "../../assets/icons/ic_trash.svg";

import useAxios from "../../api/useAxios";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

import DeleteConfirmationModal from "../modals/delete-confirmation.modal";

const ManageUsersComponent = () => {
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModal(!openDeleteModal);
  };

  // -------------------

  useEffect(() => {
    if (deleteItem) {
      setDeleteModal(true);
    }
  }, [deleteItem]);

  useEffect(() => {
    if (!openDeleteModal) {
      setDeleteItem(false);
    }
  }, [openDeleteModal]);

  const deleteSchedule = () => {
    return api.delete(`/accounts/delete-candidate/${deleteItem?.id}`);
  };

  const deleteMutation = useMutation(deleteSchedule, {
    onSuccess: () => {
      toast.success("تم الحذف");
      setDeleteItem(false);
      setDeleteModal(false);
      refetch();
    },

    onError: (response) => {
      if (
        response?.response?.status == 404 &&
        response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  // -------------------

  let api = useAxios();

  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch,
  } = useQuery(`trainers`, async () => api.get("/accounts/candidates"), {
    cacheTime: 0,
  });

  const candidates = response?.data?.candidates ?? [];

  const columns = useMemo(() => [
    {
      header: "اسم",
      accessorKey: "ar_name",
    },
    {
      header: "بريد إلكتروني",
      accessorKey: "email",
    },
    {
      header: "مدينة",
      accessorKey: "city",
    },
    {
      header: "دولة",
      accessorKey: "country",
    },

    {
      header: "يمسح",
      Cell: ({ row }) => (
        <img
          onClick={() =>
            setDeleteItem({ id: row.original.id, name: row.original.name })
          }
          className="trash-icon cursor-pointer"
          src={trash}
          alt=""
        />
      ),
    },
  ]);

  return (
    <div className="wrapper">
      <MaterialReactTable
        state={{ isLoading: isLoading }}
        columns={columns}
        data={candidates}
        renderTopToolbarCustomActions={() => {
          return <h3 className="mx-3 mt-3">المستخدمين</h3>;
        }}
      />

      <DeleteConfirmationModal
        isOpen={openDeleteModal}
        toggle={toggleDeleteModal}
        data={deleteItem}
        mutation={deleteMutation}
      />
    </div>
  );
};

export default ManageUsersComponent;
