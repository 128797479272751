import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useAxios from "../api/useAxios";
import { useQuery } from "react-query";
import { Label, Input, Spinner } from "reactstrap";

import { useFormik } from "formik";

import DynamicPieChart from "../components/reports/pie-chart.component";
import FeedbackCharts from "../components/reports/feedback-charts";
import LineGraph from "../components/reports/attendance-graph.component";
import jsPDF from 'jspdf';

import html2canvas from "html2canvas";


const DashboardPage = () => {
  
  const attendanceDataRef = useRef(null);
  const courseReportsRef = useRef(null);
  const feedbackChartRef = useRef(null);


const pdfConverter = require("jspdf");

  document.title = 'Dashboard | Happiness Creators'

  let api = useAxios()
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);


  const [selectedCourse, setSelectedCourse] = useState(false)
  const [scheduleName, setScheduleName] = useState('اسم الدورة التدريبية')


  const {
    isLoading: isBookingLoading,
    isError: isBookingError,
    data: bookingResponse,
    isSuccess: isBookingSuccess,
    refetch: refetchBooking,
  } = useQuery(`get-schedules`, async () => api.get("/courses/get-schedules"), {
    cacheTime: 0,
  });

  const courses = bookingResponse?.data ?? [];

  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch
  } = useQuery(`stats`, async () => api.get('/stats'))

  const {
    isLoading: reportsLoading,
    isError: isReportsError,
    data: reportsData,
    isSuccess: isReportsSuccess,
    refetch: refetchReports

  } = useQuery(`reports`, async () => api.get(`/courses/get-reports/${selectedCourse}`), {enabled: !!selectedCourse})

  
  const {
    isLoading: attendanceLoading,
    isError: isAttendanceError,
    data: attendanceData,
    isSuccess: isAttendanceSuccess,
    refetch: refetchAttendance

  } = useQuery(`attendance`, async () => api.get(`/courses/attendance-stats/${selectedCourse}`), {enabled: !!selectedCourse})

  const attendances = attendanceData?.data ?? []
  
  const {
    isLoading: feedbackLoading,
    isError: isfeedbackError,
    data: feedbackData,
    isSuccess: isfeedbackSuccess,
    refetch: refetchFeedback

  } = useQuery(`feedback`, async () => api.get(`/feedback/feedback-reports/${selectedCourse}`), {enabled: !!selectedCourse})

  const feedbacks = feedbackData?.data ?? []


  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    setFieldError,
  } = useFormik({
    initialValues: {
      dayNumber: "",
      course: "",
    },

    enableReinitialize: true,
  });


  const handleCourseChange = async (event) => {
    console.log(event.target.label)
    const course = courses?.filter((cor) => cor.id == event.target.value);
    const selectedValue = event.target.value;
    setSelectedCourse(selectedValue);
    updateQueryParam(selectedValue); 
  };

  useEffect(() => {

    if (selectedCourse){
      refetchReports()
      refetchAttendance()
      refetchFeedback()
    }
  }, [selectedCourse])



  const updateQueryParam = (courseId) => {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set("course", courseId);
    navigate({ search: newSearchParams.toString() });
};

useEffect(() => {
  const courseId = queryParams.get("course");
  if (courseId !== selectedCourse) {
      setSelectedCourse(courseId);
  }
}, [location.search]);


  const printDocument = () => {
    // Create a new jsPDF instance for the merged PDF
    const pdf = new jsPDF('landscape', 'px', 'a4');

    // Get the attendance data section
    const attendanceDiv = attendanceDataRef.current;
    // Same for other elements
    
    

    let maxWidth = 297 * 0.8; // Adjust the maximum width to fit within the page
    let maxHeight = 420 * 0.8; // Adjust the maximum height to fit within the page

    if (attendanceDiv) {
        // Add a new page for attendance data
        html2canvas(attendanceDiv).then((canvas1) => {
            const imgData1 = canvas1.toDataURL('image/png');
            maxWidth = 297; // Adjust the maximum width to fit within the page
            maxHeight = 420; // Adjust the maximum height to fit within the page

            // Get the original dimensions of the image
            const img1 = new Image();
            img1.src = imgData1;
            img1.onload = function () {
                const aspectRatio = img1.width / img1.height;

                let imgWidth, imgHeight;

                // Calculate dimensions while maintaining aspect ratio
                if (img1.width > img1.height) {
                    imgWidth = maxWidth;
                    imgHeight = imgWidth / aspectRatio;
                } else {
                    imgHeight = maxHeight;
                    imgWidth = imgHeight * aspectRatio;
                }

                const offsetX = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
                const offsetY = 10;

                pdf.addImage(imgData1, 'PNG', offsetX, offsetY, imgWidth, imgHeight);

                // Add a space between attendance data and course reports
                pdf.addPage(); // Remove this line to keep attendance data on the first page

                // Get the course reports content
                const courseReportsDiv = courseReportsRef.current;
                if (courseReportsDiv) {
                    // Add a new page for course reports
                    html2canvas(courseReportsDiv).then((canvas2) => {
                        const imgData2 = canvas2.toDataURL('image/png');
                        maxWidth = 297 * 0.8; // Adjust the maximum width to fit within the page
                        maxHeight = 420 * 0.8; // Adjust the maximum height to fit within the page

                        const aspectRatio2 = canvas2.width / canvas2.height;

                        let imgWidth2, imgHeight2;

                        if (aspectRatio2 > 1) {
                            imgWidth2 = maxWidth;
                            imgHeight2 = imgWidth2 / aspectRatio2;
                        } else {
                            imgHeight2 = maxHeight;
                            imgWidth2 = imgHeight2 * aspectRatio2;
                        }

                        const offsetX2 = (pdf.internal.pageSize.getWidth() - imgWidth2) / 2;
                        const offsetY2 = (pdf.internal.pageSize.getHeight() - imgHeight2) / 2;

                        pdf.addImage(imgData2, 'JPEG', offsetX2, offsetY2, imgWidth2, imgHeight2);

                        // Add a space between course reports and feedback chart
                        pdf.addPage();

                        // Get the feedback chart content
                        const feedbackDiv = feedbackChartRef.current;
                        if (feedbackDiv) {
                            // Add a new page for feedback chart
                            html2canvas(feedbackDiv).then((canvas3) => {
                                const imgData3 = canvas3.toDataURL('image/png');

                                const aspectRatio3 = canvas3.width / canvas3.height;

                                let imgWidth3, imgHeight3;

                                if (aspectRatio3 > 1) {
                                    imgWidth3 = maxWidth;
                                    imgHeight3 = imgWidth3 / aspectRatio3;
                                } else {
                                    imgHeight3 = maxHeight;
                                    imgWidth3 = imgHeight3 * aspectRatio3;
                                }

                                const offsetX3 = (pdf.internal.pageSize.getWidth() - imgWidth3) / 2;
                                const offsetY3 = (pdf.internal.pageSize.getHeight() - imgHeight3) / 2;

                                pdf.addImage(imgData3, 'JPEG', offsetX3, offsetY3, imgWidth3, imgHeight3);

                                // Save the merged PDF with all sections
                                pdf.save("Course_reports.pdf");
                                window.location.reload()
                            });
                        }
                    });
                }
            };
        });
    }
    
};





  
  

  return (
    <>

{/* <button onClick={(e) => div2PDF(e)}>Download PDF</button> */}

      
      <div
        className="container-fluid mt-4 mb-3">
        <div
          className="bg-primary breadcrumb-item text-white text-end active fs-4"
          aria-current="page">
          لوحة القيادة
        </div>
      </div>

      <div className="main-content">
        <div className="container-fluid">
          <div className="row d-flex flex-row-reverse">

            {/* courses */}
            <div className="col-12 col-md-4 mt-4 mt-md-0">
              <div className="stat">
                <h4>الدورات </h4>
                <h1 className="fw-bold">{response?.data?.courses ?? '-'}</h1>
              </div>
            </div>

            {/* schedules */}
            <div className="col-12 col-md-4 mt-4 mt-md-0">
              <div className="stat">
                <h4>جداول </h4>
                <h1 className="fw-bold">{response?.data?.schedules ?? '-'}</h1>
              </div>
            </div>


            {/* locations */}
            <div className="col-12 col-md-4 mt-4 mt-md-0">
              <div className="stat">
                <h4>المواقع</h4>
                <h1 className="fw-bold">{response?.data?.locations ?? '-'}</h1>
              </div>
            </div>


            {/* questionnaires */}
            <div className="col-12 col-md-4 mt-4">
              <div className="stat">
                <h4>استبيانات</h4>
                <h1 className="fw-bold">{response?.data?.questionnaires ?? '-'}</h1>
              </div>
            </div>

            {/* trainers */}
            <div className="col-12 col-md-4 mt-4">
              <div className="stat">
                <h4>المدربون</h4>
                <h1 className="fw-bold">{response?.data?.trainers ?? '-'}</h1>
              </div>
            </div>

            {/* users */}
            <div className="col-12 col-md-4 mt-4 mb-5">
              <div className="stat">
                <h4>المستخدمين</h4>
                <h1 className="fw-bold">{response?.data?.candidates ?? '-'}</h1>
              </div>
            </div>



          </div>
        </div>
      </div>

      <span ref={attendanceDataRef} >
      <div
        className="container-fluid mt-0 mb-3">
        <div
          className="bg-primary breadcrumb-item text-white text-end active fs-4"
          aria-current="page">
          إحصائيات
        </div>
      </div>

     <div className="container-fluid">
        <div className="row text-end justify-content-end">
            <div className="col-12 col-md-6"></div>
            <div className="col-12 col-md-6 mb-4">
            <Label className="form-label text-start mt-3 mb-1" dir="rtl">حدد الجدول الزمني لرؤية الإحصائيات*</Label>
              <Input
                type="select"
                className={
                  errors.course && touched.course && selectedCourse == ""
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon input-border-primary col-6"
                }
                dir="rtl"
                name="course"
                onChange={handleCourseChange}
                value={selectedCourse}
              >
                <option value="">اختر الدورة</option>
                {courses?.map((course) => (
                  <option key={course.id} value={course.id}>
                    {course.value}
                  </option>
                ))}
              </Input>
              {errors.course && touched.course && (
                <div className="invalid-feedback">{errors.course}</div>
              )}
            </div>
        </div>
     </div>

      {/* Attendance card */}
      <div
        className="container-fluid mt-0 mb-3">
        <div
          className="bg-primary breadcrumb-item text-white text-end active fs-4"
          aria-current="page">
          الحضور
        </div>
      </div>



      <div className="container-fluid mb-3">
        <div className="row flex-row-reverse">
        <div className="col-12 col-md-4 mt-4 mt-md-0">
              <div className="stat">
                <h4>إجمالي المرشحين المسجلين</h4>
                <h1 className="fw-bold">{reportsData?.data?.enrolled_candidates ?? '-'}</h1>
              </div>
            </div>
        </div>
      </div>

      {
        attendanceLoading &&  <Spinner size="sm" className="flex-shrink-0 ms-2" />
      }
      {
        isAttendanceSuccess &&
        <LineGraph
        data={attendances}
        />
      }
      </span>
      {
        isAttendanceError && 
        <h3 className="text-center">
          غير معثور عليه
        </h3>
      }

{
              isReportsSuccess &&
     <div className="text-end me-3 mb-2">
      <button
            onClick={printDocument}
            className={'custom-btn active-btn mt-3'}
          >
            تحميل التقارير التالية  
          </button>
     </div>}

  <span ref={courseReportsRef}>

  
  
      <div
        className="container-fluid mt-0 mb-3">
        <div
          className="bg-primary breadcrumb-item text-white text-end active fs-4"
          aria-current="page">
          التقارير
        </div>
      </div>


      {/* PIE CHARTS */}

     
      {
        reportsLoading &&  <Spinner size="sm" className="flex-shrink-0 ms-2" />
      }
     
        
      {
        isReportsSuccess &&
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-4 pie-chart-container" id='pie-charts'>
            {/* assignments */}
            {
              isReportsSuccess &&
              <DynamicPieChart
                title={'التقييمات المقدمة'}
                values={{
                  'التقييم المسبق': reportsData?.data?.pre_assessments_count,
                  'تقييم ما بعد': reportsData?.data?.post_assessments_count,
                }}
              />
            }
          </div>


          {/* Average Scores */}
          {
            isReportsSuccess &&
            <div className="col-12 col-md-4 pie-chart-container">
              <DynamicPieChart
                title={'متوسط ​​درجات التقييمات'}
                values={{
                  'التقييم المسبق': reportsData?.data?.pre_assessments_average,
                  'تقييم ما بعد': reportsData?.data?.post_assessments_average,
                }}
              />
            </div>
          }

          {/* Genders */}
          {
            isReportsSuccess &&
            <div className="col-12 col-md-4 pie-chart-container">
              <DynamicPieChart
                title={'الجنس'}
                values={{
                  'ذكر': reportsData?.data?.male_candidates,
                  'أنثى': reportsData?.data?.female_candidates,
                }}
              />
            </div>
          }

          {/* Residence */}
          {
            isReportsSuccess &&
            <div className="col-12 col-md-4 pie-chart-container">
              <DynamicPieChart
                title={'مكان الإقامة'}
                values={reportsData?.data?.residences}
                category={'residences'}
              />
            </div>
          }

          {/* Residence */}
          {
            isReportsSuccess &&
            <div className="col-12 col-md-4 pie-chart-container">
              <DynamicPieChart
                title={'نوع الوثيقة'}
                values={reportsData?.data?.nationalities}
                category={'nationalities'}
              />
            </div>
          }

          {/* Cities */}
          {
            isReportsSuccess &&
            <div className="col-12 col-md-4 pie-chart-container">
              <DynamicPieChart
                title={'المدن'}
                values={reportsData?.data?.cities}
                category={'cities'}
              />
            </div>
          }

          {/* Employemnt status */}

          {
            isReportsSuccess &&
            <div className="col-12 col-md-4 pie-chart-container">
              <DynamicPieChart
                title={'الحالة الوظيفية'}
                values={reportsData?.data?.employment_status}
                category={'employment-status'}
              />
            </div>
          }


          {/* Age range */}

          {
            isReportsSuccess &&
            <div className="col-12 col-md-4 pie-chart-container">
              <DynamicPieChart
                title={'العمر'}
                values={reportsData?.data?.age_range}
                category={'age'}
              />
            </div>
          }

          {/* Qualification */}

          {
            isReportsSuccess &&
            <div className="col-12 col-md-4 pie-chart-container">
              <DynamicPieChart
                title={'المؤهل العلمي'}
                values={reportsData?.data?.qualification}
                category={'qualification'}
              />
            </div>

          }

          {/* id_types */}

          {
            isReportsSuccess &&
            <div className="col-12 col-md-4 pie-chart-container">
              <DynamicPieChart
                title={'نوع الوثيقة'}
                values={reportsData?.data?.id_types}
                category={'id_types'}
              />
            </div>
          }

        </div>
      </div>
 }
 </span>

{
        isReportsError && 
        <h3 className="text-center">
          غير معثور عليه
        </h3>
      }

      {/* feedback section */}

     
      {
        feedbackLoading &&  <Spinner size="sm" className="flex-shrink-0 ms-2" />
      }

      {
        isfeedbackSuccess &&
      
      <div ref={feedbackChartRef}  className="container-fluid feedback-chart-container">
        <FeedbackCharts
        data={feedbacks}
        />
      </div>
    }

      {
        isfeedbackError && 
        <h3 className="text-center">
        غير معثور عليه
      </h3>
      }

    </>
  )
}


export default DashboardPage;