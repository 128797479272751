import React from "react";

import ManageUsersComponent from "../../components/users/manage-users.component";


const ManageUsers = () => {

  document.title = 'ادارة المستخدمين | صناع السعادة'

  return (
    <>
      <div
        className="container-fluid mt-4 mb-3">
        <div
          className="bg-primary breadcrumb-item text-white text-end active fs-4"
          aria-current="page">
          ادارة المستخدمين      
          </div>
          <ManageUsersComponent/>
      </div>
    </>
  )
}


export default ManageUsers;