import React, { useEffect, useState } from "react";

import { Label, Input, Spinner } from "reactstrap";
import { useFormik } from "formik";

import { useMutation } from "react-query";

import { toast } from "react-toastify";
import useAxios from "../../api/useAxios";
import IP from "../../sections/variables";

import { useQuery } from "react-query";

import MultipleChoiceQuestion from "./mcq.component";
import { Prev } from "react-bootstrap/esm/PageItem";

const assessmentCategories = [
  { label: "التقييم المسبق", value: "pre" },
  { label: "تقييم ما بعد", value: "post" },
];

const AddNewQuestionnaire = () => {
  const [questions, setQuestions] = useState([]);
  const [formError, setFormError] = useState([]);
  const [shouldDisableButton, setShouldDisableButton] = useState(false);
  const api = useAxios();

  const URL = `${IP}/api`;

  const {
    isLoading: coursesLoading,
    isError: coursesError,
    error,
    data: coursesResponse,
    isSuccess: coursesSuccess,
    refetch: refetchCourses,
  } = useQuery(
    `courses`,
    async () => api.get(`/courses/get-courses-with-ids`),
    {
      cacheTime: 0,
    }
  );

  console.log(coursesResponse);
  const courses = coursesResponse?.data ?? [];

  const [course, setCourse] = useState("");
  const [category, setCategory] = useState("");
  const [active, setActive] = useState(true);
  const [passingMarks, setPassingMarks] = useState("");

  const [mcqCount, setMCQCount] = useState(1);
  const [questionsArr, setQuestionsArr] = useState([{ id: 1 }]);

  const handleAddMCQ = () => {
    setQuestionsArr((qArr) => [...qArr, { id: qArr.length + 1 }]);
    // setQuestionsArr((qArr) => [...qArr, {}]);
    // setQuestionsArr((qArr) => [...qArr, qArr.length]);
    // setMCQCount(mcqCount + 1);
  };

  const [finalValues, setFinalValues] = useState([]);

  const handleSubmit = (questionValues) => {
    // setQuestions((prevQuestions) => [...prevQuestions, questionValues]);
    setQuestions((prevQuestions) => {
      const existingIndex = prevQuestions.findIndex(
        (question) => question.questionNo === questionValues.questionNo
      );

      if (existingIndex !== -1) {
        // If questionNo already exists, replace the existing object
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[existingIndex] = questionValues;
        return updatedQuestions;
      } else {
        // If questionNo doesn't exist, add the new object
        return [...prevQuestions, questionValues];
      }
    });
  };

  const sendQuestionnaire = (payload) => {
    console.log(payload);
    // if (payload.category == "pre") {
    return api.post(`${URL}/courses/add-assessment`, payload);
    // } else {
    //   return api.post(`${URL}/courses/add-questionnaire`, payload);
    // }
  };

  const addQuestionnaireMutaton = useMutation(sendQuestionnaire, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
      // resetForm();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const handleCreateQuestionnaire = () => {
    addQuestionnaireMutaton.mutate({
      ...finalValues,
      course_id: course,
      passing_marks: passingMarks,
      is_active: active,
      assessment_type: category,
      questions: finalValues.questions.map((question) => {
        // Use object destructuring to create a new object without the 'questionNo' key
        const { questionNo, ...rest } = question;
        return rest;
      }),
    });
  };

  const handleFormError = (error) => {
    setFormError((prevErrors) => {
      const existingErrorIndex = prevErrors.findIndex((e) => e.id === error.id);
      if (existingErrorIndex !== -1) {
        // If error with the same id already exists, replace the previous object
        const newErrors = [...prevErrors];
        newErrors[existingErrorIndex] = error;
        return newErrors;
      } else {
        // If error with the same id doesn't exist, add the new error object
        return [...prevErrors, error];
      }
    });
  };

  const handleDeleteQuestion = (questionIndex, questionNo) => {
    console.log(questionIndex);
    setQuestionsArr((qArr) =>
      qArr.filter((_, index) => index !== questionIndex)
    );
    setQuestions((q) =>
      q.filter((quest, index) => quest.questionNo !== questionNo)
    );
    setFinalValues((finalValues) => ({
      ...finalValues,
      questions: finalValues.questions.filter(
        (quest) => quest.questionNo !== questionNo
      ),
    }));
  };

  useEffect(() => {
    setFinalValues((finalValues) => ({ ...finalValues, questions }));
  }, [questions]);

  useEffect(() => {
    console.log("I run");
    let errorCount = 0;
    formError.map((obj) => {
      console.log(obj);
      if (obj.error) {
        console.log("Errrrooorrrrrr");
        setShouldDisableButton(true);
        errorCount++;
      }
    });

    if (!errorCount > 0) {
      setShouldDisableButton(false);
    }
  }, [formError]);

  return (
    <div className="wrapper">
      <form>
        <div className="row text-end justify-content-end">
          <div className="col-12 col-md-6">
            <div className="d-flex align-items-center justify-content-end  h-100">
              <div className="form-check form-switch mt-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`flexSwitchCheckDefault`}
                  checked={active}
                  onChange={() => setActive(!active)}
                />
                <label
                  className="form-check-label ms-3"
                  for="flexSwitchCheckDefault"
                >
                  نشيط
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row text-end justify-content-end">
          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">
              علامات المرور*
            </Label>
            <Input
              value={passingMarks}
              handle
              onChange={(e) => {
                setPassingMarks(e.target.value);
              }}
              type="number"
              className="form-control form-control-icon input-border-primary col-6"
              id="passingMarksInput"
              name="passingMarks"
              dir="rtl"
              min="0"
            />
          </div>
          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">دورة*</Label>
            <Input
              value={course}
              handle
              onChange={(e) => {
                setCourse(e.target.value);
              }}
              type="select"
              className="form-control form-control-icon input-border-primary col-6"
              id="courseInput"
              name="course"
              dir="rtl"
            >
              <option value="">حدد الدورة</option>

              {courses?.map((course) => (
                <option key={course.value} value={course.course_id}>
                  {course.course_name}
                </option>
              ))}
            </Input>
          </div>

          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">
              فئة التقييم*
            </Label>
            <Input
              value={category}
              handle
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              type="select"
              className="form-control form-control-icon input-border-primary col-6"
              id="categoryInput"
              name="category"
              dir="rtl"
            >
              <option value="">اختر الفئة</option>

              {assessmentCategories?.map((category) => (
                <option key={category.value} value={category.value}>
                  {category.label}
                </option>
              ))}
            </Input>
          </div>

          {questionsArr?.length > 0 &&
            questionsArr?.map((q, index) => (
              <div key={q.id} className="d-flex flex-column">
                <h5 className="mt-3 text-end">السؤال{index + 1}</h5>
                <MultipleChoiceQuestion
                  questionNo={q.id}
                  handleSubmit={handleSubmit}
                  setError={handleFormError}
                  onDelete={handleDeleteQuestion}
                  index={index}
                  course={course}
                  category={category}
                  passingMarks={passingMarks}
                />
              </div>
            ))}
          {/* {[...Array(mcqCount)].map((_, index) => (
            <>
              <h5 className="mt-3 text-end">السؤال{index + 1}</h5>
              <MultipleChoiceQuestion
                questionNo={index + 1}
                handleSubmit={handleSubmit}
                formError={handleFormError}
                onDelete={handleDeleteQuestion}
                index={index}
              />
            </>
          ))} */}

          <div>
            <button
              type="button"
              onClick={handleAddMCQ}
              className={"custom-btn inactive-btn mt-1 cursor-pointer"}
            >
              إضافة سؤال جديد
            </button>
          </div>
        </div>
      </form>

      <hr />

      <div className="text-center">
        <button
          onClick={handleCreateQuestionnaire}
          type="button"
          className={"custom-btn active-btn mt-1 cursor-pointer"}
          disabled={
            shouldDisableButton ||
            !course ||
            !category ||
            !questionsArr?.length > 0 ||
            !passingMarks
          }
        >
          {addQuestionnaireMutaton.isLoading ? (
            <div
              className="spinner-border spinner-border-sm text-light"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            "إنشاء استبيان"
          )}
        </button>
      </div>
    </div>
  );
};

export default AddNewQuestionnaire;
