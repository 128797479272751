import React, { useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Button } from "@mui/material";
import { useQuery } from "react-query";
import useAxios from "../../api/useAxios";
import { CSVLink } from "react-csv";


import {
  formatGender,
  formatNationality,
  formatIDTypes,
  formatQualification,
  formatEmploymentStatus,
  formatCity,
  formatResidence,
  formatAge
} from "../../utils/formatCandidate";


const DownloadPreviousComponent = () => {

  const [isDownloading, setIsDownloading] = useState(false);


  const api = useAxios()

  const {
    isLoading,
    isError,
    data: reports,
    isSuccess,
    refetch,
  } = useQuery(
    `get-previous-courses`,
    async () => api.get('/courses/previous-courses-report'),
    {
      cacheTime: 0,
      enabled: false,
    }
  );

  const registrations = reports?.data?.registrations ?? [];

  const formattedData = registrations?.map((registration, index) => {

    console.log(registration)
    
    return {
      serial_no: index + 1,
      course_name: registration?.course_name,
      "candidate.ar_name": registration?.candidate?.ar_name,
      "candidate.en_name": registration?.candidate?.en_name,
      "candidate.email": registration?.candidate?.email,
      "candidate.phone": registration?.candidate?.phone,
      "candidate.gender": formatGender(registration?.candidate?.gender),
      "candidate.age": formatAge(registration?.candidate?.age),
      "candidate.qualification": formatQualification(registration?.candidate?.qualification),
      "candidate.employment_status": formatEmploymentStatus(registration?.candidate?.employment_status),
      "candidate.job_title": registration?.candidate?.job_title ?? "-",
      "candidate.organization": registration?.candidate?.organization ?? "-",
      "candidate.years_of_experience": registration?.candidate?.years_of_experience ?? "-",
      "candidate.id_type": formatIDTypes(registration?.candidate?.id_type),
      "candidate.id_no": registration?.candidate?.id_no,
      "candidate.nationality": formatNationality(registration?.candidate?.nationality),
      "candidate.residence": formatResidence(registration?.candidate?.residence),
      "candidate.city": formatCity(registration?.candidate?.city),
      "candidate.pre_assessment_percentage": registration?.pre_assessment_percentage ?? "-",
      "candidate.post_assessment_percentage": registration?.post_assessment_percentage ?? "-",
      "candidate.feedback": registration?.feedback?.submitted ? "نعم" : "لا",
      "candidate.show_certificate": registration?.show_certificate ? "نعم" : "لا",
      "candidate.attendance_percentage": registration?.attendance_percentage,
    };
  });

  const headers = [
    { label: "#", key: "serial_no" },
    { label: "اسم الدورة", key: "course_name" },
    { label: "الإسم الكامل باللغة العربية", key: "candidate.ar_name" },
    { label: "الإسم الكامل باللغة الانجليزية", key: "candidate.en_name" },
    { label: "البريد الإلكتروني", key: "candidate.email" },
    { label: "رقم الجوال", key: "candidate.phone" },
    { label: "الجنس", key: "candidate.gender" },
    { label: "العمر", key: "candidate.age" },
    { label: "المؤهل العلمي", key: "candidate.qualification" },
    { label: "الحالة الوظيفية", key: "candidate.employment_status" },
    { label: "مسمى وظيفي", key: "candidate.job_title" },
    { label: "جهة العمل", key: "candidate.organization" },
    { label: "عدد سنوات الخبرة", key: "candidate.years_of_experience" },
    { label: "نوع الوثيقة", key: "candidate.id_type" },
    { label: "رقم الهوية", key: "candidate.id_no" },
    { label: "الجنسية", key: "candidate.nationality" },
    { label: "مكان الإقامة", key: "candidate.residence" },
    { label: "المدينة", key: "candidate.city" },
    { label: 'Pre Assessment %', key: "candidate.pre_assessment_percentage" },
    { label: 'Post Assessment %', key: "candidate.post_assessment_percentage" },
    { label: "تم إرسال التعليقات", key: "candidate.feedback" },
    { label: "إظهار الشهادة", key: "candidate.show_certificate" },
    { label: "نسبة الحضور", key: "candidate.attendance_percentage" },
  ];

  const handleDownload = () => {
    setIsDownloading(true);
    refetch();
  };

  console.log(reports)

    return (
        <div>
            {
              !isDownloading &&
              <>
             <Button
             color="primary"
             style={{ marginBottom: "10px", backgroundColor: "#8E46CD" }}
             startIcon={<FileDownloadIcon />}
             variant="contained"
             onClick={handleDownload}
             >
                Download Previous Courses
              </Button>
              
               </>

              }

              {isDownloading && isLoading && 
              <>
               <Button
               color="primary"
               style={{ marginBottom: "10px", backgroundColor: "#8E46CD" }}
               startIcon={<FileDownloadIcon />}
               variant="contained"
               disabled
               >
                  Please Wait...
                </Button>
                <p>قد يتطلب التنزيل بضع دقائق</p>
                 </>
              }

              {!isLoading && isSuccess && (
              
              <CSVLink
                data={formattedData}
                headers={headers}
                filename={`Previous Courses Report.csv`}
              >
                <Button
               color="primary"
               style={{ marginBottom: "10px", backgroundColor: "#8E46CD" }}
               startIcon={<FileDownloadIcon />}
               variant="contained"
               >
                  Click Now To Download
                </Button>
              </CSVLink>
            )}
                    
        </div>
    )

}

export default DownloadPreviousComponent;