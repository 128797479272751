import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  Spinner,
} from "reactstrap";

import { useFormik } from "formik";

import { scheduleSchema } from "../../validations/validation-schemas";
import { useMutation } from "react-query";

import useAxios from "../../api/useAxios";

import { toast } from "react-toastify";
import IP from "../../sections/variables";

import { useQuery } from "react-query";

const EditSchedule = ({ isOpen, toggle, data, refetch }) => {
  const api = useAxios();

  const URL = `${IP}/api`;

  const today = new Date().toISOString().split("T")[0];

  const editSchedule = (payload) => {
    console.log(payload);
    return api.patch(`${URL}/courses/schedule/${data?.id}`, payload);
  };

  const editScheduleMutation = useMutation(editSchedule, {
    onSuccess: () => {
      refetch();
      toast.success("محدث");
      // toggle();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const onSubmit = () => {
    editScheduleMutation.mutate(values);
  };

  console.log(data);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      course: data?.course?.id ?? "",
      location: data?.location?.id ?? "",
      trainer: data?.trainer?.id ?? "",
      start_time: data?.start_time ?? "",
      end_time: data?.end_time ?? "",
      language: data?.language ?? "",
      start_date: data?.start_date ?? "",
      end_date: data?.end_date ?? "",
      duration: data?.duration ?? "",
      hours: data?.hours ?? "",
    },

    validationSchema: scheduleSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const {
    isLoading: locationsLoading,
    isError: locationsError,
    data: locationsResponse,
    isSuccess: locationsSuccess,
  } = useQuery(`locations`, async () => api.get("/courses/locations"), {
    cacheTime: 0,
  });

  const locations = locationsResponse?.data?.locations ?? [];

  const {
    isLoading: trainersLoading,
    isError: trainersError,
    data: trainersResponse,
    isSuccess: trainersSuccess,
  } = useQuery(`trainers`, async () => api.get("/courses/trainers"), {
    cacheTime: 0,
  });

  const trainers = trainersResponse?.data?.trainers ?? [];

  const {
    isLoading: coursesLoading,
    isError: coursesError,
    data: coursesResponse,
    isSuccess: coursesSuccess,
    refetch: refetchCourses,
  } = useQuery(
    `courses-${values?.category}`,
    async () => api.get(`/courses/`),
    {
      cacheTime: 0,
      // enabled: !!values.category,
    }
  );

  const courses = coursesResponse?.data?.courses ?? [];

  console.log(coursesResponse);

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-lg" centered>
      <ModalHeader toggle={toggle}>
        <h4 className="text-primary mb-0">تحرير الجدول الزمني</h4>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <div className="row text-end justify-content-end">
            {/* course */}
            <div className="col-12 col-md-6">
              <Label className="form-label text-start mt-3 mb-1">دورة*</Label>
              <Input
                // value={values.course}
                // onChange={handleChange}
                // onBlur={handleBlur}
                disabled={true}
                type="select"
                className={
                  errors.course && touched.course
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon input-border-primary col-6"
                }
                id="courseInput"
                name="course"
                dir="rtl"
              >
                <option value={data?.course?.id}>{data?.course?.name}</option>
                {/* {courses
                  .filter((course) => course.id !== data?.course?.id)
                  .map((course) => (
                    <option key={course.id} value={course.id}>
                      {course.name}
                    </option>
                  ))} */}
              </Input>
              {errors.course && touched.course && (
                <div className="invalid-feedback">{errors.course}</div>
              )}
            </div>

            {/* trainer */}
            <div className="col-12 col-md-6">
              <Label className="form-label text-start mt-3 mb-1">مدرب</Label>
              <Input
                value={values.trainer}
                onChange={handleChange}
                onBlur={handleBlur}
                type="select"
                className={
                  errors.trainer && touched.trainer
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon input-border-primary col-6"
                }
                id="locationInput"
                name="trainer"
                dir="rtl"
              >
                <option value="">حدد المدرب</option>
                {trainers.map((trainer) => (
                  <option key={trainer.id} value={trainer.id}>
                    {trainer.name}
                  </option>
                ))}
              </Input>
              {errors.trainer && touched.trainer && (
                <div className="invalid-feedback">{errors.trainer}</div>
              )}
            </div>

            {/* location */}
            <div className="col-12 col-md-6">
              <Label className="form-label text-start mt-3 mb-1">الموقع*</Label>
              <Input
                value={values.location}
                onChange={handleChange}
                onBlur={handleBlur}
                type="select"
                className={
                  errors.location && touched.location
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon input-border-primary col-6"
                }
                id="locationInput"
                name="location"
                placeholder="أدخل اسم الموقع"
                dir="rtl"
              >
                <option value="">اختر الموقع</option>
                {locations.map((location) => (
                  <option key={location?.id} value={location.id}>
                    {location.name}
                  </option>
                ))}
              </Input>
              {errors.location && touched.location && (
                <div className="invalid-feedback">{errors.location}</div>
              )}
            </div>

            {/* location */}
            <div className="col-12 col-md-6">
              <Label className="form-label text-start mt-3 mb-1">لغة*</Label>
              <Input
                value={values.language}
                onChange={handleChange}
                onBlur={handleBlur}
                type="select"
                className={
                  errors.language && touched.language
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon input-border-primary col-6"
                }
                id="languageInput"
                name="language"
                placeholder="أدخل اسم اللغة"
                dir="rtl"
              >
                <option value="">اختر الموقع</option>
                <option value="العربية">العربية</option>
                <option value="إنجليزي">إنجليزي</option>
              </Input>
              {errors.language && touched.language && (
                <div className="invalid-feedback">{errors.language}</div>
              )}
            </div>

            {/* start date */}
            <div className="col-12 col-md-6">
              <Label className="form-label text-start mt-3 mb-1">
                تاريخ البدء*
              </Label>
              <input
                type="date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.start_date}
                name="start_date"
                className={
                  errors.start_date && touched.start_date
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon input-border-primary col-6"
                }
              />

              {errors.start_date && touched.start_date && (
                <div className="invalid-feedback">{errors.start_date}</div>
              )}
            </div>

            {/* end date */}
            <div className="col-12 col-md-6">
              <Label className="form-label text-start mt-3 mb-1">
                تاريخ الانتهاء*
              </Label>
              <input
                type="date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.end_date}
                name="end_date"
                
                className={
                  errors.end_date && touched.end_date
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon input-border-primary col-6"
                }
              />

              {errors.end_date && touched.end_date && (
                <div className="invalid-feedback">{errors.end_date}</div>
              )}
            </div>

            {/* Start time */}
            <div className="col-12 col-md-6">
              <Label className="form-label text-start mt-3 mb-1">
                وقت البدء*
              </Label>
              <Input
                value={values.start_time}
                onChange={handleChange}
                onBlur={handleBlur}
                type="time"
                min={1}
                dir="rtl"
                placeholder="أدخل وقت البدء"
                className={
                  errors.start_time && touched.start_time
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon input-border-primary col-6"
                }
                name="start_time"
              />
              {errors.start_time && touched.start_time && (
                <div className="invalid-feedback">{errors.start_time}</div>
              )}
            </div>

            {/* End time */}
            <div className="col-12 col-md-6">
              <Label className="form-label text-start mt-3 mb-1">
                وقت النهاية*
              </Label>
              <Input
                value={values.end_time}
                onChange={handleChange}
                onBlur={handleBlur}
                type="time"
                min={1}
                dir="rtl"
                placeholder="أدخل وقت الانتهاء"
                className={
                  errors.end_time && touched.end_time
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon input-border-primary col-6"
                }
                name="end_time"
              />
              {errors.end_time && touched.end_time && (
                <div className="invalid-feedback">{errors.end_time}</div>
              )}
            </div>

            {/* Hours */}
            <div className="col-12 col-md-6">
              <Label className="form-label text-start mt-3 mb-1">ساعات*</Label>
              <Input
                value={values.hours}
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                min={1}
                dir="rtl"
                placeholder="أدخل الساعات"
                className={
                  errors.hours && touched.hours
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon input-border-primary col-6"
                }
                name="hours"
              />
              {errors.hours && touched.hours && (
                <div className="invalid-feedback">{errors.hours}</div>
              )}
            </div>

            {/* duration */}
            <div className="col-12 col-md-6">
              <Label className="form-label text-start mt-3 mb-1">
                المدة (أيام)*
              </Label>
              <Input
                value={values.duration}
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                min={1}
                dir="rtl"
                placeholder="أدخل المدة"
                className={
                  errors.duration && touched.duration
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon input-border-primary col-6"
                }
                name="duration"
              />
              {errors.duration && touched.duration && (
                <div className="invalid-feedback">{errors.duration}</div>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {editScheduleMutation.isLoading ? (
            <p>يعالج...</p>
          ) : (
            <>
              <button
                onClick={toggle}
                type="button"
                className="custom-btn no-btn px-3"
              >
                لا
              </button>
              <button
                type="submit"
                className="custom-btn active-btn"
                disabled={editScheduleMutation.isLoading}
              >
                {editScheduleMutation.isLoading ? "...تحديث" : " تحديث"}
                {editScheduleMutation.isLoading && (
                  <Spinner size="sm" className="flex-shrink-0 ms-2" />
                )}
              </button>
            </>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditSchedule;
