import React, { useState, useEffect, useMemo } from "react";
import MaterialReactTable from "material-react-table";

import pencil from "../../assets/icons/ic_pencil.svg";
import trash from "../../assets/icons/ic_trash.svg";

import { toast } from "react-toastify";
import useAxios from "../../api/useAxios";
import IP from "../../sections/variables";

import { useQuery } from "react-query";
import { useMutation } from "react-query";

// import DeleteConfirmationModal from "../../components/modals/delete-confirmation.modal";
// import EditLocationModal from "./edit-location.modal";

const AttendanceListing = () => {
  // const [deleteItem, setDeleteItem] = useState(false);
  // const [openDeleteModal, setDeleteModal] = useState(false);

  // const [editItem, setEditItem] = useState(false);
  // // const [openEditModal, setEditModal] = useState(false);

  // const toggleDeleteModal = () => {
  //   setDeleteModal(!openDeleteModal);
  // };

  // useEffect(() => {
  //   if (deleteItem) {
  //     setDeleteModal(true);
  //   }
  // }, [deleteItem]);

  // useEffect(() => {
  //   if (!openDeleteModal) {
  //     setDeleteItem(false);
  //   }
  // }, [openDeleteModal]);

  // const toggleEditModal = () => {
  //   if (openEditModal) {
  //     setEditItem(false);
  //   }
  //   setEditModal(!openEditModal);
  // };
  // useEffect(() => {
  //   if (editItem) {
  //     setEditModal(true);
  //   }
  // }, [editItem]);

  // useEffect(() => {
  //   if (!openEditModal) {
  //     setEditModal(false);
  //   }
  // }, [openEditModal]);

  // const deleteCategory = () => {
  //   return api.delete(`/courses/location/${deleteItem?.id}`);
  // };

  // const deleteMutation = useMutation(deleteCategory, {
  //   onSuccess: () => {
  //     toast.success("تم الحذف");
  //     setDeleteItem(false);
  //     setDeleteModal(false);
  //     refetch();
  //   },

  //   onError: (response) => {
  //     if (
  //       response?.response?.status == 404 &&
  //       response?.response?.data?.message
  //     ) {
  //       toast.error(response.response.data.message);
  //     } else {
  //       toast.error("هناك خطأ ما");
  //     }
  //   },
  // });

  const columns = useMemo(() => [
    {
      header: "الموقع",
      accessorKey: "name",
    },
    {
      header: "يحرر",
      Cell: ({ row }) => (
        <img
          // onClick={() => setEditItem(row.original)}
          className="edit-icon"
          src={pencil}
          alt=""
        />
      ),
    },
    // {
    //   header: "يمسح",
    //   Cell: ({ row }) => (
    //     <img
    //       // onClick={() => setDeleteItem(row.original)}
    //       className="trash-icon cursor-pointer"
    //       src={trash}
    //       alt=""
    //     />
    //   ),
    // },
  ]);

  let api = useAxios();

  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch,
  } = useQuery(`locations`, async () => api.get("/courses/locations"), {
    cacheTime: 0,
  });

  const locations = response?.data?.locations ?? [];

  console.log(locations);

  return (
    <div className="wrapper">
      <MaterialReactTable
        state={{ isLoading: isLoading }}
        columns={columns}
        data={locations}
        renderTopToolbarCustomActions={() => {
          return <h3 className="mx-3 mt-3"></h3>;
        }}
        enableRowNumbers
      />
      {/* <DeleteConfirmationModal
        isOpen={openDeleteModal}
        toggle={toggleDeleteModal}
        data={deleteItem}
        mutation={deleteMutation}
      /> */}

      {/* <EditLocationModal
        isOpen={openEditModal}
        toggle={toggleEditModal}
        data={editItem}
        refetch={refetch}
      /> */}
    </div>
  );
};

export default AttendanceListing;
