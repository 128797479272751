import React, { useState } from "react";

import AddNewLocation from "../../components/location/add-new-location.component";
import LocationListing from "../../components/location/location-listing.component";


const ManageLocations = () => {

    document.title = 'إدارة المواقع | صناع السعادة'

    const [activeSection, setActiveSection] = useState('add')


  return (
    <>

      <div className="container-fluid mt-4 mb-3">
        {/* container fluid */}
        <div className="bg-primary breadcrumb-item text-white text-end active fs-4"
          aria-current="page">
        إدارة المواقع
        </div>

        <div className="text-end">
         
          <button onClick={() => setActiveSection('manage')} className={`custom-btn ${activeSection == 'manage' ? 'active-btn': 'inactive-btn'} mt-3`}> إدارة المواقع</button>
          
          <button onClick={() => setActiveSection('add')} className={`custom-btn ${activeSection == 'add' ? 'active-btn': 'inactive-btn'} ms-2 mt-3`}>إضافة الموقع جديد</button>

        </div>
        

      {
            activeSection == 'add' ?
            <AddNewLocation/>
            :
            <LocationListing/>

      }
      </div>
    </>
  )
}


export default ManageLocations;