import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  Spinner,
} from "reactstrap";

import { useFormik } from "formik";

import { trainerSchema } from "../../validations/validation-schemas";
import { useMutation } from "react-query";

import useAxios from "../../api/useAxios";

import { toast } from "react-toastify";
import IP from "../../sections/variables";

import ReactQuill from "react-quill";

const EditTrainerModal = ({ isOpen, toggle, data, refetch }) => {
  const api = useAxios();

  const URL = `${IP}/api`;

  const editTrainer = (payload) => {
    return api.patch(`${URL}/courses/trainer/${data?.id}`, payload);
  };

  const editTrainerMutation = useMutation(editTrainer, {
    onSuccess: () => {
      toast.success("محدث");
      toggle();
      refetch();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const onSubmit = () => {
    editTrainerMutation.mutate(values);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      name: data?.name ?? "",
      email: data?.email ?? "",
      description: data?.description ?? "",
    },

    validationSchema: trainerSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-md" centered>
      <ModalHeader toggle={toggle}>
        <h4 className="text-primary mb-0">تحرير المدرب</h4>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <div className="col-12">
            <div className="text-end">
              <Label className="form-label text-end mt-3 mb-1">
                اسم المدرب
              </Label>
            </div>
            <Input
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              className={
                errors.name && touched.name
                  ? "form-control form-control-icon is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
              id="nameinput"
              name="name"
              placeholder="أدخل اسم المدرب"
              dir="rtl"
            />
            {errors.name && touched.name && (
              <div className="invalid-feedback">{errors.name}</div>
            )}
          </div>

          <div className="col-12">
            <div className="text-end">
              <Label className="form-label mt-3 mb-1">
                البريد الإلكتروني للمدرب
              </Label>
            </div>
            <Input
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              type="email"
              className={
                errors.email && touched.email
                  ? "form-control form-control-icon is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
              id="emailInput"
              name="email"
              placeholder="أدخل البريد الإلكتروني للمدرب"
              dir="rtl"
            />
            {errors.email && touched.email && (
              <div className="invalid-feedback">{errors.email}</div>
            )}
          </div>

          <div className="col-12">
            <div className="text-end">
              <Label className="form-label mt-3 mb-1">وصف المدرب</Label>
            </div>
            <ReactQuill
              theme="snow"
              className={
                errors.description && touched.description && "ql-error"
              }
              value={values.description}
              onChange={(e) => setFieldValue("description", e)}
            />
            {errors.description && touched.description && (
              <p className="text-danger text-start">{errors.description}</p>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          {editTrainerMutation.isLoading ? (
            <p>يعالج...</p>
          ) : (
            <>
              <button
                onClick={toggle}
                type="button"
                className="custom-btn no-btn px-3"
              >
                لا
              </button>
              <button
                type="submit"
                className="custom-btn active-btn"
                disabled={editTrainerMutation.isLoading}
              >
                {editTrainerMutation.isLoading ? "يعالج" : " تحديث"}
                {editTrainerMutation.isLoading && (
                  <Spinner size="sm" className="flex-shrink-0 ms-2" />
                )}
              </button>
            </>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditTrainerModal;
