import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { useDropzone } from "react-dropzone";

import { useFormik } from "formik";

import { courseSchema } from "../../validations/validation-schemas";

import ReactQuill from "react-quill";
import { useMutation } from "react-query";

import useAxios from "../../api/useAxios";

import { toast } from "react-toastify";
import IP from "../../sections/variables";

import { useQuery } from "react-query";

const EditCourseModal = ({ isOpen, toggle, data, refetch }) => {
  const api = useAxios();

  const URL = `${IP}/api`;

  //   const editCourse = (payload) => {
  //     console.log(payload);
  //     return api.patch(`${URL}/courses/${data?.id}`, payload);
  //   };

  const editCourse = async (payload) => {
    const formData = new FormData();
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        // Append each key-value pair to the formData
        formData.append(key, payload[key]);
      }
    }
    // formData.append("name", payload.name);
    // formData.append("description", payload.description);
    // formData.append("title_img", payload.title_img);

    try {
      const response = await api.patch(`${URL}/courses/${data?.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  };
 
  const editCourseMutation = useMutation(editCourse, {
    onSuccess: () => {
      toast.success("محدث");
      toggle();
      refetch();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const onSubmit = () => {
    let newValues = {};
    if (values?.title_img !== data?.title_img) {
      newValues = {
        ...newValues,
        title_img: values.title_img,
      };
    }
    if (values?.name !== data?.name) {
      newValues = {
        ...newValues,
        name: values.name,
      };
    }
    if (values?.description !== data?.description) {
      newValues = {
        ...newValues,
        description: values.description,
      };
    }
    editCourseMutation.mutate(newValues);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      name: data?.name ?? "",
      title_img: data?.title_img ?? "",
      description: data?.description ?? "",
    },

    validationSchema: courseSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        const maxSize = 5 * 1024 * 1024; // 5 MB
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

        console.log(maxSize);
        console.log(file.size);
        if (file.size > maxSize) {
          toast.error("يتجاوز حجم الملف الحد المسموح به وهو 5 ميغابايت");
        } else if (!allowedTypes.includes(file.type)) {
          toast.error("نوع الملف غير صالح. الرجاء استخدام PNG، JPEG، أو JPG.");
        } else {
          setFieldValue("title_img", file);
        }
      }
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/png, image/jpeg, image/jpg",
    maxSize: 5 * 1024 * 1024, // 5 MB
    multiple: false,
  });

  const handleRemoveImage = () => {
    setFieldValue("title_img", null);
  };

  // getting list of categories
  // const {
  //     isLoading: loadingCategories,
  //     isError: errorCategories,
  //     data: responseCategories,
  // } = useQuery(`categories`, async () => api.get('/courses/categories'), { cacheTime: 0 })

  // const categories = responseCategories?.data?.course_categories ?? []

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-lg" centered>
      <ModalHeader toggle={toggle}>
        <h4 className="text-primary mb-0">تحرير فئة الدورة التدريبية</h4>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <div className="row text-end justify-content-end">
            <div className="col-12 col-lg-6">
              <Label className="form-label text-start mt-3 mb-1">
                عنوان الصورة (الحجم الأقصى: 5 ميجابايت)
              </Label>
              <div
                {...getRootProps()}
                className={
                  isDragActive || values.title_img
                    ? "dropzone active"
                    : "dropzone"
                }
              >
                <input {...getInputProps()} />
                {values.title_img && (
                  <div>
                    <p className="form-control input-border-primary">
                      File: {values.title_img.name}
                    </p>
                    <div className="d-flex flex-row-reverse align-items-center justify-content-between">
                      <button
                        type="button"
                        onClick={handleRemoveImage}
                        style={{ borderRadius: "10px" }}
                        className="btn btn-danger btn-sm"
                      >
                        إزالة الصورة
                      </button>
                      {typeof values.title_img == "string" && (
                        <div className="course-edit-img">
                          <img src={values.title_img} />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!values.title_img && (
                  <p
                    className={
                      errors.name && touched.name
                        ? "form-control form-control-icon mb-0 is-invalid col-6"
                        : "form-control form-control-icon mb-0 input-border-primary col-6"
                    }>
                    {isDragActive
                      ? "قم بإفلات الملف هنا"
                      : "اسحب وأسقط صورة هنا"}
                  </p>
                )}
              </div>
              {errors.title_img && (
                <div className="text-danger" style={{fontSize: '14px'}}>{errors.title_img}</div>
              )}
            </div>
            <div className="col-12 col-lg-6">
              <Label className="form-label text-start mt-3 mb-1">
                اسم الدورة التدريبية*
              </Label>

              <Input
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                className={
                  errors.name && touched.name
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon input-border-primary col-6"
                }
                id="courseInput"
                name="name"
                placeholder="أدخل اسم الدورة"
                dir="rtl"
              />
              {errors.name && touched.name && (
                <div className="invalid-feedback">{errors.name}</div>
              )}
            </div>
            {/* <div className="col-12 col-md-6">
                            <Label className="form-label text-start mt-3 mb-1">Course Category*</Label>
                            {
                                errorCategories ?
                                    <>
                                        <br />
                                        <Label className="form-label text-start mt-3 mb-1 text-danger">Could not load categories</Label>
                                    </>
                                    :
                                    <Input
                                        value={values.category}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="select"
                                        className={errors.category && touched.category ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon input-border-primary col-6"}
                                        id="titleInput"
                                        name="category"
                                        dir="rtl"
                                    >
                                        <option value="">{loadingCategories ? 'Loading Categories' : 'Choose'}</option>
                                        {categories.map((category) => (
                                            <option value={category?.id}>{category?.name}</option>
                                        ))}
                                    </Input>
                            }
                            {
                                errors.category && touched.category && <div className="invalid-feedback">{errors.category}</div>
                            }
                        </div> */}

            <div className="col-12">
              <Label className="form-label text-start mt-3 mb-1">
                وصف الدورة التدريبية
              </Label>
              <ReactQuill
                theme="snow"
                className={
                  errors.description && touched.description && "ql-error"
                }
                value={values.description}
                onChange={(e) => setFieldValue("description", e)}
              />
              {errors.description && touched.description && (
                <p className="text-danger text-start">{errors.description}</p>
              )}
            </div>
            {/* 
            <div className="col-12">
              <Label className="form-label text-start mt-3 mb-1">
                Course Outline
              </Label>
              <ReactQuill
                theme="snow"
                value={values.outline}
                className={errors.outline && touched.outline && "ql-error"}
                onChange={(e) => setFieldValue("outline", e)}
              />
              {errors.outline && touched.outline && (
                <p className="text-danger text-start">{errors.outline}</p>
              )}
            </div>

            <div className="col-12">
              <Label className="form-label text-start mt-3 mb-1">
                Course Certification
              </Label>
              <ReactQuill
                theme="snow"
                value={values.certification}
                className={
                  errors.certification && touched.certification && "ql-error"
                }
                onChange={(e) => setFieldValue("certification", e)}
              />
            </div>

            <div className="col-12">
              <Label className="form-label text-start mt-3 mb-1">FAQs</Label>
              <ReactQuill
                theme="snow"
                value={values.faqs}
                dir="rtl"
                className={errors.faqs && touched.faqs && "ql-error"}
                onChange={(e) => setFieldValue("faqs", e)}
              />
            </div> */}
          </div>
        </ModalBody>
        <ModalFooter>
          {editCourseMutation.isLoading ? (
            <p>يعالج...</p>
          ) : (
            <>
              <button
                onClick={toggle}
                type="button"
                className="custom-btn no-btn px-3"
              >
                لا
              </button>
              <button
                type="submit"
                className="custom-btn active-btn"
                disabled={editCourseMutation.isLoading}
              >
                {editCourseMutation.isLoading ? "...تحديث" : " تحديث"}
                {editCourseMutation.isLoading && (
                  <Spinner size="sm" className="flex-shrink-0 ms-2" />
                )}
              </button>
            </>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditCourseModal;
