import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Input, Label } from "reactstrap";
import useAxios from "../../api/useAxios";
import IP from "../../sections/variables";
import { MdDelete } from "react-icons/md";

const AddNewQuestion = ({ addSection, refetchFeedback, feedbackID }) => {
  const [simpleQuestion, setSimpleQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [option, setOption] = useState("");

  const api = useAxios();
  const URL = `${IP}/api`;

  const sendSimpleQ = () => {
    return api.post(`${URL}/feedback/add-new-question`, {
      feedback_form: feedbackID,
      question_text: simpleQuestion,
    });
  };

  const addSimpleQMutation = useMutation(sendSimpleQ, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
      setSimpleQuestion("");
      refetchFeedback();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  
  const sendMCQ = () => {
    return api.post(`${URL}/feedback/add-new-mcq`, {
      feedback_form: feedbackID,
      question_text: simpleQuestion,
      options,
    });
  };

  const addMCQMutation = useMutation(sendMCQ, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
      setOptions([]);
      setOption("");
      setSimpleQuestion("");
      refetchFeedback();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  console.log(options);

  return (
    <div className="mt-4">
      {addSection == "simpleQ" ? (
        <>
          <Label>أضف سؤالا</Label>
          <Input
            type="text"
            dir="rtl"
            value={simpleQuestion}
            onChange={(e) => setSimpleQuestion(e.target.value)}
          />
          <button
            className="custom-btn active-btn mt-3"
            disabled={simpleQuestion == "" || addSimpleQMutation.isLoading}
            onClick={() => addSimpleQMutation.mutate()}
          >
            {addSimpleQMutation.isLoading ? (
              <div
                class="spinner-border spinner-border-sm text-light"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : (
              "يضيف"
            )}
          </button>
        </>
      ) : addSection == "MCQ" ? (
        <>
          <Label>أضف سؤالا</Label>
          <Input
            type="text"
            dir="rtl"
            value={simpleQuestion}
            onChange={(e) => setSimpleQuestion(e.target.value)}
          />
          <div>
            <div className="col-12 mt-3">
              <div className="d-flex  align-items-end flex-row-reverse gap-3">
                <div>
                  <Label>الخيار</Label>
                  <Input
                    type="text"
                    dir="rtl"
                    className="form-control form-control-icon input-border-primary"
                    value={option}
                    onChange={(e) => setOption(e.target.value)}
                    placeholder="أدخل الخيار"
                  />
                </div>
                <div className="text-start">
                  <button
                    type="button"
                    className="custom-btn active-btn  cursor-pointer"
                    disabled={option == ""}
                    onClick={() => {
                      setOptions((prev) => [...prev, option]);
                      setOption("");
                    }}
                  >
                    إضافة خيار
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-6">
                {options?.length > 0 && (
                  <div className="mt-3">
                    <p>
                      (الحد الأدنى 2 خيار)<b> :خيارات</b>
                    </p>
                    {options?.map((option, optionIndex) => (
                      <div key={optionIndex}>
                        <div className="d-flex flex-row-reverse justify-content-between align-items-center mt-2">
                          <p className="mb-0">{option}</p>
                          <MdDelete
                            className="trash-icon cursor-pointer"
                            onClick={() =>
                              setOptions((prev) =>
                                prev?.filter((_, i) => i !== optionIndex)
                              )
                            }
                          />
                          {/* <button

                  >
                    Remove
                  </button> */}
                        </div>
                        <hr />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* "يرجى إضافة خيارين على الأقل" */}
            </div>
            {/* {options?.length > 0 && (
              <div className="mt-3">
                <p>
                  (الحد الأدنى 2 خيار)<b> :خيارات</b>
                </p>
                {options.map((option, optionIndex) => (
                  <div key={optionIndex}>
                    <div className="d-flex flex-row-reverse justify-content-between align-items-center mt-2">
                      <p className="mb-0">{option}</p>
                      <MdDelete
                        className="trash-icon cursor-pointer"
                        onClick={() =>
                          setOptions((prev) =>
                            prev?.filter((_, i) => i !== optionIndex)
                          )
                        }
                      />
                    </div>
                    <hr />
                  </div>
                ))}
              </div>
            )} */}
          </div>
          <button
            className="custom-btn active-btn mt-3"
            disabled={
              simpleQuestion == "" ||
              !(options?.length > 1) ||
              addMCQMutation.isLoading
            }
            onClick={() => addMCQMutation.mutate()}
          >
            {addMCQMutation.isLoading ? (
              <div
                class="spinner-border spinner-border-sm text-light"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : (
              "يضيف"
            )}
          </button>
        </>
      ) : null}
    </div>
  );
};

export default AddNewQuestion;
