import React from "react";

import { Label, Input } from "reactstrap";
import ReactQuill from "react-quill";
import { useFormik } from "formik";

import { trainerSchema } from "../../validations/validation-schemas";
import { toast } from "react-toastify";

import { useMutation } from "react-query";

import useAxios from "../../api/useAxios";

import IP from "../../sections/variables";

const AddNewTrainer = () => {
  const api = useAxios();

  const URL = `${IP}/api`;

  const addTrainer = (payload) => {
    console.log(payload);
    return api.post(`${URL}/courses/add-trainer`, payload);
  };

  const addTrainerMutation = useMutation(addTrainer, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
      resetForm();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  console.log(addTrainerMutation.isLoading);

  const onSubmit = () => {
    addTrainerMutation.mutate(values);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      description: "",
    },

    validationSchema: trainerSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="wrapper">
      <form onSubmit={handleSubmit}>
        <div className="row text-end justify-content-end">
          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">
              اسم المدرب
            </Label>
            <Input
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              className={
                errors.name && touched.name
                  ? "form-control form-control-icon is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
              id="nameinput"
              name="name"
              placeholder="أدخل اسم المدرب"
              dir="rtl"
            />
            {errors.name && touched.name && (
              <div className="invalid-feedback">{errors.name}</div>
            )}
          </div>

          <div className="col-12 col-md-6">
            <Label className="form-label text-start mt-3 mb-1">
              البريد الإلكتروني للمدرب
            </Label>
            <Input
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              type="email"
              className={
                errors.email && touched.email
                  ? "form-control form-control-icon is-invalid col-6"
                  : "form-control form-control-icon input-border-primary col-6"
              }
              id="emailInput"
              name="email"
              placeholder="أدخل البريد الإلكتروني للمدرب"
              dir="rtl"
            />
            {errors.email && touched.email && (
              <div className="invalid-feedback">{errors.email}</div>
            )}
          </div>

          <div className="col-12">
            <Label className="form-label text-start mt-3 mb-1">
              وصف المدرب
            </Label>
            <ReactQuill
              theme="snow"
              className={
                errors.description && touched.description && "ql-error"
              }
              value={values.description}
              onChange={(e) => setFieldValue("description", e)}
            />
            {errors.description && touched.description && (
              <p className="text-danger text-start">{errors.description}</p>
            )}
          </div>

          <div>
            <button
              className="custom-btn active-btn ms-2 mt-3"
              style={{ minWidth: "60px" }}
            >
              {addTrainerMutation.isLoading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                "يضيف"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewTrainer;
