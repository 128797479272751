import React, { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";
import { MdCheck, MdDelete } from "react-icons/md";
import { useMutation } from "react-query";
import useAxios from "../../api/useAxios";
import { toast } from "react-toastify";
import IP from "../../sections/variables";
import MCQOption from "./mcq-option.component";

const MCQQuestion = ({ question, idx, refetchFeedback, simpleLength }) => {
  const [quest, setQuest] = useState(question?.statement);
  const [newOption, setNewOption] = useState("");

  const api = useAxios();

  const URL = `${IP}/api`;

  const sendUpdate = (payload) => {
    return api.patch(`${URL}/feedback/mcq/${question?.id}`, payload);
  };

  const updateQuestionMutation = useMutation(sendUpdate, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const handleUpdateQuestion = () => {
    updateQuestionMutation.mutate({
      statement: quest,
    });
  };

  const sendDelete = () => {
    return api.delete(`${URL}/feedback/mcq/${question?.id}`);
  };

  const deleteQuestionMutation = useMutation(sendDelete, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
      refetchFeedback();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });
  const sendOption = () => {
    return api.post(`${URL}/feedback/add-choice-option/${question?.id}`, {
      option_text: newOption,
    });
  };

  const addOptionMutation = useMutation(sendOption, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
      setNewOption("");
      refetchFeedback();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  return (
    <div className="mb-5 pb-3" key={question?.id}>
      <div className="d-flex flex-row-reverse justify-content-between ">
        <h5>السؤال{simpleLength + idx + 1}</h5>
        <div className="d-flex gap-5">
          <MdDelete
            className="trash-icon custom-md-icon cursor-pointer"
            onClick={() => deleteQuestionMutation.mutate()}
          />

          <MdCheck
            className="text-success custom-md-icon"
            onClick={handleUpdateQuestion}
          />
        </div>
      </div>
      <Input
        dir="rtl"
        type="text"
        className="form-control form-control-icon input-border-primary "
        value={quest}
        onChange={(e) => setQuest(e.target.value)}
      />
      <div className="row text-end justify-content-end">
        {question?.options?.map((op, index) => {
          return (
            <MCQOption
              key={op?.id}
              data={op}
              idx={index}
              refetchFeedback={refetchFeedback}
            />
          );
        })}
      </div>
      <div className="row text-end justify-content-end mt-3">
        <div className="col-6 d-flex flex-row-reverse align-items-center gap-3">
          <Input
            dir="rtl"
            name="newOption"
            placeholder="أدخل خيارا جديدا"
            value={newOption}
            onChange={(e) => setNewOption(e.target.value)}
          />
          <button
            className="custom-btn active-btn"
            disabled={newOption == "" || addOptionMutation.isLoading}
            onClick={() => addOptionMutation.mutate()}
          >
            {addOptionMutation.isLoading ? (
              <div
                class="spinner-border spinner-border-sm text-light"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : (
              "يضيف"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MCQQuestion;
