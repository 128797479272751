import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import createCertificate from "../../utils/certificate-generator";
import vendorCreateCertificate from "../../utils/vendor-certificate-generator";

const DownloadMultipleCertificates = ({ registrations, courseName }) => {
  const [downloading, setDownloading] = useState(false);

  var trainer = registrations[0]?.trainer


  const handleDownload = async () => {
    try {
      setDownloading(true);
      const zip = new JSZip();

      await Promise.all(
        registrations.map(async (registration, index) => {
          var pdfBytes;
          if (registration.trainer == "ESM" || registration.trainer == "Event Academy" || registration.trainer == "Fhm" || registration.trainer == "Fennec"){
            pdfBytes = await vendorCreateCertificate(registration);
            }
            else{
            pdfBytes = await createCertificate(registration);
          }
          if (pdfBytes) {
            zip.file(`Certificate_${index + 1}.pdf`, pdfBytes);
          }
        })
      );

      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `Certificates - ${courseName}.zip`);
    } catch (error) {
      console.error("Error downloading certificates:", error);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Button
      variant="contained"
      style={{ marginBottom: "10px", backgroundColor: "#8E46CD" }}
      onClick={handleDownload}
      disabled={downloading || registrations?.length === 0}
      startIcon={downloading ? <CircularProgress size={20} /> : null}
    >
      {downloading ? "Downloading..." : "Download Certificates"}
    </Button>
  );
};

export default DownloadMultipleCertificates;
