import React from 'react';
import DynamicPieChart from './pie-chart.component';


const FeedbackCharts = ({ data }) => {


  return (
    <div className='container-fluid'>
      <div className='row'>
      <div
        className="container-fluid mt-0 mb-3">
        <div
          className="bg-primary breadcrumb-item text-white text-end active fs-4"
          aria-current="page">
         ردود الفعل
        </div>
      </div>


      {data.map((questionData, index) => {
        const { question, answers } = questionData;

        // Extracting labels and data for the pie chart
        const labels = answers.map(answer => Object.keys(answer)[0]);
        const data = answers.map(answer => Object.values(answer)[0]);

        // Generating a unique title for each pie chart
        const title = question;

        // Creating a unique key for each pie chart
        const chartKey = `pie-chart-${index}`;

        // Restructuring the data for DynamicPieChart
        const formattedData = labels.reduce((acc, label, idx) => {
          acc[label] = data[idx];
          return acc;
        }, {});

        return (
          <div className='col-12 col-md-4'>
          <DynamicPieChart
            key={chartKey}
            values={formattedData}
            title={title}
            />
            </div>
        );
      })}
    </div>
    </div>
  );
};

export default FeedbackCharts;
