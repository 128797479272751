import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import ARcertificate from "../assets/certificate/arabic_certificate.pdf";
import ENCertificate from "../assets/certificate/english_certificate.pdf";
import customFontFile from "../assets/certificate/Tajawal-Regular.ttf";

import { arabicCoords, englishCoords } from "./coords-generator";
import { arToEn } from "./formatDate";

const createCertificate = async (registration) => {
  var language = "arabic";

  try {
    let pdfBytes;

    if (registration?.course_lang == "العربية") {
      pdfBytes = await fetch(ARcertificate).then((res) => res.arrayBuffer());
    } else {
      language = "english";
      pdfBytes = await fetch(ENCertificate).then((res) => res.arrayBuffer());
    }

    const pdfDoc = await PDFDocument.load(pdfBytes);
    pdfDoc.registerFontkit(fontkit);

    // if (language == "arabic") {
    //   pdfDoc.setLanguage("ar-sy");
    // }

    const customFontBytes = await fetch(customFontFile).then((res) =>
      res.arrayBuffer()
    );
    const customFont = await pdfDoc.embedFont(customFontBytes);
    const [firstPage] = pdfDoc.getPages();

    const arName = registration?.candidate?.ar_name;
    const enName = registration?.candidate?.en_name;
    const course = registration?.course_name;
    let startDate = registration?.start_date;
    let endDate = registration?.end_date;

    var hours = registration?.course_hours.toString();

    const fontSize14 = 14;
    const fontSize11 = 11;

    const { width, height } = firstPage.getSize();

    const arNameWidth = customFont.widthOfTextAtSize(arName, fontSize14);

    let enNameWidth;

    if (
      Object.keys(registration.candidate).includes("en_name") &&
      registration?.candidate?.en_name != null &&
      language == "english"
    ) {
      enNameWidth = customFont.widthOfTextAtSize(enName, fontSize14);
    } else {
      enNameWidth = customFont.widthOfTextAtSize(arName, fontSize14);
    }

    const courseWidth = customFont.widthOfTextAtSize(course, fontSize14);

    let coords;

    if (language == "arabic") {
      coords = arabicCoords(width, arNameWidth, courseWidth, height);
    } else {
      coords = englishCoords(width, enNameWidth, courseWidth, height);
      startDate = arToEn(startDate);
      endDate = arToEn(endDate);
    }

    var hours_arabic = "ساعة"

    if (
      Object.keys(registration.candidate).includes("en_name") &&
      registration?.candidate?.en_name != null &&
      language == "english"
    ) {
      console.log(course, "I am printing English name");
      firstPage.drawText(enName, {
        x: coords.nameX,
        y: coords.nameY,
        font: customFont,
        size: fontSize14,
        color: rgb(0, 0, 0),
      });
    } else {
      console.log(course, "I am printing ARABIC name");

      firstPage.drawText(arName, {
        x: coords.nameX,
        y: coords.nameY,
        font: customFont,
        size: fontSize14,
        color: rgb(0, 0, 0),
      });
    
      firstPage.drawText(hours_arabic, {
        x: coords.hoursX - 40,
        y: coords.hoursY,
        font: customFont,
        size: fontSize14,
        color: rgb(0, 0, 0),
      });

    }

    // firstPage.drawText(name, {
    //   x: coords.nameX,
    //   y: coords.nameY,
    //   font: customFont,
    //   size: fontSize14,
    //   color: rgb(0, 0, 0),
    // });
    firstPage.drawText(course, {
      x: coords.courseX,
      y: coords.courseY,
      font: customFont,
      size: coords.courseFont,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(startDate, {
      x: coords.startDateX,
      y: coords.startDateY,
      font: customFont,
      size: fontSize14,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(endDate, {
      x: coords.endDateX,
      y: coords.endDateY,
      font: customFont,
      size: fontSize14,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(hours, {
      x: coords.hoursX,
      y: coords.hoursY,
      font: customFont,
      size: fontSize14,
      color: rgb(0, 0, 0),
    });

    return pdfDoc.save();
  } catch (error) {
    console.error("Error creating PDF:", error);
    return null;
  }
};

export default createCertificate;
