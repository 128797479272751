import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MdDelete } from "react-icons/md";

import { questionSchema } from "../../validations/validation-schemas";

const MultipleChoiceQuestion = ({
  questionNo,
  handleSubmit,
  setError,
  index,
  onDelete,
  course,
  category,
  passingMarks,
}) => {
  const [statement, setStatement] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");
  const [errorMCQ, setErrorMCQ] = useState(false);
  const [deleteQ, setDeleteQ] = useState(false);
  const [correctOption, setcorrectOption] = useState("");

  // const isInitialRender = useRef(true);

  const handleRadioChange = (event) => {
    setcorrectOption(event.target.value);
  };

  // const {
  //   values,
  //   errors,
  //   touched,
  //   handleBlur,
  //   handleChange,
  //   handleSubmit,
  //   setFieldValue,
  //   resetForm,
  // } = useFormik({
  //   initialValues: {
  //     statement: "",
  //     option1: "",
  //     option2: "",
  //     option3: "",
  //     option4: "",
  //     correctOption: "",
  //   },
  //   validationSchema: questionSchema,
  //   onSubmit: (values) => {
  //
  //     console.log("val", values);
  //   },
  // });

  const handleMCQSubmit = () => {
    handleSubmit({
      questionNo,
      statement,
      option1,
      option2,
      option3,
      option4,
      correct_option: correctOption,
    });
  };

  useEffect(() => {
    // if (deleteQ) {
    //   setErrorMCQ(false);
    // } else
    if (
      statement == "" ||
      option1 == "" ||
      option2 == "" ||
      option3 == "" ||
      option4 == "" ||
      correctOption == ""
    ) {
      setErrorMCQ(true);
    } else {
      setErrorMCQ(false);
    }
    setError({ error: errorMCQ, id: questionNo });

    if (
      statement &&
      option1 &&
      option2 &&
      option3 &&
      option4 &&
      correctOption &&
      !errorMCQ
    ) {
      handleMCQSubmit();
    }
  }, [statement, option1, option2, option3, option4, correctOption, errorMCQ]);

  return (
    <form onSubmit={handleMCQSubmit} className="question-form col-12 w-100">
      <div className="d-flex justify-content-start w-100 mx-3">
        <MdDelete
          className="trash-icon cursor-pointer"
          onClick={() => {
            setError({ error: false, id: questionNo });
            onDelete(index, questionNo);
          }}
        />
      </div>

      <div>
        <label htmlFor="question" className="mt-2">
          سؤال
        </label>
        <input
          type="text"
          id="question"
          name="question"
          dir="rtl"
          onChange={(e) => {
            setStatement(e.target.value);
            // !statement
            //   ? setErrorMCQ({
            //       ...errorMCQ, // Copy existing values
            //       statement: true /* modify the specific value for statement error */,
            //     })
            //   : setErrorMCQ({
            //       ...errorMCQ, // Copy existing values
            //       statement: false /* modify the specific value for statement error */,
            //     });
          }}
          value={statement}
          placeholder="أدخل السؤال"
          className="form-control form-control-icon input-border-primary col-6 mb-3"
        />
      </div>

      <div className="row flex-row-reverse">
        <div className="col-3">
          الخيار 1
          <input
            type="text"
            dir="rtl"
            id="option1"
            name="option1"
            onChange={(e) => {
              setOption1(e.target.value);
              // !option1
              //   ? setErrorMCQ({
              //       ...errorMCQ, // Copy existing values
              //       option1: true /* modify the specific value for statement error */,
              //     })
              //   : setErrorMCQ({
              //       ...errorMCQ, // Copy existing values
              //       option1: false /* modify the specific value for statement error */,
              //     });
            }}
            value={option1}
            placeholder="أدخل الخيار"
            className="form-control form-control-icon input-border-primary"
          />
          <div className="d-flex justify-content-end mt-3 mx-3">
            <label htmlFor="">الخيار الصحيح</label>
            <input
              type="radio"
              name="option"
              value="option1"
              checked={correctOption === "option1"}
              onChange={handleRadioChange}
            />
          </div>
        </div>
        <div className="col-3">
          الخيار 2
          <input
            type="text"
            dir="rtl"
            id="option2"
            name="option2"
            onChange={(e) => {
              setOption2(e.target.value);

              // !option2
              //   ? setErrorMCQ({
              //       ...errorMCQ, // Copy existing values
              //       option2: true /* modify the specific value for statement error */,
              //     })
              //   : setErrorMCQ({
              //       ...errorMCQ, // Copy existing values
              //       option2: false /* modify the specific value for statement error */,
              //     });
            }}
            value={option2}
            placeholder="أدخل الخيار"
            className="form-control form-control-icon input-border-primary"
          />
          <div className="d-flex justify-content-end mt-3 mx-3">
            <label htmlFor="">الخيار الصحيح</label>
            <input
              type="radio"
              name="option"
              value="option2"
              checked={correctOption === "option2"}
              onChange={handleRadioChange}
            />
          </div>
        </div>
        <div className="col-3">
          الخيار 3
          <input
            type="text"
            dir="rtl"
            id="option3"
            name="option3"
            onChange={(e) => {
              setOption3(e.target.value);
              // !option3
              //   ? setErrorMCQ({
              //       ...errorMCQ, // Copy existing values
              //       option3: true /* modify the specific value for statement error */,
              //     })
              //   : setErrorMCQ({
              //       ...errorMCQ, // Copy existing values
              //       option3: false /* modify the specific value for statement error */,
              //     });
            }}
            value={option3}
            placeholder="أدخل الخيار"
            className="form-control form-control-icon input-border-primary"
          />
          <div className="d-flex justify-content-end mt-3 mx-3">
            <label htmlFor="">الخيار الصحيح</label>
            <input
              type="radio"
              name="option"
              value="option3"
              checked={correctOption === "option3"}
              onChange={handleRadioChange}
            />
          </div>
        </div>
        <div className="col-3">
          الخيار 4
          <input
            type="text"
            dir="rtl"
            id="option4"
            name="option4"
            onChange={(e) => {
              setOption4(e.target.value);

              // !option4
              //   ? setErrorMCQ({
              //       ...errorMCQ, // Copy existing values
              //       option4: true /* modify the specific value for statement error */,
              //     })
              //   : setErrorMCQ({
              //       ...errorMCQ, // Copy existing values
              //       option4: false /* modify the specific value for statement error */,
              //     });
            }}
            value={option4}
            placeholder="أدخل الخيار"
            className="form-control form-control-icon input-border-primary"
          />
          <div className="d-flex justify-content-end mt-3 mx-3">
            <label htmlFor="">الخيار الصحيح</label>
            <input
              type="radio"
              name="option"
              value="option4"
              checked={correctOption === "option4"}
              onChange={handleRadioChange}
            />
          </div>
        </div>
      </div>
      {errorMCQ || !category || !course || !passingMarks ? (
        <div className="text-danger">يرجى ملء جميع الحقول بشكل صحيح</div>
      ) : null}
    </form>

    // <form onSubmit={handleSubmit} className="question-form col-12">
    //   <div>
    //     <label htmlFor="question" className="mt-3">
    //       سؤال
    //     </label>
    //     <input
    //       type="text"
    //       id="question"
    //       name="question"
    //       dir="rtl"
    //       onChange={(e) => {
    //         handleChange(e);
    //         handleSubmit();
    //       }}
    //       onBlur={handleBlur}
    //       value={values.question}
    //       placeholder="أدخل السؤال"
    //       className="form-control form-control-icon input-border-primary col-6 mb-3"
    //     />

    //     {touched.question && errors.question ? (
    //       <div className="text-danger">{errors.question}</div>
    //     ) : null}
    //   </div>

    //   <div className="row flex-row-reverse">
    //     <div className="col-3">
    //       الخيار 1
    //       <input
    //         type="text"
    //         dir="rtl"
    //         id="option1"
    //         name="option1"
    //         onChange={(e) => {
    //           handleChange(e);
    //           handleSubmit();
    //         }}
    //         onBlur={handleBlur}
    //         value={values.option1}
    //         placeholder="أدخل الخيار"
    //         className="form-control form-control-icon input-border-primary"
    //       />
    //       <div className="d-flex justify-content-end mt-3 mx-3">
    //         <input
    //           type="radio"
    //           name="correctOption"
    //           value="option1"
    //           onChange={(e) => {
    //             handleChange(e);
    //             handleSubmit();
    //           }}
    //         />
    //       </div>
    //       {touched.option1 && touched.option1 && errors.option1 ? (
    //         <div className="text-danger">{errors.option1}</div>
    //       ) : null}
    //     </div>
    //     <div className="col-3">
    //       الخيار 2
    //       <input
    //         type="text"
    //         dir="rtl"
    //         id="option2"
    //         name="option2"
    //         onChange={(e) => {
    //           handleChange(e);
    //           handleSubmit();
    //         }}
    //         onBlur={handleBlur}
    //         value={values.option2}
    //         placeholder="أدخل الخيار"
    //         className="form-control form-control-icon input-border-primary"
    //       />
    //       <div className="d-flex justify-content-end mt-3 mx-3">
    //         <input
    //           type="radio"
    //           name="correctOption"
    //           value="option2"
    //           onChange={(e) => {
    //             handleChange(e);
    //             handleSubmit();
    //           }}
    //         />
    //       </div>
    //       {touched.option2 && touched.option2 && errors.option2 ? (
    //         <div className="text-danger">{errors.option2}</div>
    //       ) : null}
    //     </div>
    //     <div className="col-3">
    //       الخيار 3
    //       <input
    //         type="text"
    //         dir="rtl"
    //         id="option3"
    //         name="option3"
    //         onChange={(e) => {
    //           handleChange(e);
    //           handleSubmit();
    //         }}
    //         onBlur={handleBlur}
    //         value={values.option3}
    //         placeholder="أدخل الخيار"
    //         className="form-control form-control-icon input-border-primary"
    //       />
    //       <div className="d-flex justify-content-end mt-3 mx-3">
    //         <input
    //           type="radio"
    //           name="correctOption"
    //           value="option3"
    //           onChange={(e) => {
    //             handleChange(e);
    //             handleSubmit();
    //           }}
    //         />
    //       </div>
    //       {touched.option3 && touched.option3 && errors.option3 ? (
    //         <div className="text-danger">{errors.option3}</div>
    //       ) : null}
    //     </div>
    //     <div className="col-3">
    //       الخيار 4
    //       <input
    //         type="text"
    //         dir="rtl"
    //         id="option4"
    //         name="option4"
    //         onChange={(e) => {
    //           handleChange(e);
    //           handleSubmit();
    //         }}
    //         onBlur={handleBlur}
    //         value={values.option4}
    //         placeholder="أدخل الخيار"
    //         className="form-control form-control-icon input-border-primary"
    //       />
    //       <div className="d-flex justify-content-end mt-3 mx-3">
    //         <input
    //           type="radio"
    //           name="correctOption"
    //           value="option4"
    //           onChange={(e) => {
    //             handleChange(e);
    //             handleSubmit();
    //           }}
    //         />
    //       </div>
    //       {touched.option4 && touched.option4 && errors.option4 ? (
    //         <div className="text-danger">{errors.option4}</div>
    //       ) : null}
    //     </div>
    //   </div>
    // </form>
  );
};

export default MultipleChoiceQuestion;

// const allOptionsAvailable = values.options.every((option) => option.text.trim() !== '');

// useEffect(() => {
//   if (formik?.values?.question && allOptionsAvailable && formik?.values?.correctOption){
//     autoSubmit({
//       'qID': questionNo,
//       'statement': values.question,
//       'correctOption': '',
//     })
//   }
// }, [values])

// const handleRadioChange = (index) => {
//   const updatedOptions = values.map((option, i) => ({
//     ...option,
//     isCorrect: i === index,
//   }));

//   setValues({
//     ...values,
//     options: updatedOptions,
//   });
// };
