import React, { useState, useEffect, useMemo } from "react";
import MaterialReactTable from "material-react-table";

import pencil from "../../assets/icons/ic_pencil.svg";
import trash from "../../assets/icons/ic_trash.svg";

import { useQuery } from "react-query";
import useAxios from "../../api/useAxios";
import { toast } from "react-toastify";

import { useMutation } from "react-query";
import DeleteConfirmationModal from "../../components/modals/delete-confirmation.modal";

import EditSchedule from "./edit-schedule.modal";

const SchedulesListing = () => {
  const [deleteItem, setDeleteItem] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);

  const [editItem, setEditItem] = useState(false);
  const [openEditModal, setEditModal] = useState(false);

  const toggleEditModal = () => {
    setEditItem(false);
    setEditModal(!openEditModal);
  };

  console.log(editItem);

  useEffect(() => {
    if (editItem) {
      setEditModal(true);
    }
  }, [editItem]);

  useEffect(() => {
    if (!openEditModal) {
      setEditItem(false);
      setEditModal(false);
    }
  }, [openEditModal]);

  useEffect(() => {
    if (deleteItem) {
      setDeleteModal(true);
    }
  }, [deleteItem]);

  useEffect(() => {
    if (!openDeleteModal) {
      setDeleteItem(false);
    }
  }, [openDeleteModal]);

  const toggleDeleteModal = () => {
    setDeleteModal(!openDeleteModal);
  };

  const deleteSchedule = () => {
    return api.delete(`/courses/schedule/${deleteItem?.id}`);
  };

  const deleteMutation = useMutation(deleteSchedule, {
    onSuccess: () => {
      toast.success("تم الحذف");
      setDeleteItem(false);
      setDeleteModal(false);
      refetch();
    },

    onError: (response) => {
      if (
        response?.response?.status == 404 &&
        response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const columns = useMemo(() => [
    {
      header: "دورة",
      accessorKey: "course.name",
    },
    {
      header: "تاريخ البدء",
      accessorKey: "start_date",
    },
    {
      header: "مدرب",
      accessorKey: "trainer.name",
    },
    {
      header: "الموقع",
      accessorKey: "location.name",
    },
    {
      header: "المدة (أيام)",
      accessorKey: "duration",
    },
    {
      header: "حالة",
      accessorKey: "status",
    },

    {
      header: "يحرر",
      Cell: ({ row }) => (
        <img
          onClick={() => setEditItem(row.original)}
          className="edit-icon cursor-pointer"
          src={pencil}
          alt=""
        />
      ),
    },
    {
      header: "يمسح",
      Cell: ({ row }) => (
        <img
          onClick={() =>
            setDeleteItem({
              id: row.original.id,
              name: row.original.course.name,
            })
          }
          className="trash-icon cursor-pointer"
          src={trash}
          alt=""
        />
      ),
    },
  ]);

  const api = useAxios();

  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch,
  } = useQuery(`schedules`, async () => api.get("/courses/schedules/"), {
    cacheTime: 0,
  });

  const schedules = response?.data?.schedules ?? [];

  return (
    <div className="wrapper">
      <MaterialReactTable
        state={{ isLoading: isLoading }}
        columns={columns}
        data={schedules}
        renderTopToolbarCustomActions={() => {
          return <h3 className="mx-3 mt-3">جداول</h3>;
        }}
      />
      <DeleteConfirmationModal
        isOpen={openDeleteModal}
        toggle={toggleDeleteModal}
        data={deleteItem}
        mutation={deleteMutation}
      />

      <EditSchedule
        isOpen={openEditModal}
        toggle={toggleEditModal}
        data={editItem}
        refetch={refetch}
      />
    </div>
  );
};

export default SchedulesListing;
