import React, { useState } from "react";

import AddNewCourse from "../../components/course/add-new-course.component";
import CourseListing from "../../components/course/course-listing.component";

const ManageCourses = () => {
  document.title = "إدارة الدورات | صناع السعادة";

  const [activeSection, setActiveSection] = useState("add");

  return (
    <>
      <div className="container-fluid mt-4 mb-3">
        {/* container fluid */}
        <div
          className="bg-primary breadcrumb-item text-white text-end active fs-4"
          aria-current="page"
        >
          إدارة الدورات
        </div>

        <div className="text-end">
          <button
            onClick={() => setActiveSection("manage")}
            className={`custom-btn ${
              activeSection == "manage" ? "active-btn" : "inactive-btn"
            } mt-3`}
          >
            {" "}
            إدارة الدورات
          </button>

          <button
            onClick={() => setActiveSection("add")}
            className={`custom-btn ${
              activeSection == "add" ? "active-btn" : "inactive-btn"
            } ms-2 mt-3`}
          >
            إضافة دورة
          </button>
        </div>

        {activeSection == "add" ? <AddNewCourse /> : <CourseListing />}
      </div>
    </>
  );
};

export default ManageCourses;
