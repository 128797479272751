import React, { useState } from "react";

import AddNewEvaluation from "../../components/evaluation/add-evaluation.component";
import EvaluationListing from "../../components/evaluation/evaluation-listing.component";
import FeedbacksListing from "../../components/evaluation/feedback-listing.component";

const ManageEvaluation = () => {
  document.title = "إدارة الدورات | صناع السعادة";

  const [activeSection, setActiveSection] = useState("add");

  return (
    <>
      <div className="container-fluid mt-4 mb-3">
        {/* container fluid */}
        <div
          className="bg-primary breadcrumb-item text-white text-end active fs-4"
          aria-current="page"
        >
     إدارة الاستبيانات
        </div>

        <div className="text-end">
          <button
            onClick={() => setActiveSection("feedbacks")}
            className={`custom-btn ${
              activeSection == "feedbacks" ? "active-btn" : "inactive-btn"
            } ms-2 mt-3`}
          >
            الاستبيانات
          </button>
          <button
            onClick={() => setActiveSection("manage")}
            className={`custom-btn ${
              activeSection == "manage" ? "active-btn" : "inactive-btn"
            } ms-2 mt-3`}
          >
قائمة الاستبيانات
          </button>

          <button
            onClick={() => setActiveSection("add")}
            className={`custom-btn ${
              activeSection == "add" ? "active-btn" : "inactive-btn"
            } ms-2 mt-3`}
          >
            إضافة استبيان جديد

          </button>
        </div>

        {activeSection == "add" ? (
          <AddNewEvaluation />
        ) : activeSection == "manage" ? (
          <EvaluationListing />
        ) : (
          <FeedbacksListing />
        )}
      </div>
    </>
  );
};

export default ManageEvaluation;
