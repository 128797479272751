import React, { useEffect, useState } from "react";

const AttendanceCard = ({ data, setAttendance }) => {
  const [present, setPresent] = useState(data?.is_present ?? false);

  const handleCheckboxChange = (info) => {
    setAttendance((prev) => {
      // Check if an item with the same id already exists
      const existingItemIndex = prev.findIndex(
        (item) => item.candidateID === info.candidateID
      );
      // If the item exists, replace it; otherwise, add the new item
      if (existingItemIndex !== -1) {
        // Replace the existing item
        const updatedAttendance = [...prev];
        updatedAttendance[existingItemIndex] = info;
        return updatedAttendance;
      } else {
        // Add the new item
        return [...prev, info];
      }
    });
  };

  useEffect(() => {
    setPresent(data?.is_present ?? false);
  }, [data]);

  return (
    <>
      <div className="attendance-card d-flex flex-row-reverse align-items-center justify-content-between gap-3 gap-sm-5 mt-3">
        <div>
          <div className="mb-1">
            <b>{data?.candidate?.ar_name}</b>
          </div>
          <p style={{ fontSize: "12px" }}>{data?.candidate?.email}</p>
        </div>
        <div>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              id={`flexSwitchCheckDefault-${data?.id}`}
              checked={present}
              onChange={() => {
                handleCheckboxChange({
                  candidateID: data?.id || data?.candidate_id,
                  isPresent: !present,
                });
                setPresent(!present);
              }}
            />
            <label class="form-check-label" for="flexSwitchCheckDefault">
              حاضر
            </label>
          </div>
        </div>
      </div>
      {/* <hr /> */}
    </>
  );
};

export default AttendanceCard;
