import { ConstructionOutlined } from "@mui/icons-material";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Input, Label } from "reactstrap";
import useAxios from "../../api/useAxios";
import DynamicEvaluationQuestions from "./dynamic-evaluation-questions.component";
import { evaluationSchema } from "../../validations/validation-schemas";
import { toast } from "react-toastify";
import IP from "../../sections/variables";

const AddNewEvaluation = () => {
  const [questions, setQuestions] = useState([{ questionNo: 1 }]);
  const [formError, setFormError] = useState([]);
  const [shouldDisableButton, setShouldDisableButton] = useState(false);
  const [finalValues, setFinalValues] = useState([]);
  const api = useAxios();

  // console.log(api);

  const URL = `${IP}/api`;
  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch,
  } = useQuery(`get-schedules`, async () => api.get("/courses/get-schedules"), {
    cacheTime: 0,
  });

  const courses = response?.data ?? [];

  const handleFormError = (error) => {
    setFormError((prevErrors) => {
      const existingErrorIndex = prevErrors.findIndex((e) => e.id === error.id);
      if (existingErrorIndex !== -1) {
        // If error with the same id already exists, replace the previous object
        const newErrors = [...prevErrors];
        newErrors[existingErrorIndex] = error;
        return newErrors;
      } else {
        // If error with the same id doesn't exist, add the new error object
        return [...prevErrors, error];
      }
    });
  };

  const handleQuestionsSubmit = (questionValues) => {
    setQuestions((prevQuestions) => {
      const existingIndex = prevQuestions.findIndex(
        (question) => question.questionNo === questionValues.questionNo
      );

      if (existingIndex !== -1) {
        // If questionNo already exists, replace the existing object
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[existingIndex] = questionValues;
        return updatedQuestions;
      } else {
        // If questionNo doesn't exist, add the new object
        return [...prevQuestions, questionValues];
      }
    });
  };
  const handleDeleteQuestion = (questionNo) => {
    // console.log(questionIndex);
    // setQuestions((qArr) =>
    //   qArr.filter((_, index) => index !== questionIndex)
    // );
    setQuestions((q) =>
      q.filter((quest, index) => quest.questionNo !== questionNo)
    );
    // setFinalValues((finalValues) => ({
    //   ...finalValues,
    //   questions: finalValues.questions.filter(
    //     (quest) => quest.questionNo !== questionNo
    //   ),
    // }));
  };

  // useEffect(() => {
  //   setFinalValues((finalValues) => ({ ...finalValues, questions }));
  // }, [questions]);

  useEffect(() => {
    // console.log("I run");
    let errorCount = 0;
    formError.map((obj) => {
      // console.log(obj);
      if (obj.error) {
        // console.log("Errrrooorrrrrr");
        setShouldDisableButton(true);
        errorCount++;
      }
    });

    if (!errorCount > 0) {
      setShouldDisableButton(false);
    }
  }, [formError]);

  // console.log("questions", questions);

  const sendEvaluation = (payload) => {
    console.log(payload);
    return api.post(`${URL}/feedback/create-feedback`, payload);
  };

  const addEvaluationMutaton = useMutation(sendEvaluation, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
      setQuestions([]);
      resetForm();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const onSubmit = (values) => {
    const newQuestions = questions?.map((obj) => {
      const { questionNo, ...rest } = obj;
      return rest;
    });

    addEvaluationMutaton.mutate({
      schedule: values.course,
      questions: newQuestions,
    });
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    setFieldError,
  } = useFormik({
    initialValues: {
      course: "",
    },

    validationSchema: evaluationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="wrapper">
      <form
        className="row text-end justify-content-end"
        onSubmit={handleSubmit}
      >
        <div className="col-12 col-md-6">
          <Label className="form-label text-start mt-3 mb-1">دورة*</Label>
          <Input
            value={values.course}
            onChange={handleChange}
            onBlur={handleBlur}
            type="select"
            className={
              errors.course && touched.course
                ? "form-control form-control-icon is-invalid col-6"
                : "form-control form-control-icon input-border-primary col-6"
            }
            id="courseInput"
            name="course"
            dir="rtl"
          >
            <option value="">اختر الدورة</option>

            {courses?.map((course) => (
              <option key={course.id} value={course.id}>
                {course.value}
              </option>
            ))}
          </Input>
          {errors.course && touched.course && (
            <div className="invalid-feedback">{errors.course}</div>
          )}
        </div>
        <div className="col-12">
          {questions?.length > 0 &&
            questions?.map((q, index) => (
              <div key={q.questionNo} className="my-4">
                <h5 className="mt-3 text-end">السؤال{index + 1}</h5>
                <DynamicEvaluationQuestions
                  index={index}
                  questionNo={q.questionNo}
                  handleSubmit={handleQuestionsSubmit}
                  onDelete={handleDeleteQuestion}
                  setError={handleFormError}
                />
              </div>
            ))}
        </div>
        <button
          type="button"
          className={"custom-btn inactive-btn mt-3 cursor-pointer"}
          onClick={() =>
            setQuestions((prev) => [...prev, { questionNo: prev.length + 1 }])
          }
        >
          إضافة سؤال جديد
        </button>

        <hr className="mt-4" />
        <div className="text-center ">
          <button
            type="submit"
            disabled={
              shouldDisableButton ||
              values.course == "" ||
              addEvaluationMutaton.isLoading ||
              !(questions?.length > 0)
            }
            className={"custom-btn active-btn mt-1 cursor-pointer"}
            style={{ width: "105px" }}
          >
            {addEvaluationMutaton.isLoading ? (
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "إنشاء التقييم"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewEvaluation;
