import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  Spinner,
} from "reactstrap";

import { Formik, useFormik } from "formik";

import {
  editQuestionSchema,
  locationSchema,
} from "../../validations/validation-schemas";
import { useMutation } from "react-query";
import { MdCheck, MdDelete } from "react-icons/md";

import useAxios from "../../api/useAxios";

import { toast } from "react-toastify";
import IP from "../../sections/variables";
import DeleteConfirmationModal from "../modals/delete-confirmation.modal";
import EditForm from "./editForm.component";

const EditQuestionnaireModal = ({ isOpen, toggle, data, refetch }) => {
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [passingMarks, setPassingMarks] = useState(false);
  const [active, setActive] = useState(false);

  const [mcqCount, setMCQCount] = useState(1);

  const handleAddMCQ = () => {
    // Create a new array with the existing questions and the new question
    const updatedQuestions = [
      ...(data?.questions || []),
      {
        statement: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        correct_option: "",
        questionNo: data?.questions?.length,
      },
    ];

    // Update the data prop with the new questions
    // Note: This is directly manipulating the prop, which is fine in this case
    data.questions = updatedQuestions;

    // Increment the MCQ count
    setMCQCount(mcqCount + 1);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!openDeleteModal);
  };

  const api = useAxios();

  //   console.log(data);
  const URL = `${IP}/api`;

  const editQuestionnaire = (payload) => {
    console.log(payload);
    const { question_id, ...newPayload } = payload;

    // Log the payload without question_id
    console.log(newPayload);
    return api.patch(
      `${URL}/courses/question/${payload?.question_id}`,
      newPayload
    );
  };

  const editQuestionnaireMutation = useMutation(editQuestionnaire, {
    onSuccess: () => {
      toast.success("محدث");
      //   toggle();
      refetch();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const addQuestion = (payload) => {
    console.log(payload);
    return api.post(`${URL}/courses/add-question/${data?.id}`, payload);
  };

  const addNewQuestionMutation = useMutation(addQuestion, {
    onSuccess: () => {
      toast.success("تمت الإضافة");
      toggle();
      refetch();
    },
    onError: (response) => {
      if (
        response?.response?.status === 400 &&
        !!response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  //   const onSubmit = () => {
  //     // editQuestionnaireMutation.mutate(values);
  //   };

  //   const {
  //     values,
  //     errors,
  //     touched,
  //     handleBlur,
  //     handleChange,
  //     handleSubmit,
  //     setFieldValue,
  //     resetForm,
  //   } = useFormik({
  //     initialValues: {
  //       statement: data?.statement ?? "",
  //     },

  //     validationSchema: locationSchema,
  //     onSubmit,
  //     enableReinitialize: true,
  //   });

  const deleteCategory = () => {
    if (deleteItem?.id) {
      // It has a backend ID, perform deletion using the backend API
      return api.delete(`/courses/question/${deleteItem?.id}`);
    } else {
      // It's a custom question, handle removal in the client-side state
      const filteredQuestions = data?.questions?.filter(
        (q) => q.questionNo !== deleteItem?.questionNo
      );
      data.questions = filteredQuestions;
      return Promise.resolve(); // Resolve immediately since it's client-side
    }
    // return api.delete(`/courses/question/${deleteItem?.id}`);
  };

  const deleteMutation = useMutation(deleteCategory, {
    onSuccess: () => {
      toast.success("تم الحذف");
      setDeleteModal(false);
      toggle();
      refetch();
      //   data.questions = data?.questions?.filter(
      //     (quest) => quest.id !== deleteItem?.id
      //   );
      setDeleteItem(false);
    },

    onError: (response) => {
      if (
        response?.response?.status == 404 &&
        response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const sendActive = (payload) => {
    console.log(payload);
    return api.patch(`${URL}/courses/assessment/${data?.id}`, payload);
  };

  const activeUpdateMutation = useMutation(sendActive, {
    onSuccess: () => {
      toast.success("تم الحذف");
      toggle();
      refetch();
    },

    onError: (response) => {
      console.log(response)
      if (
        (response?.response?.status == 404 || response?.response?.status == 400) &&
        response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  const handleUpdateData = () => {



    activeUpdateMutation.mutate({
      is_active: active,
      passing_marks: `${passingMarks}`,
    });
  };

  useEffect(() => {
    if (data) {
      setActive(data?.is_active);
      setPassingMarks(data?.passing_marks);
    }
  }, [data]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-lg" centered>
      <ModalHeader toggle={toggle}>
        <h4 className="text-primary mb-0">تحرير الاستبيان</h4>
      </ModalHeader>

      <ModalBody>
        <>
          <div className="row text-end justify-content-end mb-3">
            <div className="col-12 col-md-6 mb-3 mb-md-0">
              <Label className="form-label text-start mt-3 mb-1">
                علامات المرور*
              </Label>
              <Input
                value={passingMarks}
                handle
                onChange={(e) => {
                  setPassingMarks(e.target.value);
                }}
                type="number"
                className="form-control form-control-icon input-border-primary col-6"
                id="passingMarksInput"
                name="passingMarks"
                dir="rtl"
                min='0'
              />
            </div>
            <div className="col-12 col-md-6 mb-3 mb-md-0">
              <div className="d-flex align-items-center justify-content-end  h-100">
                <div className="form-check form-switch mt-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`flexSwitchCheckDefault`}
                    checked={active}
                    onChange={() => setActive(!active)}
                  />
                  <label
                    className="form-check-label ms-3"
                    for="flexSwitchCheckDefault"
                  >
                    نشيط
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 text-end">
              <button
                type="button"
                onClick={handleUpdateData}
                className={"custom-btn active-btn mt-1 cursor-pointer"}
              >
                تحديث
              </button>
            </div>
          </div>
          <div className="row text-end justify-content-end">
            <div className="col-12">
              {data?.questions?.map((quest, index) => {
                return (
                  <EditForm
                    quest={quest}
                    idx={index}
                    editQuestionnaireMutation={editQuestionnaireMutation}
                    addNewQuestionMutation={addNewQuestionMutation}
                    toggleDeleteModal={toggleDeleteModal}
                    setDeleteItem={setDeleteItem}
                  />
                  // <Formik
                  //   initialValues={{
                  //     statement: quest?.statement ?? "",
                  //     option1: quest?.option1 ?? "",
                  //     option2: quest?.option2 ?? "",
                  //     option3: quest?.option3 ?? "",
                  //     option4: quest?.option4 ?? "",
                  //     correct_option: quest?.correct_option ?? "",
                  //   }}
                  //   validationSchema={editQuestionSchema}
                  //   onSubmit={(values) => {
                  //     if (quest?.id) {
                  //       console.log("id");
                  //       editQuestionnaireMutation.mutate({
                  //         ...values,
                  //         question_id: quest?.id,
                  //       });
                  //     } else {
                  //       console.log("no id");
                  //       addNewQuestionMutation.mutate({
                  //         ...values,
                  //       });
                  //     }
                  //   }}
                  // >
                  //   {({
                  //     values,
                  //     errors,
                  //     touched,
                  //     setFieldValue,
                  //     handleBlur,
                  //     handleChange,
                  //     handleSubmit,
                  //     isValid,
                  //     isSubmitting,
                  //     resetForm,
                  //     dirty,
                  //   }) => {
                  //     return (
                  //       <>
                  //         <div className="question-form col-12 w-100">
                  //           <label htmlFor="statement" className="mt-3">
                  //             سؤال
                  //           </label>
                  //           <input
                  //             type="text"
                  //             id="statement"
                  //             name="statement"
                  //             dir="rtl"
                  //             onChange={handleChange}
                  //             onBlur={handleBlur}
                  //             value={values.statement}
                  //             placeholder="أدخل السؤال"
                  //             className="form-control form-control-icon input-border-primary col-6 mb-3"
                  //           />
                  //           {/* {touched.statement && errors.statement ? (
                  //             <div className="text-danger mb-2">
                  //               {errors.statement}
                  //             </div>
                  //           ) : null} */}
                  //           <div className="row flex-row-reverse">
                  //             <div className="col-3">
                  //               الخيار 1
                  //               <input
                  //                 type="text"
                  //                 dir="rtl"
                  //                 id="option1"
                  //                 name="option1"
                  //                 onChange={handleChange}
                  //                 onBlur={handleBlur}
                  //                 value={values.option1}
                  //                 placeholder="أدخل الخيار"
                  //                 className="form-control form-control-icon input-border-primary"
                  //               />
                  //               <div className="d-flex justify-content-end mt-3 mx-3">
                  //                 <input
                  //                   type="checkbox"
                  //                   name="correct_option"
                  //                   value="option1"
                  //                   onChange={handleChange}
                  //                   checked={values.correct_option === "option1"}
                  //                 />
                  //               </div>
                  //               {/* {touched.option1 &&
                  //               touched.option1 &&
                  //               errors.option1 ? (
                  //                 <div className="text-danger">
                  //                   {errors.option1}
                  //                 </div>
                  //               ) : null} */}
                  //             </div>
                  //             <div className="col-3">
                  //               الخيار 2
                  //               <input
                  //                 type="text"
                  //                 dir="rtl"
                  //                 id="option2"
                  //                 name="option2"
                  //                 onChange={handleChange}
                  //                 onBlur={handleBlur}
                  //                 value={values.option2}
                  //                 placeholder="أدخل الخيار"
                  //                 className="form-control form-control-icon input-border-primary"
                  //               />
                  //               <div className="d-flex justify-content-end mt-3 mx-3">
                  //                 <input
                  //                   type="checkbox"
                  //                   name="correct_option"
                  //                   value="option2"
                  //                   onChange={handleChange}
                  //                   checked={values.correct_option === "option2"}
                  //                 />
                  //               </div>
                  //               {/* {touched.option2 &&
                  //               touched.option2 &&
                  //               errors.option2 ? (
                  //                 <div className="text-danger">
                  //                   {errors.option2}
                  //                 </div>
                  //               ) : null} */}
                  //             </div>
                  //             <div className="col-3">
                  //               الخيار 3
                  //               <input
                  //                 type="text"
                  //                 dir="rtl"
                  //                 id="option3"
                  //                 name="option3"
                  //                 onChange={handleChange}
                  //                 onBlur={handleBlur}
                  //                 value={values.option3}
                  //                 placeholder="أدخل الخيار"
                  //                 className="form-control form-control-icon input-border-primary"
                  //               />
                  //               <div className="d-flex justify-content-end mt-3 mx-3">
                  //                 <input
                  //                   type="radio"
                  //                   name="correct_option"
                  //                   value="option3"
                  //                   onChange={handleChange}
                  //                   checked={values.correct_option === "option3"}
                  //                 />
                  //               </div>
                  //               {/* {touched.option3 &&
                  //               touched.option3 &&
                  //               errors.option3 ? (
                  //                 <div className="text-danger">
                  //                   {errors.option3}
                  //                 </div>
                  //               ) : null} */}
                  //             </div>
                  //             <div className="col-3">
                  //               الخيار 4
                  //               <input
                  //                 type="text"
                  //                 dir="rtl"
                  //                 id="option4"
                  //                 name="option4"
                  //                 onChange={handleChange}
                  //                 onBlur={handleBlur}
                  //                 value={values.option4}
                  //                 placeholder="أدخل الخيار"
                  //                 className="form-control form-control-icon input-border-primary"
                  //               />
                  //               <div className="d-flex justify-content-end mt-3 mx-3">
                  //                 <input
                  //                   type="radio"
                  //                   name="correct_option"
                  //                   value="option4"
                  //                   onChange={handleChange}
                  //                   checked={values.correct_option === "option4"}
                  //                 />
                  //               </div>
                  //               {/* {touched.option4 && errors.option4 ? (
                  //                 <div className="text-danger">
                  //                   {errors.option4}
                  //                 </div>
                  //               ) : null} */}
                  //             </div>
                  //             <div>
                  //               {(touched.correct_option &&
                  //                 errors.correct_option) ||
                  //               (touched.option4 && errors.option4) ||
                  //               (touched.option3 && errors.option3) ||
                  //               (touched.option2 && errors.option2) ||
                  //               (touched.option1 && errors.option1) ||
                  //               (touched.statement && errors.statement) ? (
                  //                 <div className="text-danger">
                  //                   يرجى ملء جميع الحقول بشكل صحيح
                  //                 </div>
                  //               ) : null}
                  //             </div>
                  //             <div className="d-flex justify-content-start mt-4 gap-5">
                  //               <MdDelete
                  //                 className="trash-icon custom-icon cursor-pointer"
                  //                 onClick={() => {
                  //                   toggleDeleteModal();
                  //                   setDeleteItem(quest);
                  //                 }}
                  //               />
                  //               <MdCheck
                  //                 className="text-success custom-icon"
                  //                 onClick={handleSubmit}
                  //               />
                  //             </div>
                  //           </div>
                  //         </div>
                  //       </>
                  //     );
                  //   }}
                  // </Formik>
                );
              })}
              <div>
                <button
                  type="button"
                  onClick={handleAddMCQ}
                  className={"custom-btn inactive-btn mt-1 cursor-pointer"}
                >
                  إضافة سؤال جديد
                </button>
              </div>
              {/* <Label className="form-label text-start mt-3 mb-1">
                Location Name*
              </Label>
              <Input
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                className={
                  errors.name && touched.name
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon input-border-primary col-6"
                }
                id="courseInput"
                name="name"
                placeholder="Enter course category name"
                dir="rtl"
              />
              {errors.name && touched.name && (
                <div className="invalid-feedback">{errors.name}</div>
              )} */}
            </div>
          </div>
        </>

        <DeleteConfirmationModal
          isOpen={openDeleteModal}
          toggle={toggleDeleteModal}
          data={deleteItem}
          mutation={deleteMutation}
        />
      </ModalBody>
      <ModalFooter>
        {/* {editQuestionnaireMutation.isLoading ? (
            <p>يعالج...</p>
          ) : (
            <>
              <button
                onClick={toggle}
                type="button"
                className="custom-btn no-btn px-3"
              >
                No
              </button>
              <button
                type="submit"
                className="custom-btn active-btn"
                disabled={editQuestionnaireMutation.isLoading}
              >
                {editQuestionnaireMutation.isLoading ? "Updating" : " Update"}
                {editQuestionnaireMutation.isLoading && (
                  <Spinner size="sm" className="flex-shrink-0 ms-2" />
                )}
              </button>
            </>
          )} */}
      </ModalFooter>
    </Modal>
  );
};

export default EditQuestionnaireModal;
