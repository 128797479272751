import React from "react";
import { useQuery } from "react-query";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import useAxios from "../../api/useAxios";

const ModalViewFeedback = ({ detailModal, toggleDetailModal, detailID }) => {
  const api = useAxios();

  const {
    isLoading,
    isError,
    data: res,
    isSuccess,
    refetch,
  } = useQuery(
    `get-feedback-detail-${detailID}`,
    async () => api.get(`/feedback/submitted-feedback/${detailID}`),
    {
      cacheTime: 0,
      enabled: detailID ? true : false,
    }
  );

  const feedbackDetail = res?.data?.feedback ?? [];
  console.log(feedbackDetail);
  return (
    <Modal isOpen={detailModal} toggle={toggleDetailModal} size="lg">
      <ModalBody className="p-0">
        <div className="detail-feedback-modal">
          <div className="detail-modal-header">
            <h4>تفاصيل ردود الفعل</h4>
          </div>
          <div className="detail-feedback-modal-main">
            {isLoading ? (
              <div className="d-flex justify-content-center py-5">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {feedbackDetail[0]?.questions?.map((quest, idx) => {
                  return (
                    <div className="mb-4">
                      <div className="d-flex gap-3 flex-row-reverse align-items-start">
                        <h5 className="text-end">السؤال{idx + 1}</h5>
                        <p className="text-end">{quest.question}</p>
                      </div>
                      <div className="me-5 pe-5">
                        <p className="text-end answer-detail-feedback">
                          <b>{quest.answer}</b>
                        </p>
                      </div>
                    </div>
                  );
                })}
                {feedbackDetail[0]?.mcqs?.map((quest, idx) => {
                  return (
                    <div className="mb-4">
                      <div className="d-flex gap-3 flex-row-reverse align-items-start">
                        <h5 className="text-end">
                          السؤال{feedbackDetail[0]?.questions?.length + idx + 1}
                        </h5>
                        <p className="text-end">{quest.mcq}</p>
                      </div>
                      <div className="me-5 pe-5">
                        <p className="text-end answer-detail-feedback">
                          <b>{quest.choice}</b>
                        </p>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalViewFeedback;

// {feedbackDetail[0]?.questions?.map((quest, idx) => {
//   return (

//       <div>
//       <h5 className="text-end">السؤال{idx + 1}</h5>
//       {/* <p className="text-end">{}</p> */}
//       <div>

//   );
// })
