import React from "react";

import { Label, Input, Spinner } from "reactstrap";
import { useFormik } from "formik";

import { useMutation } from "react-query";

import { toast } from 'react-toastify';
import useAxios from '../../api/useAxios';
import IP from "../../sections/variables";


import { locationSchema } from "../../validations/validation-schemas";


const AddNewLocation = () => {

    const api = useAxios()

    const URL = `${IP}/api`

    const sendLocation = (payload) => {

      console.log(payload)
      return api.post(`${URL}/courses/add-location`, payload)
    
    }
  
    const addLocationMutaton = useMutation(sendLocation, {
      onSuccess: () => {
  
        toast.success('تمت الإضافة')
        resetForm()
  
      },
      onError: (response) => {
        
        if (response?.response?.status === 400 && !!response?.response?.data?.message){
            toast.error(response.response.data.message)
          }
        
        else{
            toast.error('هناك خطأ ما')
        }
  
      }
    })
  
    const onSubmit = () => {
      addLocationMutaton.mutate(values)
    }

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      } = useFormik({
        initialValues: {
            name: '',
        },
    
        validationSchema: locationSchema,
        onSubmit,
        enableReinitialize: true,
      });

    return (
        <div className="wrapper">
            <form onSubmit={handleSubmit}>
                <div className="row text-end justify-content-end">
                    <div className="col-12 col-md-6">
                        <Label className="form-label text-start mt-3 mb-1">الموقع*</Label>
                        <Input
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            className={errors.name && touched.name ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon input-border-primary col-6"}
                            id="locationInput"
                            name="name"
                            placeholder="أدخل اسم الموقع"
                            dir="rtl"
                        />
                            {
                                errors.name && touched.name && <div className="invalid-feedback">{errors.name}</div>
                            }
                    <button 
                        type="submit" 

                        className='custom-btn active-btn mt-3'
                        disabled={addLocationMutaton.isLoading}>
                        {addLocationMutaton.isLoading ? "يعالج" : ' يضيف'}
                        {addLocationMutaton.isLoading &&  <Spinner size="sm" className="flex-shrink-0 ms-2"/>}
                    </button>
                    </div>
                
                </div>
            </form>
        </div>
    )
}


export default AddNewLocation;