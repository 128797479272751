import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Spinner, Input, Label, Col, Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';

import axios from 'axios';
import { useMutation } from 'react-query';
import { adminLoginSchema } from '../../../validations/validation-schemas';

import { useStore } from '../../../App';

import IP from '../../variables'

export default function LoginForm() {


  const navigate = useNavigate();

  const URL = `${IP}/api`

  const sendLogin = (payload) => {

    return axios.post(`${URL}/accounts/admin-login`, payload)
  
  }

  const sendLoginMutation = useMutation(sendLogin, {
    onSuccess: (response) => {

      const tokens = response?.data?.data
      
      if (tokens) {
        useStore.setState({ userStatus: true })
        useStore.setState({ accessToken: tokens.access})
        useStore.setState({ refreshToken: tokens.refresh})
        
        toast.success('مرحباً')
        navigate('dashboard');
      }
      
      
      else{
        toast.error('هناك خطأ ما')
      }

    },
    onError: (data) => {

      console.log(data.response)
      console.log(data.response.status)
      
      if (data?.response?.status === 400 && !!data?.response?.data?.message){
        toast.error(data.response.data.message)
      }
      
      else if (data?.response?.status === 500 && !!data?.response?.data?.message){
        toast.error(data.response.data.message)
      }

      else{
        toast.error('هناك خطأ ما')
      }

    }
  })

  const onSubmit = () => {
    sendLoginMutation.mutate(values)
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: adminLoginSchema,
    onSubmit,
    enableReinitialize: true,
  })



  return (
    <form onSubmit={handleSubmit}>
      <div>

          <div className='text-end '>
            <Label dir="rtl" className="form-label mt-3 mb-1 text-secondary">بريد إلكتروني</Label>
          </div>

          <Input
          dir="rtl"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            type="email"
            className={errors.email && touched.email ? "form-control form-control-icon is-invalid login-field col-6" : "form-control input-border-secondary  form-control-icon login-field col-6"}
            id="emailInput"
            placeholder="أدخل البريد الإلكتروني"
            name="email"
          />
          {
            errors.email && touched.email && <div dir='rtl' className="invalid-feedback">{errors.email}</div>
          }
          <div className='text-end '>
          <Label dir='rtl' className="form-label text-start mt-3 mb-1 text-secondary">كلمة المرور</Label>
            
          </div>
       
          
          <Input
            dir="rtl"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            className={errors.password && touched.password ? "form-control form-control-icon is-invalid login-field col-6" : "form-control input-border-secondary form-control-icon login-field col-6"}
            id="passwordInput"
            placeholder="أدخل كلمة المرور"
            name="password"
          />
          {
            errors.password && touched.password && <div dir='rtl' className="invalid-feedback">{errors.password}</div>
          }

      </div>

     
      <div className='text-center'>
          <button 
              type="submit" 
              className="btn-login mt-3"
              disabled={sendLoginMutation.isLoading}>
              {sendLoginMutation.isLoading ? "يعالج" : 'تسجيل الدخول'}
              {sendLoginMutation.isLoading &&  <Spinner size="sm" className="flex-shrink-0 ms-2"/>}
          </button>
      </div>
    </form>
  );
}
