import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useLocation, Link } from "react-router-dom";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
import { bgBlur } from "../../../utils/cssStyles";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import Iconify from "../../../theme-components/iconify";

import logo from "../../../assets/images/logo/black.png";

const NAV_WIDTH = 280;
const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),

  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
  [theme.breakpoints.up("lg")]: {},
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const { pathname } = useLocation();

  const [imageWidth, setImageWidth] = useState(
    window.innerWidth >= 768 ? "200px" : "150px"
  );

  useEffect(() => {
    const handleResize = () => {
      setImageWidth(window.innerWidth >= 768 ? "200px" : "150px");
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Check if the URL contains "public"
  const isPublicPage = pathname.includes("public");

  return (
    <StyledRoot
      sx={{
        width: `calc(100% - ${NAV_WIDTH + 1}px)`,
        zIndex: 3,
      }}
    >
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
            display: { lg: "none" },
          }}
        >
          <Iconify
            icon="eva:menu-2-fill"
            style={{ fontSize: "32px", width: "40px", height: "35px" }}
          />
        </IconButton>

        <img
          src={logo}
          alt="Not found"
          className="img-fluid"
          style={{ width: imageWidth, marginTop: "10px" }}
        />

        <Box sx={{ flexGrow: 1 }}>
          {" "}
          {isPublicPage && (
            <h1 className="ps-5 pt-3 fw-bolder text-center text-success">
              2024 Strategy Offsite Meeting
            </h1>
          )}{" "}
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
