import React, { useState, useEffect, useMemo } from "react";
import MaterialReactTable from "material-react-table";

import pencil from "../../assets/icons/ic_pencil.svg";
import trash from "../../assets/icons/ic_trash.svg";

import useAxios from "../../api/useAxios";
import { useMutation } from "react-query";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import DeleteConfirmationModal from "../modals/delete-confirmation.modal";
import EditCourseModal from "./edit-course.modal";

const CourseListing = () => {
  const [deleteItem, setDeleteItem] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);

  const [editItem, setEditItem] = useState(false);
  const [openEditModal, setEditModal] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModal(!openDeleteModal);
  };

  const toggleEditModal = () => {
    if (openEditModal) {
      setEditItem(false);
    }
    setEditModal(!openEditModal);
  };

  useEffect(() => {
    if (deleteItem) {
      setDeleteModal(true);
    }
  }, [deleteItem]);

  useEffect(() => {
    if (!openDeleteModal) {
      setDeleteItem(false);
    }
  }, [openDeleteModal]);

  useEffect(() => {
    if (editItem) {
      setEditModal(true);
    }
  }, [editItem]);
  useEffect(() => {
    if (!openEditModal) {
      setEditModal(false);
    }
  }, [openEditModal]);

  const deleteCourse = () => {
    return api.delete(`/courses/${deleteItem?.id}`);
  };

  let api = useAxios();

  const {
    isLoading,
    isError,
    data: response,
    isSuccess,
    refetch,
  } = useQuery(`courses`, async () => api.get("/courses/"), { cacheTime: 0 });

  const courses = response?.data?.courses ?? [];

  const columns = useMemo(() => [
    {
      header: "دورة",
      accessorKey: "name",
    },
    // {
    //   header: "Category",
    //   accessorKey: "category",
    // },
    {
      header: "يحرر",
      Cell: ({ row }) => (
        <img
          onClick={() => {
            setEditItem(row.original);
          }}
          className="edit-icon cursor-pointer"
          src={pencil}
          alt=""
        />
      ),
    },
    {
      header: "يمسح",
      Cell: ({ row }) => (
        <img
          onClick={() => setDeleteItem(row.original)}
          className="trash-icon cursor-pointer"
          src={trash}
          alt=""
        />
      ),
    },
  ]);

  const deleteMutation = useMutation(deleteCourse, {
    onSuccess: () => {
      toast.success("تم الحذف");
      setDeleteItem(false);
      setDeleteModal(false);
      refetch();
    },

    onError: (response) => {
      if (
        response?.response?.status == 404 &&
        response?.response?.data?.message
      ) {
        toast.error(response.response.data.message);
      } else {
        toast.error("هناك خطأ ما");
      }
    },
  });

  return (
    <div className="wrapper">
      {isError ? (
        "هناك خطأ ما"
      ) : (
        <MaterialReactTable
          state={{ isLoading: isLoading }}
          columns={columns}
          data={courses}
          renderTopToolbarCustomActions={() => {
            return <h3 className="mx-3 mt-3">الدورات</h3>;
          }}
          enableRowNumbers
        />
      )}

      <DeleteConfirmationModal
        isOpen={openDeleteModal}
        toggle={toggleDeleteModal}
        data={deleteItem}
        mutation={deleteMutation}
      />

      <EditCourseModal
        isOpen={openEditModal}
        toggle={toggleEditModal}
        data={editItem}
        refetch={refetch}
      />
    </div>
  );
};

export default CourseListing;
